export const triggerTypesData = [
  {
    value: 1,
    label: 'Пол',
  },
  {
    value: 2,
    label: 'Доп. поле',
  },
];

export const typesData = [
  {
    value: 1,
    label: 'Начальная дата',
  },
  {
    value: 2,
    label: 'Дата и месяц',
  },
  {
    value: 3,
    label: 'День рождение',
  },
];