import axios from './axios';

export const onGetProductsCategoriesListReq = async () => {
  return await axios.get('/categories/list');
};

export const onAddNewCategoryReq = async ({ name, file_content }) => {
  return await axios.post('/categories/create', {
    name,
    file_content: file_content.split(',')[1],
  });
};

export const onAddNewSubCategoryReq = async (data) => {
  return await axios.post('/categories/sub_create', data);
};

export const onEditCategoryReq = async ({ name, id, file_content }) => {
  return await axios.post(`/categories/edit/${id}`, {
    name,
    file_content: file_content ? file_content.split(',')[1] : '',
  });
};

export const onEditSubCategoryReq = async ({ name, parent_id, id }) => {
  return await axios.post(`/categories/sub_edit/${id}`, { name, parent_id });
};

export const onDeleteCategoryReq = async (id) => {
  return await axios.get(`/categories/delete/${id}`);
};
