import React from 'react';

import { Box } from '@material-ui/core';

import Header from '../../components/Header';
import QuestionsTable from '../../components/Tables/QuestionsTable';

const QuestionsHistoryPage = () => {
  return (
    <Box>
      <Header label='Опросы' />
      <QuestionsTable />
    </Box>
  );
};

export default QuestionsHistoryPage;
