import { imgPath } from '../resourses/variables';
import axios from './axios';

import axiosDefault from 'axios';

export const onGetProductsListReq = async (id) => {
  return await axios.post('/products/list', {
    category_id: id ? id : null,
  });
};

export const onGetProductsForSelectReq = async () => {
  return await axios.post('/products/list');
};

export const onGetProductByIdReq = async (id) => {
  return await axios.get(`/products/get/${id}`);
};

export const onAddProductReq = async (product) => {
  const data = {
    ...product,
    images: product.images.map((el) => el.name.split(',')[1]),
  };

  return await axios.post('/products/create', data);
};

export const onEditProductReq = async (product) => {
  const data = {
    ...product,
    images: await Promise.all(
      product.images.map(async (el) => {
        if (el.name.includes('base64')) {
          return el.name.split(',')[1];
        } else {
          const url = imgPath + el.name;
          const image = await axiosDefault.get(url, {
            responseType: 'arraybuffer',
          });

          const returnedB64 = Buffer.from(image.data).toString('base64');
          return returnedB64;
        }
      }),
    ),
  };

  return await axios.post(`/products/edit/${product.id}`, data);
};

export const onDeleteProductReq = async (id) => {
  return await axios.get(`/products/delete/${id}`);
};

export const onChangeProductsPositionReq = async (ids_list) => {
  return await axios.patch('/products/set_position', { ids_list });
};
