import * as constant from '../constants';
import {
  onGetNewsListReq,
  onCreateNewsReq,
  onEditNewsReq,
  onDeleteNewsReq,
  onHideNewsReq,
} from '../../api/news';
import { dispatchLoadingStatus } from './helpersFuncions';
import { setNotify } from './notify';

export const onGetNewsList = () => {
  const type = constant.IS_LOADING_NEWS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetNewsListReq();

      dispatch({
        type: constant.ON_GET_NEWS_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddNews = (newsData, showQuestionnare) => {
  return async (dispatch) => {
    try {
      if (newsData.question_bonus_duration < 10 && showQuestionnare) {
        dispatch(
          setNotify({
            notifyStatus: 'error',
            notifyText: 'Время действия должно быть не менее 10 дней!',
            isShowNotify: true,
          }),
        );
        return;
      }

      const {
        data: { data },
      } = await onCreateNewsReq(newsData);

      dispatch({
        type: constant.ON_ADD_NEWS,
        payload: data,
      });
      dispatch(
        setNotify({
          notifyStatus: 'success',
          notifyText: 'Новость успешно создана!',
          isShowNotify: true,
        }),
      );
    } catch (e) {
      dispatch(
        setNotify({
          notifyStatus: 'error',
          notifyText: 'Ошибка при создании новости!',
          isShowNotify: true,
        }),
      );
    }
  };
};

export const onEditNews = (newsData, showQuestionnare) => {
  return async (dispatch, getState) => {
    try {
      if (newsData.question_bonus_duration < 10 && showQuestionnare) {
        dispatch(
          setNotify({
            notifyStatus: 'error',
            notifyText: 'Время действия должно быть не менее 10 дней!',
            isShowNotify: true,
          }),
        );
        return;
      }

      const {
        data: { data },
      } = await onEditNewsReq(newsData);

      const news = getState().news.newsList;

      const idx = news.findIndex((el) => el.id === newsData.id);

      const list = [
        ...news.slice(0, idx),
        { ...data, is_hidden: newsData.is_hidden ? 1 : 0 },
        ...news.slice(idx + 1),
      ];

      if (!!newsData.is_hidden !== !!data.is_hidden) {
        await onHideNewsReq(data.id);
      }

      dispatch({
        type: constant.ON_GET_NEWS_LIST,
        payload: list,
      });
      dispatch(
        setNotify({
          notifyStatus: 'success',
          notifyText: 'Новость успешно отредактирована!',
          isShowNotify: true,
        }),
      );
    } catch (e) {
      dispatch(
        setNotify({
          notifyStatus: 'error',
          notifyText: 'Ошибка при редактировании новости!',
          isShowNotify: true,
        }),
      );
    }
  };
};

export const onDeleteNews = (id) => {
  return async (dispatch, getState) => {
    await onDeleteNewsReq(id);

    const list = getState().news.newsList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_NEWS_LIST,
      payload: list,
    });
  };
};
