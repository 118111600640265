import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Tabs, Tab, makeStyles } from '@material-ui/core';

import Header from '../../components/Header';
import MigrationsFilters from './components/MigrationsFilters/MigrationsFilters';
import UsersSalesByLastDateFilters from './components/UsersSalesByLastDateFilters';
import UsersSalesByPriceFilters from './components/UsersSalesByPriceFilters';
import UsersSalesByPriceAndDateTotalFilters from './components/UsersSalesByPriceAndDateTotalFilters';

import propStyles from '../../resourses/propStyles';
import UsersSalesByPriceAndDateAverageFilters from './components/UsersSalesByPriceAndDateAverageFilters';
import HeaderPushesAndBonuses from './components/HeaderPushesAndBonuses';
import UsersSalesByCategories from './components/UsersSalesByCategories';
import { onGetShopsList } from '../../store/actions/shops';

const tabTypes = {
  MIGRATIONS: 'migrations',
  LAST_PAGE: 'lastPage',
  PRICE: 'price',
  PRICE_AND_DATE_TOTAL: 'priceAndDateTotal',
  PRICE_AND_DATE_AVERAGE: 'priceAndDateAverage',
  BY_CATEGORIES: 'categories',
};

const FiltersByUsersPage = ({ fetchShopList }) => {
  const styles = useStyles();
  // const [tab, setTab] = useState(tabTypes.MIGRATIONS);
  const [tab, setTab] = useState(tabTypes.BY_CATEGORIES);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchShopList();
  }, [fetchShopList]);

  const handleChangeTab = (_, tabValue) => {
    setTab(tabValue);
    setUsers([]);
  };

  const selectAll = () => {
    const isAnySelected = !!users.filter((user) => user.checked).length;

    setUsers((curUsers) =>
      curUsers.map((user) => ({ ...user, checked: !isAnySelected })),
    );
  };

  const componentProps = {
    users,
    setUsers,
    selectAll,
  };

  return (
    <Box pl={2}>
      <Header label='Фильтрация по пользователям' />
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{
          style: {
            backgroundColor: propStyles.orangeColor,
          },
        }}
        value={tab}
        onChange={handleChangeTab}
      >
        <Tab label='Миграции' value={tabTypes.MIGRATIONS} />
        <Tab label='Последняя покупка' value={tabTypes.LAST_PAGE} />
        <Tab label='По цене' value={tabTypes.PRICE} />
        <Tab
          label='Итоговая сумма чека'
          value={tabTypes.PRICE_AND_DATE_TOTAL}
        />
        <Tab
          label='Средняя сумма чека'
          value={tabTypes.PRICE_AND_DATE_AVERAGE}
        />
        <Tab label='По категории' value={tabTypes.BY_CATEGORIES} />
      </Tabs>
      {users.length > 0 && <HeaderPushesAndBonuses users={users} />}

      <Box>
        {tab === tabTypes.MIGRATIONS && (
          <MigrationsFilters {...componentProps} />
        )}
      </Box>
      <Box>
        {tab === tabTypes.LAST_PAGE && (
          <UsersSalesByLastDateFilters {...componentProps} />
        )}
      </Box>
      <Box>
        {tab === tabTypes.PRICE && (
          <UsersSalesByPriceFilters {...componentProps} />
        )}
      </Box>
      <Box>
        {tab === tabTypes.PRICE_AND_DATE_TOTAL && (
          <UsersSalesByPriceAndDateTotalFilters {...componentProps} />
        )}
        {tab === tabTypes.PRICE_AND_DATE_AVERAGE && (
          <UsersSalesByPriceAndDateAverageFilters {...componentProps} />
        )}
        {tab === tabTypes.BY_CATEGORIES && (
          <UsersSalesByCategories {...componentProps} />
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    marginBottom: 14,
  },
}));

const mapDispatch = (dispatchEvent) => ({
  fetchShopList: () => dispatchEvent(onGetShopsList()),
});

export default connect(null, mapDispatch)(FiltersByUsersPage);
