import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import {
  onGetCouponsReq,
  onAddCouponReq,
  onDeleteCouponReq,
} from '../../api/coupons';
import { onGetUsersListReq } from '../../api/users';
import { onGetProductsForSelectReq } from '../../api/products';

export const onGetCoupons = () => {
  const type = constant.IS_LOADING_COUPONS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetCouponsReq();

      dispatch({
        type: constant.ON_GET_COUPONS_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddCoupon = (couponsData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddCouponReq(couponsData);

      const coupons = getState().coupons.couponsList;

      dispatch({
        type: constant.ON_GET_COUPONS_LIST,
        payload: [
          ...coupons,
          { ...data, product_name: data.name, date_end: couponsData.date_end },
        ],
      });

      dispatch({ type: constant.IS_COUPONS_ACTION_SUCCESS });
    } catch (e) {
      dispatch({ type: constant.IS_COUPONS_ACTION_ERROR });
    }
  };
};

export const onDeleteCoupon = (id) => {
  return async (dispatch, getState) => {
    await onDeleteCouponReq(id);

    const coupons = getState().coupons.couponsList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_COUPONS_LIST,
      payload: coupons,
    });
  };
};

export const onGetDataForCoupSelect = () => {
  return async (dispatch) => {
    const resU = await onGetUsersListReq();
    const resP = await onGetProductsForSelectReq();

    const users = resU.data.data.list.map((el) => ({
      label: el.phone,
      value: el.id,
    }));
    const products = resP.data.data.data.map((el) => ({
      label: el.name,
      value: el.id,
    }));

    dispatch({
      type: constant.ON_GET_DATA_FOR_COUP_SELECT,
      payload: {
        users,
        products,
      },
    });
  };
};

export const onCloseActionCouponsMessage = () => {
  return {
    type: constant.ON_CLOSE_ACTION_COUPONS_MESSAGE,
  };
};
