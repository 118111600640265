import * as constant from '../constants';

const initialState = {
  usersList: [],
  usersCount: 0,
  userStatsList: [],
  userCardsList: [],
  isCreateCardError: false,
  isCreateCardErrorMessage: '',
  isCreateCardSuccess: false,
  loadingUsersList: true,
  loadingUserStatsList: true,
  curUserById: null,
  loadingUserById: true,
  isUserActionSuccess: false,
  isUserActionError: false,
  userBlockMessage: '',
};

const usersReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_USERS_LIST:
      return {
        ...state,
        usersList: payload.list,
        usersCount: payload.count,
      };
    case constant.ON_GET_USER_STATS_LIST:
      return {
        ...state,
        userStatsList: payload,
      };
    case constant.ON_GET_USER_CARDS_LIST:
      return {
        userCardsList: payload,
      };
    case constant.IS_CREATE_CARD_SUCCESS:
      return {
        ...state,
        isCreateCardSuccess: payload,
      };
    case constant.IS_CREATE_CARD_ERROR:
      return {
        ...state,
        isCreateCardError: payload.bool,
        isCreateCardErrorMessage: payload.message,
      };
    case constant.IS_LOADING_USERS_LIST:
      return {
        ...state,
        loadingUsersList: payload,
      };
    case constant.IS_LOADING_USERS_STATS_LIST:
      return {
        ...state,
        loadingUserStatsList: payload,
      };
    case constant.ON_GET_USER_BY_ID:
      return {
        ...state,
        curUserById: payload,
      };
    case constant.IS_LOADING_USER_BY_ID:
      return {
        ...state,
        loadingUserById: payload,
      };
    case constant.IS_USER_ACTION_SUCCESS:
      return {
        ...state,
        isUserActionSuccess: true,
      };
    case constant.IS_USER_ACTION_ERROR:
      return {
        ...state,
        isUserActionError: true,
      };
    case constant.ON_CLOSE_ACTION_USER_MESSAGE:
      return {
        ...state,
        isUserActionSuccess: false,
        isUserActionError: false,
      };
    case constant.GET_USER_BLOCK_MESSAGE:
      return {
        ...state,
        userBlockMessage: payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
