import cardImg from '../assets/cardIcon.jpg';

export const imgPath = 'https://loyalty-ch.ru/images/';
export const API_PATH = 'https://loyalty-ch.ru/';
export const noPhotoImg =
  'https://cdn.mouzenidis-travel.ru/userfiles/images/adaptive/no-photo-available.png';

export const cardIcon = cardImg;

export const monthData = [
  {
    label: 'Январь',
    value: 1,
  },
  {
    label: 'Февраль',
    value: 2,
  },
  {
    label: 'Март',
    value: 3,
  },
  {
    label: 'Апрель',
    value: 4,
  },
  {
    label: 'Май',
    value: 5,
  },
  {
    label: 'Июнь',
    value: 6,
  },
  {
    label: 'Июль',
    value: 7,
  },
  {
    label: 'Август',
    value: 8,
  },
  {
    label: 'Сентябрь',
    value: 9,
  },
  {
    label: 'Октябрь',
    value: 10,
  },
  {
    label: 'Ноябрь',
    value: 11,
  },
  {
    label: 'Декабрь',
    value: 12,
  },
];
