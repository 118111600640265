import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import {
  onGetFieldsReq,
  onAddFieldReq,
  onEditFieldReq,
  onDeleteFieldReq,
} from '../../api/fields';

export const onGetFields = () => {
  const type = constant.IS_LOADING_FIELDS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetFieldsReq();

      dispatch({
        type: constant.ON_GET_FIELDS_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddField = (fieldData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddFieldReq(fieldData);

      const list = [...getState().fields.fieldsList, data];

      dispatch({
        type: constant.ON_GET_FIELDS_LIST,
        payload: list,
      });

      dispatch({
        type: constant.IS_FIELDS_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_FIELDS_ACTION_ERROR,
      });
    }
  };
};

export const onEditField = (fieldData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditFieldReq(fieldData);

      const fields = getState().fields.fieldsList;
      const idx = fields.findIndex((el) => el.id === fieldData.id);

      const list = [...fields.slice(0, idx), data, ...fields.slice(idx + 1)];

      dispatch({
        type: constant.ON_GET_FIELDS_LIST,
        payload: list,
      });

      dispatch({
        type: constant.IS_FIELDS_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_FIELDS_ACTION_ERROR,
      });
    }
  };
};

export const onDeleteField = (id) => {
  return async (dispatch, getState) => {
    await onDeleteFieldReq(id);

    const fields = getState().fields.fieldsList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_FIELDS_LIST,
      payload: fields,
    });
  };
};

export const onCloseActionFieldsMessage = () => {
  return { type: constant.ON_CLOSE_ACTION_FIELDS_MESSAGE };
};
