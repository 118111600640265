import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dialog, Grid, Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Notify from '../../Notify';
import Select from 'react-select';

import {
  onGetDataForCoupSelect,
  onAddCoupon,
  onCloseActionCouponsMessage,
} from '../../../store/actions/coupons';

const AddCouponModal = ({
  open,
  setOpen,
  onGetDataForCoupSelect,
  productsForCoupSelect,
  userForCoupSelect,
  onAddCoupon,
  isCouponsActionSucces,
  isCouponsActionError,
  onCloseActionCouponsMessage,
}) => {
  const styles = useStyles();
  const [coupon, setCoupon] = useState({
    user_id: '',
    product_id: '',
    count: '',
    date_end: '',
  });

  useEffect(() => {
    onGetDataForCoupSelect();
  }, [onGetDataForCoupSelect]);

  const onHandleChange = (e) => {
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const clearData = () => {
    setCoupon({
      user_id: '',
      product_id: '',
      count: '',
      date_end: '',
    });
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const onSubmit = () => {
    const newCoupon = {
      product_id: coupon.product_id.value,
      user_id: coupon.user_id.value,
      count: coupon.count,
      date_end: coupon.date_end,
    };

    onAddCoupon(newCoupon);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Grid container direction='column' className={styles.modal}>
        <HeaderForModal
          setOpen={onCloseModal}
          headerLabel={'Добавление купона'}
        />
        <Box>
          {/*  */}
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Кол-во товара
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={onHandleChange}
                name='count'
                value={coupon.count}
                className={styles.input}
                label='Кол-во товара'
                variant='outlined'
                type='number'
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Товар
            </Grid>
            <Grid item xs={8}>
              <Select
                styles={propStyle.selectStyle}
                placeholder='Выберите товар'
                value={coupon.product_id}
                onChange={(selectedOption) =>
                  setCoupon({ ...coupon, product_id: selectedOption })
                }
                options={productsForCoupSelect}
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Пользователь
            </Grid>
            <Grid item xs={8}>
              <Select
                styles={propStyle.selectStyle}
                placeholder='Выберите пользователя'
                value={coupon.user_id}
                isSearchable
                onChange={(selectedOption) =>
                  setCoupon({ ...coupon, user_id: selectedOption })
                }
                options={userForCoupSelect}
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Дата окончания
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={styles.input}
                id='date'
                label='Дата окончания'
                type='date'
                variant='outlined'
                value={coupon.date_end}
                onChange={(e) => {
                  setCoupon({ ...coupon, date_end: e.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {/*  */}
        </Box>
        <Box width='100%' mt={4}>
          <Grid container direction='row' justify='center'>
            <Box mr={3}>
              <Button
                className={styles.submitButton}
                variant='contained'
                onClick={() => {
                  onSubmit();
                  // clearData();
                }}
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={onCloseModal} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Notify
        closeFn={onCloseActionCouponsMessage}
        status='success'
        text='Купон успешно создан!'
        isOpen={isCouponsActionSucces}
      />
      <Notify
        closeFn={onCloseActionCouponsMessage}
        status='error'
        text='Ошибка при создании купона!'
        isOpen={isCouponsActionError}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  coupons: {
    productsForCoupSelect,
    userForCoupSelect,
    isCouponsActionSucces,
    isCouponsActionError,
  },
}) => {
  return {
    productsForCoupSelect,
    userForCoupSelect,
    isCouponsActionSucces,
    isCouponsActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDataForCoupSelect: () => dispatch(onGetDataForCoupSelect()),
    onAddCoupon: (data) => dispatch(onAddCoupon(data)),
    onCloseActionCouponsMessage: () => dispatch(onCloseActionCouponsMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCouponModal);
