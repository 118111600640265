import * as constant from '../constants';
import { onAuthUserReq } from '../../api/auth';
import axios from '../../api/axios';

const onSuccessAuthUser = (data) => {
  const token = data.token;

  localStorage.setItem('@token', JSON.stringify(token));
  localStorage.setItem('@userData', JSON.stringify(data));

  axios.defaults.headers.common['Authorization'] = `Basic ${token}`;

  return {
    type: constant.UPDATE_AUTH_STATUS,
    payload: true,
  };
};

const updateAuthStatus = (bool, type) => {
  return {
    type,
    payload: bool,
  };
};

export const onAuthUser = (userData) => {
  return async (dispatch) => {
    try {
      const data = await onAuthUserReq(userData);

      if (data.status !== 200) {
        dispatch({
          type: constant.IS_AUTH_ERROR,
        });
        return;
      }
      dispatch(updateAuthStatus(true, constant.IS_AUTH_SUCCESS));
      dispatch(onSuccessAuthUser(data.data.data));
    } catch (err) {
      dispatch({
        type: constant.IS_AUTH_ERROR,
      });
    }
  };
};

export const onCheckAuthUser = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('@token');
    if (token) {
      dispatch(updateAuthStatus(false, constant.IS_AUTH_SUCCESS));
      dispatch(updateAuthStatus(true, constant.UPDATE_AUTH_STATUS));
      return;
    } else {
      return dispatch(updateAuthStatus(false, constant.IS_AUTH_SUCCESS));
    }
  };
};

export const onLogoutUser = () => {
  localStorage.removeItem('@token');
  localStorage.removeItem('@userData');
  return {
    type: constant.UPDATE_AUTH_STATUS,
    payload: false,
  };
};

export const onCloseAuthStatusMessage = () => {
  return {
    type: constant.ON_CLOSE_AUTH_STATUS_MESSAGE,
  };
};
