import * as constant from '../constants';
import {
  onGetUsersListReq,
  onGetUserByIdReq,
  onEditUserReq,
  onCreateNewUserReq,
  onDeleteUserReq,
  onBlockUserReq,
  onGetUserStatsReq,
  onGetUserCardsListReq,
} from '../../api/users';
import {
  onAddCardReq,
  onDeleteCardReq,
  onEditBalanceValueReq,
  onAttachUserCardReq,
} from '../../api/cards';
import { dispatchLoadingStatus } from './helpersFuncions';

export const onGetUsersList = (limit, term) => {
  const type = constant.IS_LOADING_USERS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list, count },
        },
      } = await onGetUsersListReq(limit, term);

      dispatch({
        type: constant.ON_GET_USERS_LIST,
        payload: { list, count },
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onGetUserById = (id) => {
  const type = constant.IS_LOADING_USER_BY_ID;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: { data },
      } = await onGetUserByIdReq(id);

      dispatch({
        type: constant.ON_GET_USER_BY_ID,
        payload: data,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onEditUser = (userData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditUserReq(userData);
      const usersList = getState().users.usersList;
      const count = getState().users.usersCount;

      const idx = usersList.findIndex((el) => el.id === userData.id);

      const newList = [
        ...usersList.slice(0, idx),
        { ...data, card_list: userData.card_list },
        ...usersList.slice(idx + 1),
      ];

      dispatch({
        type: constant.ON_GET_USERS_LIST,
        payload: { list: newList, count },
      });
      dispatch({
        type: constant.IS_USER_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_USER_ACTION_ERROR,
      });
    }
  };
};

export const onCreateNewUser = (userData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onCreateNewUserReq(userData);

      const usersList = [{ ...data, active: 1 }, ...getState().users.usersList];
      const count = getState().users.usersCount;

      dispatch({
        type: constant.ON_GET_USERS_LIST,
        payload: { list: usersList, count: count + 1 },
      });
      dispatch({
        type: constant.IS_USER_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_USER_ACTION_ERROR,
      });
    }
  };
};

export const onDeleteUser = (id) => {
  return async (dispatch, getState) => {
    await onDeleteUserReq(id);

    const usersList = getState().users.usersList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_USERS_LIST,
      payload: usersList,
    });
  };
};

export const updateUsersCardsList = (cardsList, userId) => {
  return async (dispatch, getState) => {
    const users = getState().users.usersList;

    const curUser = users.filter((el) => el.id === userId)[0];
    curUser.card_list = cardsList;

    const idx = users.findIndex((el) => el.id === userId);

    const list = [...users.slice(0, idx), curUser, ...users.slice(idx + 1)];

    dispatch({
      type: constant.ON_GET_USERS_LIST,
      payload: list,
    });
  };
};

export const changeUserBlockMessage = (text) => {
  return {
    type: constant.GET_USER_BLOCK_MESSAGE,
    payload: text,
  };
};

export const onBlockUser = (userData) => {
  return async (dispatch, getState) => {
    await onBlockUserReq(userData);
    const users = getState().users.usersList;
    const idx = users.findIndex((el) => el.id === userData.id);

    const curUser = users.filter((el) => el.id === userData.id)[0];

    const list = [
      ...users.slice(0, idx),
      { ...curUser, active: userData.active },
      ...users.slice(idx + 1),
    ];

    dispatch({
      type: constant.ON_GET_USERS_LIST,
      payload: list,
    });

    const text =
      userData.active === 1
        ? 'Вы разблокировали пользователя'
        : 'Вы заблокировали пользователя';

    dispatch(changeUserBlockMessage(text));
  };
};

export const onGetUserStats = (id) => {
  const type = constant.IS_LOADING_USERS_STATS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { data },
        },
      } = await onGetUserStatsReq(id);

      if (data.length) {
        dispatch({
          type: constant.ON_GET_USER_STATS_LIST,
          payload: data,
        });
      } else {
        dispatch({
          type: constant.ON_GET_USER_STATS_LIST,
          payload: [],
        });
      }
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onGetUserCardsList = (id) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await onGetUserCardsListReq(id);

    dispatch({
      type: constant.ON_GET_USER_CARDS_LIST,
      payload: data.card_list,
    });
  };
};

export const onErrorCreateCard = (bool = false, message) => {
  return {
    type: constant.IS_CREATE_CARD_ERROR,
    payload: { bool, message },
  };
};

export const onSuccessCreateCard = (bool = false) => {
  return {
    type: constant.IS_CREATE_CARD_SUCCESS,
    payload: bool,
  };
};

export const onCreateUserCard = ({ number, user_id }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddCardReq({ number, user_id });

      const cards = [...getState().users.userCardsList, { ...data[0] }];

      dispatch({
        type: constant.ON_GET_USER_CARDS_LIST,
        payload: cards,
      });

      dispatch(onSuccessCreateCard(true));
      dispatch(onErrorCreateCard(false));

      setTimeout(() => dispatch(onSuccessCreateCard(false)), 3000);
    } catch (e) {
      const message = e.response.data.errors.number[0];

      dispatch(onSuccessCreateCard(false));
      dispatch(onErrorCreateCard(true, message));
    }
  };
};

export const onAttachUserCard = ({ number, user_id }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAttachUserCardReq({ number, user_id });

      const cardsList = getState().users.userCardsList;
      const prevCardsList = cardsList?.length ? cardsList : [];

      const cards = [...prevCardsList, data];

      dispatch({
        type: constant.ON_GET_USER_CARDS_LIST,
        payload: cards,
      });

      dispatch(onSuccessCreateCard(true));
      dispatch(onErrorCreateCard(false));

      setTimeout(() => dispatch(onSuccessCreateCard(false)), 3000);
    } catch (e) {
      const message = e.response.data.errors.number[0];

      dispatch(onSuccessCreateCard(false));
      dispatch(onErrorCreateCard(true, message));
    }
  };
};

export const onDeleteUserCard = (number) => {
  return async (dispatch, getState) => {
    await onDeleteCardReq(number);
    const cards = getState().users.userCardsList.filter(
      (el) => el.number !== number
    );

    dispatch({
      type: constant.ON_GET_USER_CARDS_LIST,
      payload: cards,
    });
  };
};

export const onEditBalanceValue = (id, value) => {
  return async (dispatch, getState) => {
    try {
      await onEditBalanceValueReq(id, value);

      const cards = getState().users.userCardsList;
      const idx = cards.findIndex((el) => el.bills[0].id === id);

      const list = [
        ...cards.slice(0, idx),
        {
          ...cards[idx],
          bills: [{ ...cards[idx].bills[0], real_value: value }],
        },
        ...cards.slice(idx + 1),
      ];

      dispatch({
        type: constant.ON_GET_USER_CARDS_LIST,
        payload: list,
      });
    } catch (e) {}
  };
};

export const onCloseUserActionMessages = () => {
  return {
    type: constant.ON_CLOSE_ACTION_USER_MESSAGE,
  };
};
