import axios from './axios';

export const onGetOrdersListReq = async (limit) => {
  return await axios.post('/orders/list', {
    order: 'created_at',
    dir: 'desc',
    limit: 20,
    offset: +limit === 1 ? 0 : limit,
  });
};

export const onGetOrderByIdReq = async (id) => {
  return await axios.get(`/orders/get/${id}`);
};

export const onDeleteOrderReq = async (id) => {
  return await axios.get(`/orders/delete/${id}`);
};
