import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import MigrationsTable from '../FiltersTables/MigrationsTable';
import FiltersSearchButton from '../FiltersSearchButton/FiltersSearchButton';
import MigrationsFilterModal from '../FiltersModals/MigrationsFilterModal/MigrationsFilterModal';

const MigrationsFilters = ({ users, setUsers, selectAll }) => {
  const [loading, setLoading] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  return (
    <Box mx={2}>
      <Grid container justify='flex-end'>
        <FiltersSearchButton title='Поиск' submit={() => setShowModal(true)} />
      </Grid>
      <MigrationsTable
        list={users}
        loading={loading}
        setList={setUsers}
        selectAll={selectAll}
      />
      <MigrationsFilterModal
        isShowModal={isShowModal}
        closeModal={() => setShowModal(false)}
        setUsers={setUsers}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default MigrationsFilters;
