import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid, Button, Box, TextField, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Notify from '../../components/Notify';

import { onAuthUser, onCloseAuthStatusMessage } from '../../store/actions/auth';

const AuthPage = ({
  onAuthUser,
  isAuthError,
  isAuthSuccess,
  onCloseAuthStatusMessage,
}) => {
  const style = useStyles();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const onSubmitAuth = (e) => {
    e.preventDefault();
    if (phone.length < 3 && password.length < 3) {
      setPhoneError('Введите корректный телефон');
      setPasswordError('Введите корректный пароль');
    } else if (phone.length < 3) {
      setPhoneError('Введите корректный логин');
      setPasswordError('');
      return;
    } else if (password.length < 3) {
      setPasswordError('Введите корректный пароль');
      setPhoneError('');
      return;
    } else {
      setPhoneError('');
      setPasswordError('');
      onAuthUser({ phone, password });
    }
  };

  return (
    <Grid container justify='center' alignItems='center'>
      <form onSubmit={onSubmitAuth}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          height='80vh'
          m={3}
        >
          <Box mb={3}>
            <Typography variant='h4' component='h4'>
              Авторизация
            </Typography>
          </Box>
          <Box m={2}>
            <TextField
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              className={style.authInput}
              error={!!phoneError.length}
              label='Логин'
              helperText={phoneError}
              type='text'
              autoComplete='false'
            />
          </Box>
          <Box mt={1}>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className={style.authInput}
              error={!!passwordError.length}
              label='Пароль'
              helperText={passwordError}
              type='password'
              autoComplete='false'
            />
          </Box>
          <Box mt={2}>
            <Button type='submit' variant='outlined' color='primary'>
              Войти
            </Button>
          </Box>
        </Box>
      </form>
      <Notify
        closeFn={onCloseAuthStatusMessage}
        status='success'
        text='Вы успешно авторизировались!'
        isOpen={isAuthSuccess}
      />
      <Notify
        closeFn={onCloseAuthStatusMessage}
        status='error'
        text='Неверный логин или пароль!'
        isOpen={isAuthError}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  authInput: {
    width: 300,
  },
  authLink: {
    textDecoration: 'none',
  },
}));

const mapStateToProps = ({ auth: { isAuthError, isAuthSuccess } }) => {
  return {
    isAuthError,
    isAuthSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthUser: (data) => dispatch(onAuthUser(data)),
    onCloseAuthStatusMessage: () => dispatch(onCloseAuthStatusMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
