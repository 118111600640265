import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableFooter,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import propStyle from '../../../resourses/propStyles';

import Spinner from '../../Spinner';
import UserStatsModal from '../../Modals/UserStatsModal';

import TablePaginationActions from '../TablePagination';

import { onGetUserStats } from '../../../store/actions/users';

const UserStatsTable = ({
  id,
  onGetUserStats,
  userStatsList,
  loadingUserStatsList,
  filterCard,
}) => {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [stats, setStats] = useState({});
  const [list, setList] = useState([]);
  const [defaultList, setDefaultList] = useState([]);
  const [date, setDate] = useState('');
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);

  useEffect(() => {
    onGetUserStats(id);
  }, [onGetUserStats, id]);

  useEffect(() => {
    if (filterCard) {
      setDate('');
      setList(defaultList.filter((el) => el.card_number === filterCard));
    }
  }, [filterCard, defaultList]);

  useEffect(() => {
    if (date) {
      setList(defaultList.filter((el) => el.dt.split(' ')[0] === date));
    } else {
      setList(defaultList);
    }
  }, [date, defaultList]);

  useEffect(() => {
    if (userStatsList === undefined) {
      setList(list);
      setDefaultList(defaultList);
    } else {
      setList(userStatsList);
      setDefaultList(userStatsList);
    }
    // eslint-disable-next-line
  }, [userStatsList, id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (loadingUserStatsList) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onShowUserStats = (el) => {
    setStats(el);
    setShowModal(true);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
                <Input
                  disableUnderline={true}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  id='date'
                  label='Фильтр по дате'
                  type='date'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        color='inherit'
                        className={styles.clearDateBtn}
                        onClick={() => setDate('')}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
              <TableCell align='center'>Магазин</TableCell>
              <TableCell align='center'>Пользователь</TableCell>
              <TableCell align='center'>Телефон</TableCell>
              <TableCell align='center'>Бонус</TableCell>
              <TableCell align='center'>Списание</TableCell>
              <TableCell align='center'>Бонусы</TableCell>
              <TableCell align='center'>Номер карты</TableCell>
              <TableCell align='center'>Сумма покупки</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {(rowsPerPage > 0 && list?.length
                ? list.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : list
              ).map((el) => {
                return (
                  <TableRow
                    onClick={() => onShowUserStats(el.basket)}
                    key={el.id}
                    className={styles.tableItem}
                  >
                    <TableCell align='center'>{el.dt}</TableCell>
                    <TableCell align='center'>{el.outlet_name}</TableCell>
                    <TableCell align='center'>
                      {el?.user_first_name} {el.user_second_name}
                    </TableCell>
                    <TableCell align='center'>{el.users_phone}</TableCell>
                    <TableCell align='center'>
                      {+el.bonus?.added
                        ? (+el.bonus?.added).toFixed(2) + '₽'
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {+el.bonus?.debited
                        ? (+el.bonus?.debited).toFixed(2) + '₽'
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {+el.bonus?.accumulated
                        ? (+el.bonus?.accumulated).toFixed(2) + '₽'
                        : null}
                    </TableCell>
                    <TableCell align='center'>{el.card_number}</TableCell>
                    <TableCell align='center'>
                      {(+el?.amount).toFixed(2)} ₽
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={10}
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={() => (
                  <TablePaginationActions
                    colSpan={4}
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={null}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!list.length ? (
        <Grid container justify='center'>
          <Box fontSize={22} mt={3}>
            Пользователь пока не совершил ни одной покупки.
          </Box>
        </Grid>
      ) : null}
      <UserStatsModal open={showModal} setOpen={setShowModal} basket={stats} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  clearDateBtn: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  container: {},
  table: {
    minWidth: 650,
  },
  tableItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: propStyle.shadowedColor,
    },
  },
}));

const mapStateToProps = ({
  users: { userStatsList, loadingUserStatsList },
}) => {
  return { userStatsList, loadingUserStatsList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserStats: (id) => dispatch(onGetUserStats(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStatsTable);
