const propStyles = {
  orangeColor: 'rgb(255, 189, 61)',
  shadowColor: 'rgba(0, 0, 0, 0.3)',
  shadowedColor: 'rgba(0, 0, 0, 0.1)',
  blueActiveColor: 'rgb(33, 150, 243)',
  borderColor: 'rgb(158, 158, 158)',
  successColor: '#4caf50',
  redColor: '#f44336',
  whiteColor: '#fff',
  selectStyle: {
    control: (base) => ({
      ...base,
      height: 54,
    }),
    menu: (provided) => ({ ...provided, zIndex: 2 }),
  },
};

export default propStyles;
