import React from 'react';

import {
  Grid,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';

const ActionUserModal = ({
  headerLabel,
  setOpen,
  onHandleChange,
  user,
  onSubmit,
  addModal,
  setUser,
}) => {
  const styles = useStyles();

  return (
    <Grid container direction='column' className={styles.modal}>
      <HeaderForModal setOpen={setOpen} headerLabel={headerLabel} />
      <Box>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid className={styles.pr20} item xs={4}>
            <TextField
              onChange={onHandleChange}
              value={user.first_name}
              name='first_name'
              className={styles.input}
              label='Имя'
              variant='outlined'
            />
          </Grid>
          <Grid className={styles.pr20} item xs={4}>
            <TextField
              onChange={onHandleChange}
              value={user.second_name}
              name='second_name'
              className={styles.input}
              label='Фамилия'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              onChange={onHandleChange}
              name='third_name'
              value={user.third_name}
              className={styles.input}
              label='Отчество'
              variant='outlined'
            />
          </Grid>
        </Grid>

        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid className={styles.pr20} item xs={4}>
            <FormControl className={styles.formControl} variant='outlined'>
              <InputLabel>Роль</InputLabel>
              <Select
                onChange={onHandleChange}
                className={styles.input}
                value={user.type}
                name='type'
                label='Категория'
              >
                <MenuItem value={0}>Админ</MenuItem>
                <MenuItem value={1}>Клиент</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid className={styles.pr20} item xs={4}>
            <TextField
              fullWidth
              variant='outlined'
              value={user.birthday}
              onChange={onHandleChange}
              name='birthday'
              label='День рождения'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              onChange={onHandleChange}
              InputProps={{
                readOnly: !addModal,
              }}
              name='phone'
              value={user.phone}
              className={styles.input}
              label='Номер телефона'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          {user.type === 0 && (
            <Grid className={styles.pr20} item xs={4}>
              <TextField
                onChange={onHandleChange}
                value={user.password}
                name='password'
                className={styles.input}
                label='Пароль'
                variant='outlined'
                type='password'
              />
            </Grid>
          )}
        </Grid>

        <Box>
          <Box mb={2}>
            <Typography>Дополнительные поля</Typography>
          </Box>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            alignItems='center'
          >
            {user.fields?.length &&
              user.fields.map((el, i) => (
                <Grid
                  key={i}
                  className={`${styles.editFieldContainer} ${styles.pr20}`}
                  item
                  xs={4}
                >
                  <TextField
                    onChange={(e) =>
                      setUser({
                        ...user,
                        fields: user.fields.map((fld, idx) => {
                          return i === idx
                            ? {
                                ...fld,
                                value: e.target.value,
                              }
                            : fld;
                        }),
                      })
                    }
                    value={user.fields[i].value}
                    name={el.name}
                    className={styles.input}
                    label={el.name}
                    variant='outlined'
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
      <Box width='100%' mt={4}>
        <Grid container direction='row' justify='center'>
          <Box mr={3}>
            <Button
              className={styles.submitButton}
              variant='contained'
              onClick={onSubmit}
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={() => setOpen(false)} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
  pr20: {
    paddingRight: 20,
  },
}));

export default ActionUserModal;
