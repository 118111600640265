import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import { onGetOrdersDaysStatsReq } from '../../api/stats';

export const onGetOrdersDaysStats = () => {
  const type = constant.IS_LOADING_ORDERS_DAYS_STATS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: { data },
      } = await onGetOrdersDaysStatsReq();

      dispatch({
        type: constant.ON_GET_ORDERS_DAYS_STATS,
        payload: data,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};
