import * as constant from '../constants';
import {
  onGetOrdersListReq,
  onGetOrderByIdReq,
  onDeleteOrderReq,
} from '../../api/orders';
import { dispatchLoadingStatus } from './helpersFuncions';

export const onGetOrdersList = (limit) => {
  const type = constant.IS_LOADING_ORDERS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: { data },
      } = await onGetOrdersListReq(limit);

      dispatch({
        type: constant.ON_GET_ORDERS_LIST,
        payload: { list: data.data, count: data.count },
      });
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onGetOrderById = (id) => {
  const type = constant.IS_LOADING_ORDER_BY_ID;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    const {
      data: { data },
    } = await onGetOrderByIdReq(id);

    dispatch({
      type: constant.ON_GET_ORDER_BY_ID,
      payload: data,
    });
    try {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onDeleteOrder = (id) => {
  return async (dispatch, getState) => {
    await onDeleteOrderReq(id);

    const orders = getState().orders.ordersList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_ORDERS_LIST,
      payload: { list: orders },
    });
  };
};
