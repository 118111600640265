import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Button, Tabs, Tab } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../resourses/propStyles';

import Header from '../../components/Header';
import UsersTable from '../../components/Tables/UsersTable';
import NotifyModal from '../../components/Modals/NofifyModal';

import { onGetUsersList } from '../../store/actions/users';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UsersPage = ({
  usersCount,
  onGetUsersList,
  usersList,
  loadingUsersList,
}) => {
  const styles = useStyles();
  const [tab, setTab] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [page, setPage] = useState(0);
  const [term, setTerm] = useState('');

  useEffect(() => {
    if (page === 0) {
      onGetUsersList(1, term);
    } else {
      if (term) setPage(0);

      onGetUsersList(!term ? +page * 20 : 1, term);
    }
  }, [onGetUsersList, page, term]);

  const handleChange = (e, tabValue) => {
    setTab(tabValue);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Header label={'Пользователи'} />

      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Box mr={2}>
          <Button
            onClick={() => setNotifyModal(true)}
            className={styles.addButton}
            variant='contained'
            startIcon={<MailOutlineIcon />}
          >
            Отправить уведомление
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => setAddModal(true)}
            className={styles.addButton}
            variant='contained'
          >
            Добавить
          </Button>
        </Box>
      </Grid>
      <Box px={2}>
        <Tabs
          className={styles.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: propStyle.orangeColor,
            },
          }}
          value={tab}
          onChange={handleChange}
        >
          <Tab label='Пользователи' {...a11yProps(0)} />
        </Tabs>
        <Box p={2}>
          <UsersTable
            handleChangePage={handleChangePage}
            page={page}
            setPage={setPage}
            addModal={addModal}
            setAddModal={setAddModal}
            list={usersList}
            loading={loadingUsersList}
            count={usersCount || 0}
            term={term}
            setTerm={setTerm}
          />
        </Box>
      </Box>
      <NotifyModal open={notifyModal} setOpen={setNotifyModal} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  users: { usersList, loadingUsersList, usersCount },
}) => ({ usersList, loadingUsersList, usersCount });

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsersList: (limit, term) => dispatch(onGetUsersList(limit, term)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
