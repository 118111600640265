import * as constant from '../constants';

const initialState = {
  productsList: [],
  loadingProductsList: true,
  categoriesForProductsPage: [],
  categoriesSelect: [],
  isProductActionSuccess: false,
  isProductActionError: false,
};

export const productsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_PRODUCTS_LIST:
      return {
        ...state,
        productsList: payload,
      };
    case constant.IS_LOADING_PRODUCTS_LIST:
      return {
        ...state,
        loadingProductsList: payload,
      };
    case constant.ON_ADD_PRODUCT:
      return {
        ...state,
        productsList: payload,
      };
    case constant.ON_EDIT_PRODUCT:
      return {
        ...state,
        productsList: payload,
      };
    case constant.IS_PRODUCT_ACTION_SUCCESS:
      return {
        ...state,
        isProductActionSuccess: true,
      };

    case constant.IS_PRODUCT_ACTION_ERROR:
      return {
        ...state,
        isProductActionError: true,
      };
    case constant.ON_CLOSE_ACTION_PRODUCT_MESSAGE:
      return {
        ...state,
        isProductActionSuccess: false,
        isProductActionError: false,
      };
    case constant.ON_GET_CATEGORIES_FOR_PRODUCTS_PAGE:
      return {
        ...state,
        categoriesForProductsPage: payload,
      };

    case constant.ON_GET_CAT_AND_SUBCAT_FOR_SELECT:
      return {
        ...state,
        categoriesSelect: payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
