import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import MigrationsOutlets from '../../../../../components/MigrationsOutlets/';
import MigrationsPicker from '../../../../../components/MigrationsPicker';
import HeaderForModal from '../../../../../components/Modals/HeaderForModal';
import { API_PATH } from '../../../../../resourses/variables';
import {
  downloadMigrationListReq,
  getMigrationsListReq,
} from '../../../../../api/migrations';
import propStyles from '../../../../../resourses/propStyles';
import { useCreateNotify } from '../../../../../hooks/useCreateNotify';

const MigrationsFilterModal = ({
  isShowModal,
  closeModal,
  loading,
  setUsers,
  setLoading,
}) => {
  const styles = useStyles();
  const { createNotify } = useCreateNotify();

  const [firstPeriod, setFirstPeriod] = useState({ start: '', end: '' });
  const [secondPeriod, setSecondPeriod] = useState({ start: '', end: '' });
  const [onlyLosses, setOnlyLosses] = useState(false);
  const [outlets, setOutlets] = useState([]);

  const handleChangeOnlyLosses = (e) => {
    setOnlyLosses(e.target.checked);
  };

  const createErrorNotify = () => {
    createNotify({
      notifyStatus: 'error',
      notifyText: 'Выберите все поля, чтобы продолжить!',
      isShowNotify: true,
    });
  };

  const isPeriodValid = (period) => Object.values(period).every((el) => !!el);

  const isValidForm =
    isPeriodValid(firstPeriod) && isPeriodValid(secondPeriod) && outlets.length;

  const paramsData = {
    date_begin_1: firstPeriod.start,
    date_end_1: firstPeriod.end,
    date_begin_2: secondPeriod.start,
    date_end_2: secondPeriod.end,
    outlet_ids: outlets.map((el) => el.id),
    only_losses: Number(onlyLosses),
  };

  const searchUsers = async () => {
    if (!isValidForm) return createErrorNotify();

    setLoading(true);
    try {
      const {
        data: { data },
      } = await getMigrationsListReq(paramsData);

      setUsers(data.map((user) => ({ ...user, checked: false })));
    } finally {
      setLoading(false);
    }
  };

  const downloadMigrationsList = async () => {
    const {
      data: { data },
    } = await downloadMigrationListReq(paramsData);

    window.location.href = API_PATH + data;
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box className={styles.container}>
        <HeaderForModal setOpen={closeModal} headerLabel='Заполните все поля' />
        <MigrationsPicker
          label='Первый период'
          period={firstPeriod}
          setPeriod={setFirstPeriod}
        />
        <MigrationsPicker
          label='Второй период'
          period={secondPeriod}
          setPeriod={setSecondPeriod}
        />
        <Box className={styles.headerContainer}>
          <MigrationsOutlets outlets={outlets} setOutlets={setOutlets} />
          <FormControlLabel
            className={styles.onlyLossesContainer}
            control={
              <Checkbox
                checked={onlyLosses}
                onChange={handleChangeOnlyLosses}
              />
            }
            label='Отрицательная разница'
            labelPlacement='start'
          />
        </Box>
        <Grid container justify='flex-end'>
          <Button
            className={[styles.searchButton, styles.downloadButton]}
            variant='contained'
            disabled={!isValidForm}
            onClick={downloadMigrationsList}
          >
            Скачать миграционный отчет
          </Button>
          <Button
            className={styles.searchButton}
            variant='contained'
            disabled={loading}
            onClick={searchUsers}
          >
            Поиск
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 14,
  },
  headerContainer: {
    display: 'flex',
  },
  periodsContainer: {
    gap: 14,
    marginBottom: 14,
  },
  searchButton: {
    display: 'block',
    width: 160,
    backgroundColor: propStyles.orangeColor,
    marginTop: 30,
  },
  downloadButton: {
    width: 300,
    backgroundColor: propStyles.shadowedColor,
    marginRight: 14,
  },
  onlyLossesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 20,
  },
}));

export default MigrationsFilterModal;
