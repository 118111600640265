import axios from './axios';

export const getMigrationsListReq = async (params) =>
  await axios.post('/statistic/sales_migrations', params);

export const downloadMigrationListReq = async (params) =>
  await axios.post('statistic/sales_migrations_report', params);

export const getUsersSalesLastDateReq = async (params) =>
  await axios.post('/statistic/users_sales_1', params);

export const getUsersSalesByPriceReq = async (params) =>
  await axios.post('/statistic/users_sales_2', params);

export const getUsersSalesByPriceAndDateTotalReq = async (params) =>
  await axios.post('/statistic/users_sales_3', params);

export const getUsersSalesByPriceAndDateAverageReq = async (params) =>
  await axios.post('/statistic/users_sales_4', params);

export const getUsersSalesByCategoriesReq = async (params) =>
  await axios.post('/statistic/users_sales_5', params);

export const setBonusesForUsersReq = async ({ users, title, body }) =>
  await axios.post('sendPushByUsersReq', {
    client_ids: users,
    title,
    body,
  });