import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';

import { onEditBalanceValue } from '../../../store/actions/users';

const EditCardBalanceModal = ({
  open,
  setOpen,
  balanceValue,
  id,
  onEditBalanceValue,
}) => {
  const styles = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(balanceValue);
  }, [balanceValue]);

  const onSubmit = (e) => {
    e.preventDefault();
    onEditBalanceValue(id, value);
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box className={styles.modal}>
        <HeaderForModal
          setOpen={onClose}
          headerLabel='Редактирование баланса'
        />
        <form onSubmit={onSubmit}>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid item xs={4}>
              <Typography>Баланс</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={(e) => setValue(e.target.value)}
                value={value}
                name='card'
                className={styles.input}
                variant='outlined'
                label='Баланс'
                required
              />
            </Grid>
          </Grid>
          {/*  */}
          {/* <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
            style={{ marginTop: 20 }}
          >
            <Grid item xs={4}>
              <Typography>Процент</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={(e) => setValue(e.target.value)}
                value={value}
                name='card'
                className={styles.input}
                variant='outlined'
                label='Процент'
                required
              />
            </Grid>
          </Grid> */}
          {/*  */}
          <Box width='100%' mt={4}>
            <Grid container direction='row' justify='center'>
              <Box mr={3}>
                <Button
                  type='submit'
                  className={styles.submitButton}
                  variant='contained'
                >
                  Сохранить
                </Button>
              </Box>
              <Box>
                <Button onClick={() => setOpen(false)} variant='contained'>
                  Отмена
                </Button>
              </Box>
            </Grid>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onEditBalanceValue: (id, value) => dispatch(onEditBalanceValue(id, value)),
  };
};

export default connect(null, mapDispatchToProps)(EditCardBalanceModal);
