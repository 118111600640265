import React from 'react';

import { Dialog, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import HeaderForModal from '../HeaderForModal';

const UserStatsModal = ({ open, setOpen, basket }) => {
  const styles = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box className={styles.modal}>
        <HeaderForModal
          setOpen={setOpen}
          headerLabel={'Покупки пользователя'}
        />
        <Box>
          <Box mb={2}>
            <Typography variant='h6' component='h6'>
              Товары ({basket?.length})
            </Typography>
          </Box>
          {basket.length &&
            basket.map((el) => (
              <Grid
                key={el.id}
                className={styles.basketContainer}
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Grid className={styles.basketItem} item xs={3}>
                  <Typography className={styles.basketProductName}>
                    {el.product_name}
                  </Typography>
                </Grid>
                <Grid className={styles.basketItem} item xs={3}>
                  {el.count} {el.product_is_by_weight === 1 ? 'кг.' : 'шт.'}
                </Grid>
                <Grid className={styles.basketItem} item xs={3}>
                  <Typography className={styles.basketProductAmount}>
                    {+(+el.amount).toFixed(2) * +el.count} ₽
                  </Typography>
                </Grid>
              </Grid>
            ))}

          <Box width='100%' pt={1} borderTop={1} borderColor='grey.500'>
            <Grid
              className={styles.basketContainer}
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Grid className={styles.basketItem} item xs={3}>
                <Typography className={styles.basketProductName}>
                  Итого
                </Typography>
              </Grid>
              <Grid className={styles.basketItem} item xs={3}></Grid>
              <Grid className={styles.basketItem} item xs={3}>
                <Typography className={styles.basketProductAmount}>
                  {basket.length &&
                    (+basket.reduce(
                      (acc, val) => acc + +val.amount * +val.count,
                      0
                    )).toFixed(2)}{' '}
                  ₽
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 14,
  },
  basketContainer: {
    marginBottom: 10,
  },
  basketItem: {
    textAlign: 'center',
  },
}));

export default UserStatsModal;
