import * as contant from '../constants';

export const closeNotify = () => ({
  type: contant.SET_NOTIFY,
  payload: {
    notifyStatus: '',
    notifyText: '',
    isShowNotify: false,
  },
});

export const setNotify = ({ notifyStatus, notifyText, isShowNotify }) => {
  return (dispatch) => {
    dispatch({
      type: contant.SET_NOTIFY,
      payload: {
        notifyStatus,
        notifyText,
        isShowNotify,
      },
    });

    setTimeout(() => {
      dispatch(closeNotify());
    }, 3500);
  };
};
