import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import ActionNewsModal from '../ActionNewsModal';

import { onEditNews } from '../../../store/actions/news';

const EditNewsModal = ({
  open,
  setOpen,
  onEditNews,

  newsId,
  newsData,
  headerLabel,
}) => {
  const onSubmit = (news, img, showQuestionnaire) => {
    const data = { ...news };

    data.file_content = img;

    if (showQuestionnaire) {
      data.question_bonus_duration = +data.question_bonus_duration;
      data.question_bonus_value = +data.question_bonus_value;
    }

    onEditNews({ ...data, id: newsId }, showQuestionnaire);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionNewsModal
        headerLabel={headerLabel}
        setOpen={setOpen}
        newsData={newsData}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditNews: (data, showQuestionnaire) =>
      dispatch(onEditNews(data, showQuestionnaire)),
  };
};

export default connect(null, mapDispatchToProps)(EditNewsModal);
