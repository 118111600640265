import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import propStyle from '../../../resourses/propStyles';

import Select from 'react-select';
import HeaderForModal from '../HeaderForModal';
import NewCropImageModal from '../NewCropImageModal';

import {
  onGetCatAndSubCatForSelect,
  onCloseProductActionMessages,
} from '../../../store/actions/products';
import ProductImagesCarousel from '../../ProductImagesCarousel';

const ActionProductModal = ({
  onGetCatAndSubCatForSelect,
  categoriesSelect,
  onSubmit,
  productData,
  setOpen,
  edit,
}) => {
  const styles = useStyles();
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [product, setProduct] = useState({
    category_id: '',
    subCategoryId: '',
    name: '',
    description: '',
    price: '',
    is_hit: false,
    is_novelty: false,
    is_stock: false,
    is_hidden: false,
    code: '',
    is_by_weight: 0,
    images: [],
  });

  useEffect(() => {
    if (productData && !edit) {
      setProduct({ ...productData, subCategoryId: '' });
    }
  }, [productData, edit]);

  useEffect(() => {
    if (categoriesSelect.length && edit) {
      setProduct({
        ...productData,
        is_hidden: !!productData.visible ? false : true,
        category_id: productData.parent_category_id
          ? {
              value: productData.parent_category_id,
              label: productData.parent_category_name,
            }
          : {
              value: productData.category_id,
              label: productData.category_name,
            },
        subCategoryId: productData.parent_category_id
          ? {
              value: productData.category_id,
              label: productData.category_name,
            }
          : '',
      });
    }
  }, [categoriesSelect, productData, edit]);

  useEffect(() => {
    onGetCatAndSubCatForSelect();
  }, [onGetCatAndSubCatForSelect]);

  const onShowAddFileModal = () => {
    setOpenFileDialog(true);
  };

  const onHandleChange = (e) => {
    const name = e.target.name,
      value = e.target.value,
      checked = e.target.checked;

    if (
      name === 'is_hit' ||
      name === 'is_novelty' ||
      name === 'is_hidden' ||
      name === 'is_stock'
    ) {
      setProduct({ ...product, [name]: checked });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const clearData = () => {
    setProduct({
      ...product,
      name: '',
      description: '',
      price: '',
      // file_content: '',
      is_hit: false,
      is_novelty: false,
      is_hidden: false,
      is_stock: false,
    });
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const removeImg = (name) => {
    if (product.images?.length <= 1) return;

    const images = product.images.filter((el) => el.name !== name);
    setProduct({
      ...product,
      images,
    });
  };

  return (
    <Box className={styles.modal}>
      <HeaderForModal
        setOpen={onCloseModal}
        headerLabel={'Добавление товара'}
      />
      <Box>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid style={{ paddingRight: 20 }} item xs={5}>
            <TextField
              onChange={onHandleChange}
              value={product.name}
              name='name'
              className={styles.input}
              label='Название'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={styles.input}
              onChange={onHandleChange}
              value={product.price}
              name='price'
              label='Стоимость'
              variant='outlined'
            />
          </Grid>
          <Grid
            style={{ display: 'flex', justifyContent: 'space-between' }}
            item
            xs={4}
          >
            <FormControlLabel
              labelPlacement='top'
              control={
                <Checkbox
                  onChange={onHandleChange}
                  checked={!!product.is_hit}
                  name='is_hit'
                />
              }
              label='Хит'
            />
            <FormControlLabel
              labelPlacement='top'
              control={
                <Checkbox
                  onChange={onHandleChange}
                  checked={!!product.is_novelty}
                  name='is_novelty'
                />
              }
              label='Новинка'
            />
            <FormControlLabel
              labelPlacement='top'
              control={
                <Checkbox
                  onChange={onHandleChange}
                  checked={!!product.is_stock}
                  name='is_stock'
                />
              }
              label='Акция'
            />
            <FormControlLabel
              labelPlacement='top'
              control={
                <Checkbox
                  onChange={onHandleChange}
                  checked={product.is_hidden}
                  name='is_hidden'
                />
              }
              label='Скрыть'
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid style={{ paddingRight: 20, position: 'relative' }} item xs={3}>
            <Select
              styles={propStyle.selectStyle}
              placeholder='Категория'
              value={product.category_id}
              isSearchable
              onChange={(selectedOption) => {
                setProduct({
                  ...product,
                  category_id: selectedOption,
                  subCategoryId:
                    categoriesSelect
                      .filter((el) => el.id === selectedOption.value)[0]
                      ?.sub_categories?.map((el) => ({
                        value: el.id,
                        label: el.name,
                      }))[0] || {},
                });
              }}
              options={categoriesSelect.map((el) => ({
                value: el.id,
                label: el.name,
              }))}
            />
          </Grid>
          <Grid style={{ paddingRight: 20 }} item xs={3}>
            <Select
              styles={propStyle.selectStyle}
              placeholder='Подкатегория'
              value={product.subCategoryId}
              isSearchable
              onChange={(selectedOption) =>
                setProduct({ ...product, subCategoryId: selectedOption })
              }
              options={categoriesSelect
                .filter((el) => el.id === product.category_id.value)[0]
                ?.sub_categories?.map((el) => ({
                  value: el.id,
                  label: el.name,
                }))}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={styles.input}
              onChange={onHandleChange}
              value={product.code}
              name='code'
              variant='outlined'
              label='Код'
            />
          </Grid>
          <Grid
            style={{ display: 'flex', justifyContent: 'space-around' }}
            item
            xs={4}
          >
            <RadioGroup
              onChange={(e) =>
                setProduct({ ...product, is_by_weight: Number(e.target.value) })
              }
              row
              defaultValue={product.is_by_weight}
              aria-label='push'
              name='is_by_weight'
              value={product.is_by_weight}
            >
              <FormControlLabel
                style={{ marginLeft: 0 }}
                value={0}
                control={<Radio style={{ color: propStyle.orangeColor }} />}
                label='Поштучно'
                labelPlacement='top'
              />
              <FormControlLabel
                value={1}
                control={<Radio style={{ color: propStyle.orangeColor }} />}
                label='На развес'
                labelPlacement='top'
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid className={styles.editFieldContainer} container direction='row'>
          <Grid item xs={8}>
            <TextField
              onChange={onHandleChange}
              value={product.description}
              name='description'
              className={styles.input}
              label='Описание товара'
              variant='outlined'
              multiline
              rows={12}
            />
          </Grid>
          <Grid style={{ paddingLeft: 20 }} item xs={4}>
            <Box position='relative' className={styles.imgWrapper}>
              <div style={{ width: '100%' }}>
                <Tooltip title='Добавить картинку'>
                  <Box
                    onClick={onShowAddFileModal}
                    className={styles.addPhotoBtn}
                    position='absolute'
                  >
                    <PhotoCamera
                      style={{ color: propStyle.orangeColor }}
                      fontSize='large'
                    />
                  </Box>
                </Tooltip>
                {product?.images?.length ? (
                  <ProductImagesCarousel
                    images={product?.images?.length && product.images}
                    removeImg={removeImg}
                  />
                ) : (
                  <Box className={styles.img} />
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Box mr={3}>
            <Button
              onClick={() => onSubmit(product)}
              className={styles.addButton}
              variant='contained'
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={onCloseModal} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
      <NewCropImageModal
        setImg={(newImg) => {
          setProduct({
            ...product,
            images: product.images?.length
              ? [...product.images, { name: newImg }]
              : [{ name: newImg }],
          });
        }}
        open={openFileDialog}
        setOpen={setOpenFileDialog}
        w={'100%'}
        h={'100%'}
        fw={16}
        fh={16}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  rubleIconWrap: {
    paddingLeft: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  addFileButton: {
    color: propStyle.orangeColor,
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  img: {
    width: '100%',
    height: 246,
    borderRadius: 8,
  },
  formControl: {
    width: '100%',
  },
  addPhotoBtn: {
    cursor: 'pointer',
    zIndex: 1,
    top: '45%',
    left: '45%',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const mapStateToProps = ({
  products: { categoriesSelect, isProductActionSuccess, isProductActionError },
}) => {
  return {
    categoriesSelect,
    isProductActionSuccess,
    isProductActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCatAndSubCatForSelect: () => dispatch(onGetCatAndSubCatForSelect()),
    onCloseProductActionMessages: () =>
      dispatch(onCloseProductActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionProductModal);
