import axios from './axios';

export const onGetNewsListReq = async () => {
  return await axios.post('/news/list');
};

export const onCreateNewsReq = async (data) => {
  return await axios.post('/news/create', {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onEditNewsReq = async (data) => {
  return await axios.post(`/news/edit/${data.id}`, {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onDeleteNewsReq = async (id) => {
  return await axios.get(`/news/delete/${id}`);
};

export const onHideNewsReq = async (id) => {
  return await axios.get(`/news/moderate/${id}`);
};
