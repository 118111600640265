import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';

import {
  onCloseProductActionMessages,
  onEditProduct,
} from '../../../store/actions/products';
import ActionProductModal from '../ActionProductModal';

const EditProductModal = ({
  open,
  setOpen,
  onEditProduct,
  onCloseProductActionMessages,
  isProductActionSuccess,
  isProductActionError,
  productData,
  catId,
}) => {
  const onSubmit = (product) => {
    const data = { ...product };

    data.category_id = data?.subCategoryId?.value
      ? data?.subCategoryId?.value || ''
      : data?.category_id?.value || '';

    delete data.subCategoryId;

    data.is_hit = data.is_hit ? 1 : 0;
    data.is_novelty = data.is_novelty ? 1 : 0;
    data.is_stock = data.is_stock ? 1 : 0;
    data.visible = data.is_hidden ? 0 : 1;

    onEditProduct(data);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionProductModal
        setOpen={setOpen}
        productData={productData}
        onSubmit={onSubmit}
        catId={catId}
        edit={true}
      />

      <Notify
        closeFn={onCloseProductActionMessages}
        status='success'
        text='Товар успешно отредактирован!'
        isOpen={isProductActionSuccess}
      />
      <Notify
        closeFn={onCloseProductActionMessages}
        status='error'
        text='Ошибка при редактировании товара!'
        isOpen={isProductActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  products: {
    categoriesSelect,
    subCategoriesSelect,
    isProductActionSuccess,
    isProductActionError,
  },
}) => {
  return {
    subCategoriesSelect,
    categoriesSelect,
    isProductActionSuccess,
    isProductActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditProduct: (data) => dispatch(onEditProduct(data)),
    onCloseProductActionMessages: () =>
      dispatch(onCloseProductActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductModal);
