import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Dialog, Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Notify from '../../Notify';

import {
  onCloseCategoryActionMessages,
  onGetCategoriesForSelect,
  onAddNewSubCategory,
} from '../../../store/actions/categories';

const AddSubCategoryModal = ({
  open,
  setOpen,
  onCloseCategoryActionMessages,
  onAddNewSubCategory,
  categoryId,
  isCategoryActionSuccess,
  isCategoryActionError,
}) => {
  const styles = useStyles();
  const [name, setName] = useState('');

  const onSubmit = () => {
    if (name && categoryId) {
      onAddNewSubCategory({ name, categoryId });
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box className={styles.modal}>
        <HeaderForModal
          setOpen={setOpen}
          headerLabel={'Добавление подкатегории'}
        />
        <Grid container direction='row' alignItems='center'>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Название
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={styles.input}
                label='Название'
                variant='outlined'
              />
            </Grid>
          </Grid>
          {/*  */}
        </Grid>
        <Box mt={4}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Box mr={3}>
              <Button
                onClick={onSubmit}
                className={styles.addButton}
                variant='contained'
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={() => setOpen(false)} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='success'
        text='Категория успешно создана!'
        isOpen={isCategoryActionSuccess}
      />
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='error'
        text='Ошибка при создании категории!'
        isOpen={isCategoryActionError}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  input: {
    width: '100%',
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
}));

const mapStateToProps = ({
  categories: {
    categoriesForSelect,
    isCategoryActionSuccess,
    isCategoryActionError,
  },
}) => {
  return {
    categoriesForSelect,
    isCategoryActionSuccess,
    isCategoryActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCategoriesForSelect: () => dispatch(onGetCategoriesForSelect()),
    onAddNewSubCategory: (data) => dispatch(onAddNewSubCategory(data)),
    onCloseCategoryActionMessages: () =>
      dispatch(onCloseCategoryActionMessages()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubCategoryModal);
