import * as constant from '../constants';

const initialState = {
  isAuth: false,
  isAuthError: false,
  isAuthSuccess: false,
};

export const authReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.UPDATE_AUTH_STATUS:
      return {
        ...state,
        isAuth: payload,
      };
    case constant.IS_AUTH_ERROR:
      return {
        ...state,
        isAuthError: true,
        isAuthSuccess: false,
      };
    case constant.IS_AUTH_SUCCESS:
      return {
        ...state,
        isAuthSuccess: payload,
        isAuthError: false,
      };
    case constant.ON_CLOSE_AUTH_STATUS_MESSAGE:
      return {
        ...state,
        isAuthError: false,
        isAuthSuccess: false,
      };
    default:
      return state;
  }
};

export default authReducer;
