import React, { useState } from 'react';
import HeaderForModal from '../../../../components/Modals/HeaderForModal';
import { Box, Dialog, FormLabel, TextField } from '@material-ui/core';
import { sendPushByUsersReq } from '../../../../api/pushNotifications';
import FiltersSearchButton from '../FiltersSearchButton';
import { useCreateNotify } from '../../../../hooks/useCreateNotify';

const FiltersPushModal = ({ isShowModal, closeModal, users }) => {
  const { createNotify } = useCreateNotify();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidForm = title && body && users.length;

  const sendPushes = async () => {
    if (!isValidForm) return;

    setLoading(true);

    try {
      const userIds = users
        .filter((user) => user.checked)
        .map((user) => user.id || user.user_id);

      await sendPushByUsersReq({ users: userIds, title, body });

      createNotify({
        notifyStatus: 'success',
        notifyText: 'Уведомления успешно отправлены!',
        isShowNotify: true,
      });
      closeModal();
    } catch {
      setLoading(false);
      createNotify({
        notifyStatus: 'error',
        notifyText: 'Ошибка при отправке уведомлений',
        isShowNotify: true,
      });
    }
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box p={2}>
        <HeaderForModal
          setOpen={closeModal}
          headerLabel='Отправка уведомления'
        />
        <Box mb={2}>
          <FormLabel component='legend'>Заголовок уведомления</FormLabel>
          <TextField
            name='title'
            fullWidth
            variant='outlined'
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <FormLabel component='legend'>Текст уведомления</FormLabel>
          <TextField
            name='title'
            fullWidth
            variant='outlined'
            multiline
            rows={3}
            required
            onChange={(e) => setBody(e.target.value)}
          />
        </Box>
        <FiltersSearchButton
          disabled={loading || !isValidForm}
          submit={sendPushes}
          title='Отправить'
        />
      </Box>
    </Dialog>
  );
};

export default FiltersPushModal;
