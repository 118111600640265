import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../resourses/propStyles';

import Header from '../../components/Header';

import { onGetProductsCategoriesList } from '../../store/actions/categories';
import CategoriesList from '../../components/Lists/CategoriesList';
import AddCategoryModal from '../../components/Modals/AddCategoryModal';

const CategoriesPage = ({
  onGetProductsCategoriesList,
  loadingCategoriesList,
  categoriesList,
}) => {
  const styles = useStyles();
  const [addCategory, setAddCategory] = useState(false);

  useEffect(() => {
    onGetProductsCategoriesList();
  }, [onGetProductsCategoriesList]);

  return (
    <Box className={styles.container}>
      <Header label={'Категории'} />
      <Box px={2}>
        <Grid container direction='row' justify='flex-end'>
          <Box>
            <Button
              onClick={() => setAddCategory(true)}
              variant='contained'
              className={styles.addCategoryButton}
            >
              Добавить
            </Button>
          </Box>
        </Grid>
        <CategoriesList
          loading={loadingCategoriesList}
          categories={categoriesList}
        />
      </Box>
      <AddCategoryModal open={addCategory} setOpen={setAddCategory} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  addCategoryButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  categories: { loadingCategoriesList, categoriesList },
}) => {
  return {
    loadingCategoriesList,
    categoriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsCategoriesList: () => dispatch(onGetProductsCategoriesList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
