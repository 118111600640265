import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import Spinner from '../../../../../components/Spinner';

const useStyles = makeStyles(() => ({
  outletCellItem: {
    border: 'none',
  },
}));

const UsersSalesByPriceAndDateAverageTable = ({
  list,
  loading,
  setList,
  selectAll,
}) => {
  const styles = useStyles();

  if (loading) {
    return (
      <Box mt={3}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  if (!list.length && !loading) return null;

  const handleJoinUser = (userId, checked) => {
    const users = list.map((user) =>
      user.user_id === userId ? { ...user, checked } : user,
    );

    setList(users);
  };

  return (
    <Box mt={3}>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' onClick={selectAll}>
                <Button>Выбрать все</Button>
              </TableCell>
              <TableCell align='center'>ФИО</TableCell>
              <TableCell align='center'>Телефон</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length
              ? list.map((el) => (
                  <TableRow key={el.user_id}>
                    <TableCell align='center'>
                      <Checkbox
                        checked={el.checked}
                        onChange={(e) =>
                          handleJoinUser(el.user_id, e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell align='center'>{el.name}</TableCell>
                    <TableCell align='center'>{el.phone}</TableCell>
                    <TableCell>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell variant='footer' align='center'>
                              Магазин
                            </TableCell>
                            <TableCell variant='footer' align='center'>
                              Кол-во покупок
                            </TableCell>
                            <TableCell variant='footer' align='center'>
                              Средняя сумма покупок
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {el.outlets.map((outlet) => (
                            <TableRow key={outlet.outlet_name}>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {outlet.outlet_name}
                              </TableCell>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {outlet.count} шт.
                              </TableCell>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {Math.round(outlet.sum)} ₽
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersSalesByPriceAndDateAverageTable;
