import axios from './axios';

export const onGetCommentsListReq = async ({ type, limit, dir }) => {
  return await axios.get('/reviews/list', {
    params: {
      limit: 20,
      offset: +limit === 1 ? 0 : limit,
      type,
      dir,
      order: 'created_at',
    }
  });
};

export const onDeleteCommentReq = async (id) => {
  return await axios.get(`/reviews/moderate/${id}`);
};
