import { useState, useEffect } from 'react';
import { getAuthTextsReq, editAuthTextsReq } from '../../../api/settings';

export const useAuthTexts = () => {
  const [authTextsEdit, setAuthTextsEdit] = useState(false);
  const [startData, setStartData] = useState({});
  const [authTexts, setAuthTexts] = useState({});

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await getAuthTextsReq();

      setAuthTexts(data);
      setStartData(data);
    })();
  }, []);

  useEffect(() => {
    if (!authTextsEdit) {
      setAuthTexts(startData);
    }
  }, [authTextsEdit, startData]);

  const editAuthTexts = async () => {
    const popup = authTexts?.popup_text;
    const placeholder = authTexts?.placeholder_text;

    console.log('authTexts', authTexts);

    if (popup && placeholder) {
      try {
        await editAuthTextsReq(authTexts);

        setStartData(authTextsEdit);
      } catch {
        setAuthTexts(startData);
      } finally {
        setAuthTextsEdit(false);
      }
    }
  };

  return {
    authTexts,
    setAuthTexts,
    authTextsEdit,
    setAuthTextsEdit,
    editAuthTexts,
  };
};
