import React from 'react';
import { connect } from 'react-redux';

import Notify from '../../Notify';
import ActionCategoryModal from '../ActionCategoryModal';

import {
  onAddNewCategory,
  onCloseCategoryActionMessages,
} from '../../../store/actions/categories';

const AddCategoryModal = ({
  open,
  setOpen,
  onAddNewCategory,
  isCategoryActionSuccess,
  isCategoryActionError,
  onCloseCategoryActionMessages,
}) => {
  return (
    <>
      <ActionCategoryModal
        open={open}
        setOpen={setOpen}
        onCategoryAction={onAddNewCategory}
        headerLabel='Добавление категории'
        categoryName=''
        setFile={function () {}}
      />
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='success'
        text='Категория успешно создана!'
        isOpen={isCategoryActionSuccess}
      />
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='error'
        text='Ошибка при создании категории!'
        isOpen={isCategoryActionError}
      />
    </>
  );
};

const mapStateToProps = ({
  categories: { isCategoryActionSuccess, isCategoryActionError },
}) => {
  return { isCategoryActionSuccess, isCategoryActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddNewCategory: (name) => dispatch(onAddNewCategory(name)),
    onCloseCategoryActionMessages: () =>
      dispatch(onCloseCategoryActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryModal);
