import React from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import propStyle from '../../../resourses/propStyles';

const HeaderForModal = ({ setOpen, headerLabel }) => {
  const styles = useStyles();

  return (
    <Grid
      container
      direction='row'
      justify='space-between'
      alignItems='center'
      style={{ marginBottom: 30 }}
    >
      <Box>
        <Typography variant='h6' component='h6'>
          {headerLabel}
        </Typography>
      </Box>
      <Box>
        <CloseIcon
          onClick={() => setOpen(false)}
          className={styles.closeIcon}
        />
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
}));

export default HeaderForModal;
