import React, { useState } from 'react';
import HeaderForModal from '../../../../components/Modals/HeaderForModal';
import { Box, Dialog, Grid, TextField } from '@material-ui/core';
import FiltersSearchButton from '../FiltersSearchButton';
import { addBonusesByUsersReq } from '../../../../api/bonusesRules';
import { useCreateNotify } from '../../../../hooks/useCreateNotify';

const FiltersBonusesModal = ({ isShowModal, closeModal, users }) => {
  const { createNotify } = useCreateNotify();
  const [duration, setDuration] = useState('');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidForm = duration && value && users.length;

  const addBonuses = async () => {
    if (!isValidForm) return;

    setLoading(true);

    try {
      const userIds = users
        .filter((user) => user.checked)
        .map((user) => user.id || user.user_id);

      await addBonusesByUsersReq({ users: userIds, duration, value });

      createNotify({
        notifyStatus: 'success',
        notifyText: 'Бонусы успешно начислены!',
        isShowNotify: true,
      });
      closeModal();
    } catch {
      createNotify({
        notifyStatus: 'error',
        notifyText: 'Ошибка при начислении бонусов!',
        isShowNotify: true,
      });
      setLoading(false);
    }
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box p={2}>
        <HeaderForModal
          setOpen={closeModal}
          headerLabel='Отправка уведомления'
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label='Действие бонусов(дни)'
              fullWidth
              variant='outlined'
              required
              onChange={(e) => setDuration(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Кол-во бонусов'
              fullWidth
              variant='outlined'
              required
              onChange={(e) => setValue(e.target.value)}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <FiltersSearchButton
            disabled={loading || !isValidForm}
            submit={addBonuses}
            title='Начислить'
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default FiltersBonusesModal;
