// auth
export const ON_AUTH_USER = 'ON_AUTH_USER';
export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const IS_AUTH_ERROR = 'IS_AUTH_ERROR';
export const IS_AUTH_SUCCESS = 'IS_AUTH_SUCCESS';
export const ON_CLOSE_AUTH_STATUS_MESSAGE = 'ON_CLOSE_AUTH_STATUS_MESSAGE';
// products
export const ON_GET_PRODUCTS_LIST = 'ON_GET_PRODUCTS_LIST';
export const IS_LOADING_PRODUCTS_LIST = 'IS_LOADING_PRODUCTS_LIST';
export const ON_GET_PRODUCT_BY_ID = 'ON_GET_PRODUCT_BY_ID';
export const ON_GET_PRODUCTS_BY_CATEGORY_ID_AND_NAME =
  'ON_GET_PRODUCTS_BY_CATEGORY_ID_AND_NAME';
export const ON_ADD_PRODUCT = 'ON_ADD_PRODUCT';
export const ON_EDIT_PRODUCT = 'ON_EDIT_PRODUCT';
export const IS_LOADING_CUR_PRODUCT = 'IS_LOADING_CUR_PRODUCT';
export const ON_GET_CATEGORIES_FOR_PRODUCTS_PAGE =
  'ON_GET_CATEGORIES_FOR_PRODUCTS_PAGE';
export const ON_GET_CAT_AND_SUBCAT_FOR_SELECT =
  'ON_GET_CAT_AND_SUBCAT_FOR_SELECT';
export const IS_PRODUCT_ACTION_SUCCESS = 'IS_PRODUCT_ACTION_SUCCESS';
export const IS_PRODUCT_ACTION_ERROR = 'IS_PRODUCT_ACTION_ERROR';
export const ON_CLOSE_ACTION_PRODUCT_MESSAGE =
  'ON_CLOSE_ACTION_PRODUCT_MESSAGE';
// categories
export const ON_GET_CATEGORIES_LIST = 'ON_GET_CATEGORIES_LIST';
export const IS_LOADING_CATEGORIES_LIST = 'IS_LOADING_CATEGORIES_LIST';
export const ON_CLOSE_ACTION_CATEGORY_MESSAGE =
  'ON_CLOSE_ACTION_CATEGORIES_MESSAGE';
export const IS_CATEGORY_ACTION_ERROR = 'IS_CATEGORY_ACTION_ERROR';
export const IS_CATEGORY_ACTION_SUCCESS = 'IS_CATEGORY_ACTION_SUCCESS';
export const ON_GET_CATEGORIES_FOR_SELECT = 'ON_GET_CATEGORIES_FOR_SELECT';
// orders
export const ON_GET_ORDERS_LIST = 'ON_GET_ORDERS_LIST';
export const IS_LOADING_ORDERS_LIST = 'IS_LOADING_ORDERS_LIST';
export const ON_GET_ORDER_BY_ID = 'ON_GET_ORDER_BY_ID';
export const IS_LOADING_ORDER_BY_ID = 'IS_LOADING_ORDER_BY_ID';
// users
export const ON_GET_USERS_LIST = 'ON_GET_USERS_LIST';
export const ON_GET_USER_STATS_LIST = 'ON_GET_USER_STATS_LIST';
export const IS_LOADING_USERS_LIST = 'IS_LOADING_USERS_LIST';
export const IS_LOADING_USERS_STATS_LIST = 'IS_LOADING_USERS_STATS_LIST';
export const ON_GET_USER_BY_ID = 'ON_GET_USER_BY_ID';
export const IS_LOADING_USER_BY_ID = 'IS_LOADING_USER_BY_ID';
export const ON_CLOSE_ACTION_USER_MESSAGE = 'ON_CLOSE_ACTION_USER_MESSAGE';
export const IS_USER_ACTION_ERROR = 'IS_USER_ACTION_ERROR';
export const IS_USER_ACTION_SUCCESS = 'IS_USER_ACTION_SUCCESS';
export const ON_GET_USER_CARDS_LIST = 'ON_GET_USER_CARDS_LIST';
export const IS_CREATE_CARD_ERROR = 'IS_CREATE_CARD_ERROR';
export const IS_CREATE_CARD_SUCCESS = 'IS_CREATE_CARD_SUCCESS';
// news
export const ON_GET_NEWS_LIST = 'ON_GET_NEWS_LIST';
export const IS_LOADING_NEWS_LIST = 'IS_LOADING_NEWS_LIST';
export const ON_ADD_NEWS = 'ON_ADD_NEWS';
// promos
export const ON_GET_PROMOS_LIST = 'ON_GET_PROMOS_LIST';
export const IS_LOADING_PROMOS_LIST = 'IS_LOADING_PROMOS_LIST';
export const ON_ADD_PROMO = 'ON_ADD_PROMO';
export const IS_PROMO_ACTION_SUCCESS = 'IS_PROMO_ACTION_SUCCESS';
export const IS_PROMO_ACTION_ERROR = 'IS_PROMO_ACTION_ERROR';
export const ON_CLOSE_ACTION_PROMOS_MESSAGE = 'ON_CLOSE_ACTION_PROMOS_MESSAGE';
// loyalty
export const ON_GET_LOYALTY_LIST = 'ON_GET_LOYALTY_LIST';
export const IS_LOADING_LOYALTY_LIST = 'IS_LOADING_LOYALTY_LIST';
export const ON_GET_LOYALTY_TYPES = 'ON_GET_LOYALTY_TYPES';
export const ON_ADD_LOYALTY = 'ON_ADD_LOYALTY';
export const IS_LOYALTY_ACTION_SUCCESS = 'IS_LOYALTY_ACTION_SUCCESS';
export const IS_LOYALTY_ACTION_ERROR = 'IS_LOYALTY_ACTION_ERROR';
export const ON_CLOSE_ACTION_LOYALTY_MESSAGE =
  'ON_CLOSE_ACTION_LOYALTY_MESSAGE';
export const GET_USER_BLOCK_MESSAGE = 'GET_USER_BLOCK_MESSAGE';
// coupons
export const ON_GET_COUPONS_LIST = 'ON_GET_COUPONS_LIST';
export const IS_LOADING_COUPONS_LIST = 'IS_LOADING_COUPONS_LIST';
export const ON_GET_DATA_FOR_COUP_SELECT = 'ON_GET_DATA_FOR_COUP_SELECT';
export const ON_ADD_COUPON = 'ON_ADD_COUPON';
export const IS_COUPONS_ACTION_SUCCESS = 'IS_COUPONS_ACTION_SUCCESS';
export const IS_COUPONS_ACTION_ERROR = 'IS_COUPONS_ACTION_ERROR';
export const ON_CLOSE_ACTION_COUPONS_MESSAGE =
  'ON_CLOSE_ACTION_COUPONS_MESSAGE';
// shops
export const ON_GET_SHOPS_LIST = 'ON_GET_SHOPS_LIST';
export const IS_LOADING_SHOPS_LIST = 'IS_LOADING_SHOPS_LIST';
export const IS_SHOPS_ACTION_SUCCESS = 'IS_SHOPS_ACTION_SUCCESS';
export const IS_SHOPS_ACTION_ERROR = 'IS_SHOPS_ACTION_ERROR';
export const ON_CLOSE_ACTION_SHOPS_MESSAGE = 'ON_CLOSE_ACTION_SHOPS_MESSAGE';
// comments
export const ON_GET_COMMENTS_LIST = 'ON_GET_COMMENTS_LIST';
export const IS_LOADING_COMMENTS_LIST = 'IS_LOADING_COMMENTS_LIST';
// fields
export const ON_GET_FIELDS_LIST = 'ON_GET_FIELDS_LIST';
export const IS_LOADING_FIELDS_LIST = 'IS_LOADING_FIELDS_LIST';
export const IS_FIELDS_ACTION_SUCCESS = 'IS_FIELDS_ACTION_SUCCESS';
export const IS_FIELDS_ACTION_ERROR = 'IS_FIELDS_ACTION_ERROR';
export const ON_CLOSE_ACTION_FIELDS_MESSAGE = 'ON_CLOSE_ACTION_FIELDS_MESSAGE';
// stats
export const ON_GET_ORDERS_DAYS_STATS = 'ON_GET_ORDERS_DAYS_STATS';
export const IS_LOADING_ORDERS_DAYS_STATS_LIST =
  'IS_LOADING_ORDERS_DAYS_STATS_LIST';
// settings
export const ON_GET_PUSH_PURCHASE_PRODUCT_SETTINGS =
  'ON_GET_PUSH_PURCHASE_PRODUCT_SETTINGS';
// cards
export const ON_GET_CARDS_LIST = 'ON_GET_CARDS_LIST';
export const IS_LOADING_CARDS_LIST = 'IS_LOADING_CARDS_LIST';
// bonuses rules
export const UPDATE_BONUSES_RULES_LIST = 'UPDATE_BONUSES_RULES_LIST';
export const CREATE_BONUSES_RULES = 'CREATE_BONUSES_RULES';
export const DELETE_BONUSES_RULES = 'DELETE_BONUSES_RULES';
export const IS_LOADING_BONUSES_RULES_LIST = 'IS_LOADING_BONUSES_RULES_LIST';
// notify
export const SET_NOTIFY = 'SET_NOTIFY';