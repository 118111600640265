import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import FiltersSearchButton from '../FiltersSearchButton';
import UsersSalesByPriceAndDateAverageModal from '../FiltersModals/UsersSalesByPriceAndDateAverageModal';
import UsersSalesByPriceAndDateAverageTable from '../FiltersTables/UsersSalesByPriceAndDateAverageTable';

const UsersSalesByPriceAndDateAverageFilters = ({
  users,
  setUsers,
  selectAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  return (
    <Box mx={2}>
      <Grid container justify='flex-end'>
        <FiltersSearchButton title='Поиск' submit={() => setShowModal(true)} />
      </Grid>
      <UsersSalesByPriceAndDateAverageTable
        list={users}
        loading={loading}
        setList={setUsers}
        selectAll={selectAll}
      />
      <UsersSalesByPriceAndDateAverageModal
        isShowModal={isShowModal}
        setShowModal={setShowModal}
        setUsers={setUsers}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default UsersSalesByPriceAndDateAverageFilters;
