import axios from './axios';

export const onGetPromosListReq = async () => {
  return await axios.post('/stocks/list');
};

export const onCreatePromoReq = async (data) => {
  return await axios.post('/stocks/create', {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onEditPromoReq = async (data) => {
  return await axios.post(`/stocks/edit/${data.id}`, {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onDeletePromoReq = async (id) => {
  return await axios.get(`/stocks/delete/${id}`);
};
