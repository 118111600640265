import * as constant from '../constants';

const initialState = {
  ordersList: [],
  ordersListCount: 0,
  loadingOrdersList: true,
  curOrderById: null,
  loadingOrderById: true,
};

const ordersReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_ORDERS_LIST:
      return {
        ...state,
        ordersList: payload.list,
        ordersListCount: payload.count,
      };
    case constant.IS_LOADING_ORDERS_LIST:
      return {
        ...state,
        loadingOrdersList: payload,
      };
    case constant.ON_GET_ORDER_BY_ID:
      return {
        ...state,
        curOrderById: payload,
      };
    case constant.IS_LOADING_ORDER_BY_ID:
      return {
        ...state,
        loadingOrderById: payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
