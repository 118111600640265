import axios from './axios';

export const onGetUsersListReq = async (limit, term) => {
  return await axios.post('/users/list', {
    limit: 20,
    offset: +limit === 1 ? 0 : limit,
    filters: term,
  });
};

export const onGetUserByIdReq = async (id) => {
  return await axios.get(`/users/get/${id}`);
};

export const onEditUserReq = async (data) => {
  return await axios.post(`/users/edit/${data.id}`, data);
};

export const onCreateNewUserReq = async (data) => {
  return await axios.post('/users/create', data);
};

export const onDeleteUserReq = async (id) => {
  return await axios.get(`/users/delete/${id}`);
};

export const onBlockUserReq = async (data) => {
  return await axios.post(`/users/edit/${data.id}`, { active: data.active });
};

export const onGetUserStatsReq = async (id) => {
  return await axios.post('/orders/list', { status: 4, user_id: id });
};

export const onGetUserCardsListReq = async (id) => {
  return await axios.get(`/users/get/${id}`);
};
