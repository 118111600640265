import * as constant from '../constants';

const initialState = {
  shopsList: [],
  loadingShopsList: true,
  isShopsActionSuccess: false,
  isShopsActionError: false,
};

const shopsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_SHOPS_LIST:
      return {
        ...state,
        shopsList: payload,
      };
    case constant.IS_LOADING_SHOPS_LIST:
      return {
        ...state,
        loadingShopsList: payload,
      };
    case constant.IS_SHOPS_ACTION_SUCCESS:
      return {
        ...state,
        isShopsActionSuccess: true,
      };
    case constant.IS_SHOPS_ACTION_ERROR:
      return {
        ...state,
        isShopsActionError: true,
      };
    case constant.ON_CLOSE_ACTION_SHOPS_MESSAGE:
      return {
        ...state,
        isShopsActionSuccess: false,
        isShopsActionError: false,
      };
    default:
      return state;
  }
};

export default shopsReducer;
