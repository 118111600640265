import axios from './axios';

// пуш уведомление при покупке
export const onGetPushPurchaseProductSettingsReq = async () => 
  await axios.get('/translations/rate_store');

export const onEditPurchaseProductSettingsReq = async (data) => 
  await axios.patch('/translations/rate_store', data);

// попап при отправке номера телефона и текст плейсхолдера для ввода кода
export const getAuthTextsReq = async () => 
  await axios.get('/translations/auth_code_texts');

export const editAuthTextsReq = async (data) =>  
  await axios.patch('/translations/auth_code_texts', data);