import React, { useEffect, useState } from 'react';

import {
  Dialog,
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { getBonusesRulesHistoryReq } from '../../../api/bonusesRules';
import HeaderForModal from '../HeaderForModal';
import propStyle from '../../../resourses/propStyles';

const BonusesHistoryModal = ({ open, setOpen, id, name }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    (async () => {
      if (id) {
        const {
          data: { data },
        } = await getBonusesRulesHistoryReq(id);

        setHistory(data);
      }
    })();
    return setHistory([]);
  }, [id]);

  const closeModal = () => {
    setOpen(false);
  };

  const getTotalAmount = (action) => {
    const historyList = history.filter((el) => el.type === action);

    return historyList.reduce((acc, val) => +acc + +val.amount.slice(1), 0);
  };

  const TableData = ({ action }) => {
    const historyList = history.filter((el) => el.type === action);

    return (
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Дата</TableCell>
              <TableCell align='center'>Карта</TableCell>
              <TableCell align='center'>Счет</TableCell>
              <TableCell align='center'>
                {action === 'added' ? 'Начислено' : 'Cписано'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyList.map((el) => (
              <React.Fragment key={el.id}>
                <TableCell align='center'>{el.date}</TableCell>
                <TableCell align='center'>{el.number}</TableCell>
                <TableCell align='center'>{el.bill_id}</TableCell>
                <TableCell align='center'>{el.amount} p.</TableCell>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={closeModal}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box p='20px'>
        <HeaderForModal setOpen={closeModal} headerLabel={name} />
        <Grid container direction='row' justify='space-between'>
          <Grid style={{ paddingRight: 20 }} item xs={6}>
            <Box
              mb='10px'
              color={propStyle.successColor}
              fontSize={20}
              fontWeight='500'
            >
              Начислено | Всего: {getTotalAmount('added')} p.
            </Box>
            <TableData action='added' />
          </Grid>
          <Grid item xs={6}>
            <Box
              mb='10px'
              color={propStyle.redColor}
              fontSize={20}
              fontWeight='500'
            >
              Списано | Всего: {getTotalAmount('debited')} p.
            </Box>
            <TableData action='debited' />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default BonusesHistoryModal;
