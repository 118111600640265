import React, { useState, useEffect } from 'react';

import { Grid, Box, Button, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import NewCropImageModal from '../NewCropImageModal';

import { imgPath } from '../../../resourses/variables';

const ActionLoyaltyModal = ({
  headerLabel,
  setOpen,
  loyaltyData,
  onSubmit,
}) => {
  const styles = useStyles();
  const [img, setImg] = useState();
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [loyalty, setLoyalty] = useState({
    from: '',
    to: '',
    percent: '',
  });

  useEffect(() => {
    if (loyaltyData) {
      setLoyalty(loyaltyData);
    }
  }, [loyaltyData]);

  const onShowAddFileModal = () => {
    setOpenFileDialog(true);
  };

  const clearData = () => {
    setLoyalty({
      from: '',
      to: '',
      percent: '',
    });
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const onHandleChange = (e) => {
    setLoyalty({ ...loyalty, [e.target.name]: e.target.value });
  };

  return (
    <Grid container direction='column' className={styles.modal}>
      <HeaderForModal setOpen={onCloseModal} headerLabel={headerLabel} />
      <Box>
        <Grid container direction='row'>
          <Grid style={{ paddingRight: 20 }} item xs={6}>
            <Box className={styles.pb20}>
              <TextField
                onChange={onHandleChange}
                value={loyalty.from}
                name='from'
                className={styles.input}
                label='От'
                variant='outlined'
                type='number'
              />
            </Box>
            <Box className={styles.pb20}>
              <TextField
                onChange={onHandleChange}
                value={loyalty.to}
                name='to'
                className={styles.input}
                label='До'
                variant='outlined'
                type='number'
              />
            </Box>
            <Box item xs={6}>
              <TextField
                onChange={onHandleChange}
                name='percent'
                value={loyalty.percent}
                className={styles.input}
                label='Кэшбек'
                variant='outlined'
                type='number'
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title='Добавить картинку'>
              <Box
                position='relative'
                className={styles.imgWrapper}
                onClick={onShowAddFileModal}
              >
                <Box
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                  position='absolute'
                >
                  <PhotoCamera
                    style={{ color: propStyle.orangeColor }}
                    fontSize='large'
                  />
                </Box>
                {img ? (
                  <img src={img} className={styles.img} alt='loyalty' />
                ) : loyalty.file ? (
                  <img
                    src={imgPath + loyalty.file}
                    className={styles.img}
                    alt='news'
                  />
                ) : (
                  <Box className={styles.img} />
                )}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box width='100%' mt={4}>
        <Grid container direction='row' justify='center'>
          <Box mr={3}>
            <Button
              className={styles.submitButton}
              variant='contained'
              onClick={() => {
                onSubmit({ ...loyalty, file_content: img });
                clearData();
              }}
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={onCloseModal} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
      <NewCropImageModal
        setImg={setImg}
        open={openFileDialog}
        setOpen={setOpenFileDialog}
        w={300}
        h={140}
        fw={16}
        fh={9}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
  img: {
    width: '100%',
    height: 210,
    borderRadius: 8,
  },
  addFileButton: {
    color: propStyle.orangeColor,
  },
  imgWrapper: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  pb20: {
    paddingBottom: 20,
  },
}));

export default ActionLoyaltyModal;
