import React from 'react';

import Slider from 'react-slick';
import CloseIcon from '@material-ui/icons/Close';
import { imgPath } from '../../resourses/variables';
import { makeStyles } from '@material-ui/core/styles';

import './index.css';

const NextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      right: -19,
    }}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, left: -19 }}
    onClick={onClick}
  />
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const ProductImagesCarousel = ({ images, removeImg }) => {
  const styles = useStyles();

  return (
    <Slider {...settings}>
      {images.map((img) => (
        <div key={img.name}>
          <div
            className={styles.carouselImg}
            style={{
              backgroundImage: `url(${
                img?.id ? imgPath + img.name : img.name
              })`,
            }}
            alt='product'
          >
            {images.length > 1 && (
              <CloseIcon
                className={styles.carouselRemove}
                onClick={() => removeImg(img.name)}
              />
            )}
          </div>
        </div>
      ))}
    </Slider>
  );
};

const useStyles = makeStyles(() => ({
  carouselImg: {
    width: '100%',
    height: 246,
    borderRadius: 8,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  carouselRemove: {
    display: 'block',
    padding: 4,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default ProductImagesCarousel;
