import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';

import ActionFieldModal from '../ActionFieldModal';

import {
  onCloseActionFieldsMessage,
  onEditField,
} from '../../../store/actions/fields';

const EditFieldModal = ({
  open,
  setOpen,
  isFieldsActionSuccess,
  isFieldsActionError,
  onEditField,
  onCloseActionFieldsMessage,
  fieldData,
}) => {
  const onSubmit = (field) => {
    onEditField({ ...field, is_user_editable: field.is_user_editable ? 1 : 0 });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionFieldModal
        setOpen={setOpen}
        fieldData={fieldData}
        onSubmit={onSubmit}
        headerLabel='Редактирование поля'
      />
      <Notify
        closeFn={onCloseActionFieldsMessage}
        status='success'
        text='Поле успешно отредактировано!'
        isOpen={isFieldsActionSuccess}
      />
      <Notify
        closeFn={onCloseActionFieldsMessage}
        status='error'
        text='Ошибка при редактировании поля!'
        isOpen={isFieldsActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  fields: { isFieldsActionSuccess, isFieldsActionError },
}) => {
  return { isFieldsActionSuccess, isFieldsActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseActionFieldsMessage: () => dispatch(onCloseActionFieldsMessage()),
    onEditField: (data) => dispatch(onEditField(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFieldModal);
