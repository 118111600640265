import React from 'react';
import { connect } from 'react-redux';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import propStyles from '../../resourses/propStyles';

const MigrationsOutlets = ({ shopsList, outlets, setOutlets }) => {
  const styles = useStyles();

  const handleChange = (e) => {
    setOutlets(e.target.value);
  };

  const selectRenderValue = (selected) =>
    `Выбранные магазины (шт.): ${selected.length}`;

  return (
    <FormControl className={styles.formControl} variant='outlined'>
      <InputLabel>Выберите магазины</InputLabel>
      <Select
        multiple
        value={outlets}
        onChange={handleChange}
        renderValue={selectRenderValue}
      >
        {shopsList.map((outletItem) => (
          <MenuItem key={outletItem.id} value={outletItem}>
            <Checkbox
              className={styles.checkbox}
              checked={outlets.findIndex((el) => el.id === outletItem.id) > -1}
            />
            <ListItemText primary={outletItem.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
  },
  outletItem: {
    padding: 20,
  },
  checkbox: {
    color: `${propStyles.orangeColor} !important`,
  },
}));

const mapState = ({ shops: { shopsList } }) => ({
  shopsList,
});

export default connect(mapState)(MigrationsOutlets);
