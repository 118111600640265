import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';

import ActionPromoModal from '../ActionPromoModal';

import {
  onClosePromosActionMessages,
  onEditPromo,
} from '../../../store/actions/promos';

const EditPromoModal = ({
  open,
  setOpen,
  onEditPromo,
  onClosePromosActionMessages,
  isPromosActionSuccess,
  isPromosActionError,
  promoId,
  promoData,
  headerLabel,
}) => {
  const onSubmit = (promo, img) => {
    const data = { ...promo };

    data.file_content = img;

    onEditPromo({ ...data, id: promoId });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionPromoModal
        setOpen={setOpen}
        promoData={promoData}
        onSubmit={onSubmit}
        headerLabel={headerLabel}
      />

      <Notify
        closeFn={onClosePromosActionMessages}
        status='success'
        text='Акция успешно отредактирована!'
        isOpen={isPromosActionSuccess}
      />
      <Notify
        closeFn={onClosePromosActionMessages}
        status='error'
        text='Ошибка при редактировании акции!'
        isOpen={isPromosActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  news: { isPromosActionSuccess, isPromosActionError },
}) => {
  return { isPromosActionSuccess, isPromosActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditPromo: (data) => dispatch(onEditPromo(data)),
    onClosePromosActionMessages: () => dispatch(onClosePromosActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPromoModal);
