import React from 'react';

import { Box } from '@material-ui/core';

import Header from '../../components/Header';
import SettingsTable from '../../components/Tables/SettingsTable';

const SettingsPage = () => {
  return (
    <Box>
      <Header label={'Настройки'} />
      <SettingsTable />
    </Box>
  );
};

export default SettingsPage;
