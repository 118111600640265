import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import propStyles from '../../../../resourses/propStyles';

const FiltersSearchButton = ({ title, submit, disabled }) => {
  const styles = useStyles();

  return (
    <Button
      className={styles.searchButton}
      disabled={disabled}
      onClick={submit}
    >
      {title}
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  searchButton: {
    display: 'block',
    width: 160,
    backgroundColor: propStyles.orangeColor,
  },
}));
export default FiltersSearchButton;
