import React from 'react';

import { CircularProgress } from '@material-ui/core';

import propStyle from '../../resourses/propStyles';

const Spinner = () => {
  return (
    <CircularProgress
      size={54}
      disableShrink
      style={{ marginTop: '5%', color: propStyle.orangeColor }}
    />
  );
};

export default Spinner;
