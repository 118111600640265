import * as constant from '../constants';
import {
  getBonusesRulesListReq,
  createBonusesRulesReq,
  deleteBonusesRulesReq,
  editBonusesRulesReq,
} from '../../api/bonusesRules';
import { dispatchLoadingStatus } from './helpersFuncions';
import { setNotify } from './notify';

export const getBonusesRulesList = () => {
  const loading = constant.IS_LOADING_BONUSES_RULES_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, loading));
    try {
      const {
        data: { data },
      } = await getBonusesRulesListReq();

      dispatch({
        type: constant.UPDATE_BONUSES_RULES_LIST,
        payload: data.list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, loading));
    }
  };
};

export const createBonusesRulesList = (rule) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await createBonusesRulesReq(rule);
      const list = getState().bonusesRules.bonusesRulesList;
      const updatedList = [...list, data];
      dispatch({
        type: constant.UPDATE_BONUSES_RULES_LIST,
        payload: updatedList,
      });

      dispatch(
        setNotify({
          notifyStatus: 'success',
          notifyText: 'Бонусное правило успешно создано!',
          isShowNotify: true,
        }),
      );
    } catch (e) {
      dispatch(
        setNotify({
          notifyStatus: 'error',
          notifyText: 'Ошибка при создании бонусного правила!',
          isShowNotify: true,
        }),
      );
    }
  };
};

export const editBonusesRules = (rules) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await editBonusesRulesReq(rules);
      const list = getState().bonusesRules.bonusesRulesList;

      const idx = list.findIndex((el) => el.id === rules.id);
      list.splice(idx, 1, data);

      dispatch({
        type: constant.UPDATE_BONUSES_RULES_LIST,
        payload: list,
      });

      dispatch(
        setNotify({
          notifyStatus: 'success',
          notifyText: 'Бонусное правило успешно отредактировано!',
          isShowNotify: true,
        }),
      );
    } catch (e) {
      dispatch(
        setNotify({
          notifyStatus: 'error',
          notifyText: 'Ошибка при редактировании бонусного правила!',
          isShowNotify: true,
        }),
      );
    }
  };
};

export const deleteBonusesRules = (id) => {
  return async (dispatch, getState) => {
    try {
      const list = getState().bonusesRules.bonusesRulesList;
      const updatedList = list.filter((el) => el.id !== id);

      await deleteBonusesRulesReq(id);
      dispatch({
        type: constant.UPDATE_BONUSES_RULES_LIST,
        payload: updatedList,
      });

      dispatch(
        setNotify({
          notifyStatus: 'success',
          notifyText: 'Бонусное правило успешно удалено!',
          isShowNotify: true,
        }),
      );
    } catch (e) {
      dispatch(
        setNotify({
          notifyStatus: 'error',
          notifyText: 'Ошибка при удалении бонусного правила!',
          isShowNotify: true,
        }),
      );
    }
  };
};
