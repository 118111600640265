import axios from './axios';

export const onGetFieldsReq = async () => {
  return await axios.post('/fields/list');
};

export const onAddFieldReq = async (data) => {
  return await axios.post('/fields/create', data);
};

export const onEditFieldReq = async (data) => {
  return await axios.post(`/fields/edit/${data.id}`, data);
};

export const onDeleteFieldReq = async (id) => {
  return await axios.get(`/fields/delete/${id}`);
};
