import React, { useState } from 'react';
import { Button, Box, makeStyles } from '@material-ui/core';
import propStyles from '../../../../resourses/propStyles';
import FiltersPushModal from './FiltersPushModal';
import FiltersBonusesModal from './FiltersBonusesModal';

const HeaderPushesAndBonuses = ({ users }) => {
  const styles = useStyles();
  const [isShowPushModal, setShowPushModal] = useState(false);
  const [isShowBonusesModal, setShowBonusesModal] = useState(false);

  const isActionsDisabled = !users?.length;

  return (
    <>
      <Box className={styles.container}>
        <Button
          className={styles.searchButton}
          disabled={isActionsDisabled}
          onClick={() => setShowBonusesModal(true)}
        >
          Начислить бонусы
        </Button>
        <Button
          className={styles.searchButton}
          disabled={isActionsDisabled}
          onClick={() => setShowPushModal(true)}
        >
          Пуш уведомления
        </Button>
      </Box>
      <FiltersBonusesModal
        isShowModal={isShowBonusesModal}
        closeModal={() => setShowBonusesModal(false)}
        users={users}
      />
      <FiltersPushModal
        isShowModal={isShowPushModal}
        closeModal={() => setShowPushModal(false)}
        users={users}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  searchButton: {
    display: 'block',
    backgroundColor: propStyles.orangeColor,
  },
  modalContainer: {
    padding: 14,
  },
}));

export default HeaderPushesAndBonuses;
