import axios from './axios';

export const onAddCardReq = async (data) => {
  return await axios.post('/cards/create', data);
};

export const onAttachUserCardReq = async (data) => {
  return await axios.patch(`/cards/attach_user/${data.number}`, {
    user_id: data.user_id,
  });
};

export const onDeleteCardReq = async (number) => {
  return await axios.patch(`/cards/attach_user/${number}`);
};

export const onEditBalanceValueReq = async (id, value) => {
  return await axios.patch(`/bills/edit_value/${id}`, { value });
};

export const onGetCardsListReq = async (limit, term) => {
  return await axios.post('/cards/list', {
    unattached: 1,
    limit: 20,
    offset: +limit === 1 ? 0 : limit,
    filters: term,
  });
};
