import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { Box, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import propStyle from '../../resourses/propStyles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import Header from '../../components/Header';

import { imgPath } from '../../resourses/variables';

import {
  updateUsersCardsList,
  onGetUserCardsList,
  onDeleteUserCard,
} from '../../store/actions/users';
import UserStatsTable from '../../components/Tables/UserStatsTable';

import { cardIcon } from '../../resourses/variables';
import UserCardsModal from '../../components/Modals/UserCardsModal';
import EditCardBalanceModal from '../../components/Modals/EditCardBalanceModal';

const UserStatsPage = ({
  onGetUserCardsList,
  userCardsList,
  onDeleteUserCard,
}) => {
  const styles = useStyles();
  const { id } = useParams();

  const [cards, setCards] = useState([]);
  const [addCardModal, setAddCardModal] = useState(false);
  const [editBalanceModal, setEditBalanceModal] = useState(false);
  const [curCard, setCurCard] = useState('');
  const [curdCardId, setCurCardId] = useState('');
  const [filterCard, setFilterCard] = useState('');
  const [isAttachCard, setIsAttachCard] = useState('');

  useEffect(() => {
    onGetUserCardsList(id);
  }, [id, onGetUserCardsList]);

  const onChoiseCard = (number) => {
    setFilterCard(number);
  };

  const onOpenAttachCardModal = () => {
    setAddCardModal(true);
    setIsAttachCard(true);
  };

  const onOpenAddCardModal = () => {
    setIsAttachCard(false);
    setAddCardModal(true);
  };

  const onShowEditBalanceModal = (el) => {
    setCurCard(el.bills[0].real_value);
    setCurCardId(el.bills[0].id);
    setEditBalanceModal(true);
  };

  return (
    <Box>
      <Header label={'История покупок пользователя'} />
      <Box>
        <Box mb={3}>
          <Box p={2}>
            <Grid container direction='row' alignItems='center'>
              {userCardsList?.length
                ? userCardsList.map((el) => (
                    <Grid
                      onClick={() => onChoiseCard(el.number)}
                      key={el.id}
                      item
                      xs={4}
                      className={styles.cardItem}
                    >
                      <Box
                        className={styles.card}
                        style={{
                          backgroundImage: `url(${
                            el.bills[0].file
                              ? imgPath + el.bills[0].file
                              : cardIcon
                          })`,
                        }}
                      >
                        <Grid
                          container
                          direction='row'
                          justify='space-between'
                          style={{ height: 184, width: '100%' }}
                        >
                          <Box width='100%' className={styles.cartTopWrap}>
                            <Box color={'#fff'}>Карта №: {el.number}</Box>
                            <Box className={styles.actionBtnsWrap}>
                              <Box mr={2}>
                                <Tooltip title='Редактировать'>
                                  <EditIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onShowEditBalanceModal(el);
                                    }}
                                    className={styles.editIcon}
                                  />
                                </Tooltip>
                              </Box>
                              <Box>
                                <Tooltip title='Удалить'>
                                  <CloseIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteUserCard(el.number);
                                    }}
                                    className={styles.closeIcon}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Box width='100%' className={styles.cardBottomWrap}>
                            <Box color={'#fff'} mt={1}>
                              Бонус: {el?.bills[0]?.percent || ''}%
                            </Box>
                            <Box color={'#fff'} mt={1}>
                              Баланс:{' '}
                              {(+el?.bills[0]?.real_value).toFixed(2) || ''} ₽
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  ))
                : null}
              <Grid item xs={4} className={styles.cardItem}>
                <Box
                  height='100%'
                  width='100%'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box mr={4}>
                    <Tooltip
                      onClick={onOpenAddCardModal}
                      title='Добавить карту'
                    >
                      <AddCircleOutlineIcon
                        fontSize='large'
                        className={styles.addCardIcon}
                      />
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip
                      onClick={onOpenAttachCardModal}
                      title='Привязать карту'
                    >
                      <AttachFileIcon
                        fontSize='large'
                        className={styles.AttachCardIcon}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <UserStatsTable filterCard={filterCard} id={id} />
      </Box>
      <UserCardsModal
        open={addCardModal}
        setOpen={setAddCardModal}
        userId={id}
        cardsList={cards}
        setCardsList={setCards}
        isAttachCard={isAttachCard}
      />
      <EditCardBalanceModal
        open={editBalanceModal}
        setOpen={setEditBalanceModal}
        balanceValue={curCard}
        id={curdCardId}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  cardItem: {
    padding: 8,
    height: 200,
    marginBottom: 16,
  },
  card: {
    padding: 6,
    border: `1px solid ${propStyle.shadowedColor}`,
    borderRadius: 8,
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  cartTopWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cardBottomWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actionBtnsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  addCardIcon: {
    cursor: 'pointer',
    color: propStyle.successColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  AttachCardIcon: {
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.redColor,
    },
  },
  editIcon: {
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
}));

const mapStateToProps = ({ users: { userCardsList } }) => {
  return { userCardsList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUsersCardsList: (data, id) =>
      dispatch(updateUsersCardsList(data, id)),
    onGetUserCardsList: (id) => dispatch(onGetUserCardsList(id)),
    onDeleteUserCard: (number) => dispatch(onDeleteUserCard(number)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStatsPage);
