import React, { useState } from 'react';

import { Dialog, Box, TextField, Grid } from '@material-ui/core';
import HeaderForModal from '../../../../../components/Modals/HeaderForModal';

import { useCreateNotify } from '../../../../../hooks/useCreateNotify';
import { getUsersSalesByPriceReq } from '../../../../../api/migrations';
import MigrationsOutlets from '../../../../../components/MigrationsOutlets';
import FiltersSearchButton from '../../FiltersSearchButton';

const UsersSalesByPriceModal = ({
  isShowModal,
  closeModal,
  setUsers,
  loading,
  setLoading,
}) => {
  const [outlets, setOutlets] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const isValidForm = min >= 0 && max && max >= min && !!outlets.length;

  const { createNotify } = useCreateNotify();

  const paramsData = {
    outlet_ids: outlets.map((el) => el.id),
    min,
    max,
  };

  const getUsersByFilters = async () => {
    if (!isValidForm) return createErrorNotify();

    setLoading(true);

    try {
      const {
        data: { data },
      } = await getUsersSalesByPriceReq(paramsData);

      setUsers(data.map((user) => ({ ...user, checked: false })));
    } finally {
      setLoading(false);
    }
  };

  const createErrorNotify = () => {
    createNotify({
      notifyStatus: 'error',
      notifyText: 'Заполните все поля, чтобы продолжить!',
      isShowNotify: true,
    });
  };

  const handleChangeMin = (e) => {
    const { value } = e.target;

    if (+value < 0) return;

    setMin(value);
  };

  const handleChangeMax = (e) => {
    const { value } = e.target;

    if (value < 0) return;

    setMax(e.target.value);
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box p={2}>
        <HeaderForModal setOpen={closeModal} headerLabel='Заполните все поля' />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              value={min}
              onChange={handleChangeMin}
              label='Цена от (₽)'
              variant='outlined'
              type='number'
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={max}
              onChange={handleChangeMax}
              label='Цена до (₽)'
              variant='outlined'
              type='number'
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <MigrationsOutlets outlets={outlets} setOutlets={setOutlets} />
        </Box>

        <Grid container justify='flex-end'>
          <FiltersSearchButton
            title='Поиск'
            disabled={loading}
            submit={getUsersByFilters}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default UsersSalesByPriceModal;
