import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import NewCropImageModal from '../NewCropImageModal';

import { imgPath } from '../../../resourses/variables';
import { equalArrays } from '../../../resourses/helpers';

const questionTypes = [
  {
    label: 'Число',
    value: 1,
  },
  {
    label: 'Текст',
    value: 2,
  },
  {
    label: 'Да/Нет',
    value: 3,
  },
  {
    label: 'Варианты ответа',
    value: 4,
  },
];

const ActionNewsModal = ({
  newsData,
  setOpen,
  onSubmit,
  headerLabel,
  isAddModal,
}) => {
  const styles = useStyles();

  const [news, setNews] = useState({
    name: '',
    description: '',
    push: false,
    sms: false,
    is_hidden: false,
    questions: [],
    question_bonus_duration: 0,
    question_bonus_value: 0,
  });
  const [img, setImg] = useState();
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [questionsCopy, setQuestionsCopy] = useState([]);

  const onShowAddFileModal = () => {
    setOpenFileDialog(true);
  };

  useEffect(() => {
    if (newsData) {
      const existQuestions = () => {
        setShowQuestions(true);

        return newsData.questions.map((el) => {
          const options = el?.answers_options ? el.answers_options : [];
          delete el.answers_options;
          return {
            ...el,
            options,
          };
        });
      };

      const questions = !newsData.questions ? [] : existQuestions();

      setNews({
        ...newsData,
        questions,
      });
      setQuestionsCopy(JSON.parse(JSON.stringify(questions)));
    }
  }, [newsData]);

  const onHandleChange = (e) => {
    setNews({
      ...news,
      [e.target.name]:
        e.target.name === 'sms' || e.target.name === 'push'
          ? e.target.checked
          : e.target.value,
    });
  };

  const clearData = () => {
    setNews({
      ...news,
      name: '',
      description: '',
      file: '',
      push: false,
      sms: false,
      is_hidden: false,
    });
    setImg();
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const createNewQuestion = () => {
    setNews({
      ...news,
      questions: [
        ...news.questions,
        {
          type: 2,
          text: '',
          options: [],
        },
      ],
    });
  };

  const removeQuestion = (idx) => {
    setNews({
      ...news,
      questions: news.questions.filter((_, i) => i !== idx),
    });
  };

  const createQuestionOption = (idx) => {
    const questions = news.questions;
    questions[idx].options = [...questions[idx]?.options, { text: '' }];

    setNews({
      ...news,
      questions,
    });
  };

  const removeQuestionOption = (newsIdx, optionIdx) => {
    const questions = news.questions;
    questions[newsIdx].options = questions[newsIdx].options.filter(
      (_, i) => i !== optionIdx,
    );

    setNews({
      ...news,
      questions,
    });
  };

  return (
    <Box className={styles.modal}>
      <HeaderForModal setOpen={onCloseModal} headerLabel={headerLabel} />
      <Box>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid item xs={5}>
            <TextField
              onChange={onHandleChange}
              value={news.name}
              name='name'
              className={styles.input}
              label='Название'
              variant='outlined'
            />
          </Grid>
          {isAddModal && (
            <>
              <Grid className={styles.formCheckbox} item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onHandleChange}
                      value={news.sms}
                      name='sms'
                      style={{ color: propStyle.orangeColor }}
                      color='primary'
                    />
                  }
                  label='Смс'
                  labelPlacement='top'
                />
              </Grid>
              <Grid className={styles.formCheckbox} item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onHandleChange}
                      value={news.push}
                      name='push'
                      color='primary'
                      style={{ color: propStyle.orangeColor }}
                    />
                  }
                  label='Пуш-уведомление'
                  labelPlacement='top'
                />
              </Grid>
            </>
          )}

          {!isAddModal && (
            <Grid className={styles.formCheckbox} item xs={2}>
              <FormControlLabel
                labelPlacement='top'
                control={
                  <Checkbox
                    onChange={(e) =>
                      setNews({ ...news, is_hidden: e.target.checked })
                    }
                    checked={!!news.is_hidden}
                    name='is_hidden'
                    style={{ color: propStyle.orangeColor }}
                  />
                }
                label='Скрыть'
              />
            </Grid>
          )}
        </Grid>
        <Grid className={styles.editFieldContainer} container direction='row'>
          <Grid item xs={8}>
            <TextField
              onChange={onHandleChange}
              value={news.description}
              name='description'
              className={styles.input}
              label='Описание новости'
              variant='outlined'
              multiline
              rows={11}
            />
          </Grid>
          <Grid style={{ paddingLeft: 20 }} item xs={4}>
            <Tooltip title='Добавить картинку'>
              <Box
                position='relative'
                className={styles.imgWrapper}
                onClick={onShowAddFileModal}
              >
                <Box
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                  position='absolute'
                >
                  <PhotoCamera
                    style={{ color: propStyle.orangeColor }}
                    fontSize='large'
                  />
                </Box>
                {img ? (
                  <img src={img} className={styles.img} alt='product' />
                ) : news.file ? (
                  <img
                    src={imgPath + news.file}
                    className={styles.img}
                    alt='news'
                  />
                ) : (
                  <Box className={styles.img} />
                )}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        {/*  */}
        <Box mt={3}>
          <Box>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Checkbox
                  onChange={(e) => setShowQuestions(e.target.checked)}
                  checked={showQuestions}
                  name='enabled'
                  color='primary'
                  style={{
                    color: propStyle.orangeColor,
                  }}
                />
              }
              label='Опрос'
              labelPlacement='start'
            />
            {/* Опрос */}
          </Box>
          {showQuestions && (
            <>
              <Grid
                className={styles.editFieldContainer}
                container
                direction='row'
                alignItems='center'
              >
                <Grid
                  style={{ display: 'flex' }}
                  className={styles.pr20}
                  item
                  xs={6}
                >
                  <Grid
                    onClick={createNewQuestion}
                    className={styles.addAnswerBtn}
                    container
                    direction='row'
                    alignItems='center'
                  >
                    <Box mr={2}>Добавить вопрос</Box>
                    <Box>
                      <AddCircleOutlineIcon />
                    </Box>
                  </Grid>
                </Grid>
                <Grid className={styles.pr20} item xs={3}>
                  <TextField
                    name='question_bonus_value'
                    value={news.question_bonus_value}
                    onChange={onHandleChange}
                    className={styles.input}
                    autoComplete='off'
                    variant='outlined'
                    type='number'
                    label='Бонус за опрос'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name='question_bonus_duration'
                    value={news.question_bonus_duration}
                    onChange={onHandleChange}
                    className={styles.input}
                    autoComplete='off'
                    variant='outlined'
                    type='number'
                    label='Длительность опроса(дни)'
                  />
                </Grid>
              </Grid>
              {news?.questions?.length > 0 &&
                news.questions?.map((el, i) => (
                  <Box key={i}>
                    <Box fontSize={16} fontWeight='600' mb={2}>
                      Вопрос №{i + 1}
                    </Box>
                    <Grid
                      className={styles.editFieldContainer}
                      container
                      direction='row'
                    >
                      <Grid className={styles.pr20} item xs={4}>
                        <InputLabel>Тип ответа</InputLabel>
                        <Select
                          value={el.type}
                          onChange={(e) => {
                            const updatedQuestions = news.questions;
                            updatedQuestions[i].type = e.target.value;

                            setNews({
                              ...news,
                              questions: updatedQuestions,
                            });
                          }}
                          className={styles.select}
                          variant='outlined'
                        >
                          {questionTypes.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid className={styles.pr20} item xs={7}>
                        <InputLabel>Вопрос</InputLabel>
                        <TextField
                          autoComplete='off'
                          onChange={(e) => {
                            const updatedQuestions = news.questions;
                            updatedQuestions[i].text = e.target.value;

                            setNews({
                              ...news,
                              questions: updatedQuestions,
                            });
                          }}
                          value={el.text}
                          className={styles.input}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid className={styles.closeWrap} item xs={1}>
                        <CloseIcon
                          onClick={() => removeQuestion(i)}
                          className={styles.closeBtn}
                        />
                      </Grid>
                    </Grid>
                    {el.type === 4 && (
                      <>
                        <Grid container direction='row'>
                          <Box pt='2px' mr={2} fontSize={18}>
                            Варианты ответа
                          </Box>
                          <Box>
                            <AddCircleOutlineIcon
                              onClick={() => createQuestionOption(i)}
                              className={styles.addAnswerBtn}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          className={styles.editFieldContainer}
                          container
                          direction='row'
                        >
                          {el.options?.length > 0 &&
                            el.options.map((option, optionIdx) => (
                              <Grid key={optionIdx} item xs={4}>
                                <Grid
                                  container
                                  direction='row'
                                  alignItems='center'
                                >
                                  <Box mt={1}>
                                    <TextField
                                      autoComplete='off'
                                      onChange={(e) => {
                                        const questions = news.questions;
                                        questions[i].options[optionIdx] = {
                                          text: e.target.value,
                                        };

                                        setNews({
                                          ...news,
                                          questions,
                                        });
                                      }}
                                      value={option.text}
                                      className={styles.input}
                                      variant='outlined'
                                    />
                                  </Box>
                                  <Box ml={2}>
                                    <CloseIcon
                                      onClick={() =>
                                        removeQuestionOption(i, optionIdx)
                                      }
                                      className={styles.closeBtn}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </>
                    )}
                  </Box>
                ))}
            </>
          )}
        </Box>
        {/*  */}
      </Box>

      <Box mt={3}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Box mr={3}>
            <Button
              onClick={() => {
                const updatedNews = { ...news };

                const isEqualQuestions = equalArrays(
                  questionsCopy,
                  updatedNews.questions,
                );

                if (isEqualQuestions) delete updatedNews.questions;

                if (!showQuestions) {
                  delete updatedNews.question_bonus_duration;
                  delete updatedNews.question_bonus_value;
                  delete updatedNews.questions;
                }

                onSubmit(updatedNews, img, showQuestions);
              }}
              className={styles.addButton}
              variant='contained'
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={onCloseModal} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
      <NewCropImageModal
        setImg={setImg}
        open={openFileDialog}
        setOpen={setOpenFileDialog}
        w={'100%'}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  rubleIconWrap: {},
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  addFileButton: {
    color: propStyle.orangeColor,
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  img: {
    width: '100%',
    height: 246,
    borderRadius: 8,
  },
  formControl: {
    width: '100%',
  },
  imgWrapper: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  formCheckbox: { display: 'flex', justifyContent: 'center' },
  select: {
    width: '100%',
  },
  pr20: {
    paddingRight: 20,
  },
  addAnswerBtn: {
    color: propStyle.successColor,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  closeWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeBtn: {
    color: propStyle.redColor,
    cursor: 'pointer',
    fontSize: 28,
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default ActionNewsModal;
