import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const LeftSidebarLink = ({ link, desc, icon }) => {
  const styles = useStyles();
  const location = useLocation();
  const activeLink = location.pathname;

  const checkActiveLink = (path) => {
    return activeLink === path ? styles.activeListItem : styles.listItem;
  };

  return (
    <Link className={styles.link} to={link}>
      <ListItem className={checkActiveLink(link)} button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={desc} />
      </ListItem>
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  activeListItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
}));

export default LeftSidebarLink;
