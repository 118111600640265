import React from 'react';

import { Input, InputAdornment, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({ setTerm, term }) => {
  return (
    <Box w='200px' className={styles.container}>
      <Input
        onChange={(e) => setTerm(e.target.value)}
        value={term}
        className={styles.input}
        id='input-with-icon-adornment'
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Box>
  );
};

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: 200,
  },
}));

export default SearchInput;
