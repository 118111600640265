import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import FiltersSearchButton from '../FiltersSearchButton';
import UsersSalesByPriceAndDateTotalModal from '../FiltersModals/UsersSalesByPriceAndDateTotalModal';
import UsersSalesByPriceAndDateTotalTable from '../FiltersTables/UsersSalesByPriceAndDateTotalTable';

const UsersSalesByPriceAndDateTotalFilters = ({
  users,
  setUsers,
  selectAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  return (
    <Box mx={2}>
      <Grid container justify='flex-end'>
        <FiltersSearchButton title='Поиск' submit={() => setShowModal(true)} />
      </Grid>
      <UsersSalesByPriceAndDateTotalTable
        loading={loading}
        list={users}
        setList={setUsers}
        selectAll={selectAll}
      />
      <UsersSalesByPriceAndDateTotalModal
        isShowModal={isShowModal}
        closeModal={() => setShowModal(false)}
        setUsers={setUsers}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default UsersSalesByPriceAndDateTotalFilters;
