import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';
import EditFieldModal from '../../Modals/EditFieldModal/EditFieldModal';

import { onDeleteField, onGetFields } from '../../../store/actions/fields';

const MoreFieldsTable = ({
  fieldsList,
  loadingFieldsList,
  onDeleteField,
  onGetFields,
}) => {
  const styles = useStyles();
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [fieldId, setFieldId] = useState();
  const [field, setField] = useState({});

  useEffect(() => {
    onGetFields();
  }, [onGetFields]);

  if (loadingFieldsList) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onOpenDeleteModal = (id) => {
    setFieldId(id);
    setConfirmModal(true);
  };

  const onOpenEditModal = (el) => {
    setField(el);
    setEditModal(true);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Название</TableCell>
              <TableCell align='center'>Статус</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldsList.length
              ? fieldsList.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>{el.name}</TableCell>
                    <TableCell align='center'>
                      {!!el.is_user_editable
                        ? 'Редактируемое'
                        : 'Нередактируемое'}
                    </TableCell>
                    <TableCell align='center'>
                      <Grid container direction='row' justify='space-around'>
                        <Tooltip title='Редактировать'>
                          <EditIcon
                            onClick={() => onOpenEditModal(el)}
                            className={styles.editIcon}
                          />
                        </Tooltip>
                        <Tooltip title='Удалить'>
                          <CloseIcon
                            onClick={() => onOpenDeleteModal(el.id)}
                            className={styles.closeIcon}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {!fieldsList.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список дополнительных полей пуст
        </Box>
      )}
      <EditFieldModal
        open={editModal}
        setOpen={setEditModal}
        fieldData={field}
      />

      <ConfirmDeleteModal
        id={fieldId}
        open={confirmModal}
        setOpen={setConfirmModal}
        actionFn={onDeleteField}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapStateToProps = ({ fields: { fieldsList, loadingFieldsList } }) => {
  return {
    fieldsList,
    loadingFieldsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFields: () => dispatch(onGetFields()),
    onDeleteField: (id) => dispatch(onDeleteField(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreFieldsTable);
