import * as constant from '../constants';

const initialState = {
  cardsList: [],
  loadingCardsList: false,
  cardsListCount: 0,
};

const cardsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_CARDS_LIST:
      return {
        ...state,
        cardsList: payload.list,
        cardsListCount: payload.count,
      };
    case constant.IS_LOADING_CARDS_LIST:
      return {
        ...state,
        loadingCardsList: payload,
      };
    default:
      return state;
  }
};

export default cardsReducer;
