import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import ActionUserModal from '../ActionUserModal';
import Notify from '../../Notify';

import {
  onCreateNewUser,
  onCloseUserActionMessages,
} from '../../../store/actions/users';
import { onGetFields } from '../../../store/actions/fields';

const AddUserModal = ({
  open,
  setOpen,
  onCreateNewUser,
  headerLabel,
  isUserActionSuccess,
  isUserActionError,
  onCloseUserActionMessages,
  fieldsList,
  onGetFields,
}) => {
  const [user, setUser] = useState({
    first_name: '',
    second_name: '',
    third_name: '',
    phone: '',
    password: '',
    type: 1,
    birthday: '',
  });

  useEffect(() => {
    onGetFields();
  }, [onGetFields]);

  useEffect(() => {
    if (fieldsList.length) {
      setUser({ ...user, fields: fieldsList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsList]);

  const onHandleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    onCreateNewUser(user);
    setUser({
      first_name: '',
      second_name: '',
      third_name: '',
      phone: '',
      password: '',
      type: 1,
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionUserModal
        setOpen={setOpen}
        user={user}
        setUser={setUser}
        onHandleChange={onHandleChange}
        onSubmit={onSubmit}
        addModal={true}
        headerLabel={headerLabel}
      />
      <Notify
        closeFn={onCloseUserActionMessages}
        status='success'
        text='Пользователь успешно создан!'
        isOpen={isUserActionSuccess}
      />
      <Notify
        closeFn={onCloseUserActionMessages}
        status='error'
        text='Ошибка при создании пользователя!'
        isOpen={isUserActionError}
      />
    </Dialog>
  );
};
const mapStateToProps = ({
  users: { isUserActionSuccess, isUserActionError },
  fields: { fieldsList },
}) => {
  return {
    isUserActionSuccess,
    isUserActionError,
    fieldsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFields: () => dispatch(onGetFields()),
    onCreateNewUser: (data) => dispatch(onCreateNewUser(data)),
    onCloseUserActionMessages: () => dispatch(onCloseUserActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
