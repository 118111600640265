import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import FiltersSearchButton from '../FiltersSearchButton';
import UsersSalesByCategoriesModal from '../FiltersModals/UsersSalesByCategoriesModal';
import { onGetProductsCategoriesList } from '../../../../store/actions/categories';
import UsersSalesByCategoriesTable from '../FiltersTables/UsersSalesByCategoriesTable';

const UsersSalesByCategories = ({
  users,
  setUsers,
  selectAll,
  getCategories,
}) => {
  const [loading, setLoading] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <Box mx={2}>
      <Grid container justify='flex-end'>
        <FiltersSearchButton title='Поиск' submit={() => setShowModal(true)} />
      </Grid>
      <UsersSalesByCategoriesTable
        list={users}
        loading={loading}
        setList={setUsers}
        selectAll={selectAll}
      />
      <UsersSalesByCategoriesModal
        isShowModal={isShowModal}
        closeModal={() => setShowModal(false)}
        setUsers={setUsers}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

const mapDipatchToProps = (dispatch) => ({
  getCategories: () => dispatch(onGetProductsCategoriesList()),
});

export default connect(null, mapDipatchToProps)(UsersSalesByCategories);
