import axios from './axios';

export const onPushNotification = async (data) => {
  return await axios.post('/devices/send_pushes', data);
};

export const sendPushByUsersReq = async ({ users, title, body }) =>
  await axios.post('/bulk/send_push', {
    client_ids: users,
    title,
    body,
  });
