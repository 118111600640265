import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Box,
  TextField,
  Dialog,
  Button,
  FormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import propStyle from '../../../resourses/propStyles';

import Notify from '../../Notify';
import HeaderForModal from '../HeaderForModal';
import Select from 'react-select';

import {
  onPushMessageNearShop,
  onCloseActionShopsMessage,
} from '../../../store/actions/shops';
import { onGetFields } from '../../../store/actions/fields';

const PushNearShopModal = ({
  setOpen,
  open,
  id,
  onPushMessageNearShop,
  isShopsActionSuccess,
  isShopsActionError,
  onGetFields,
  fieldsList,
}) => {
  const styles = useStyles();
  const [radius, setRadius] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [curField, setCurField] = useState({});

  useEffect(() => {
    onGetFields();
  }, [onGetFields]);

  const onCloseModal = () => {
    setOpen(false);
    onClearData();
  };

  const onClearData = () => {
    setRadius('');
    setTitle('');
    setBody('');
    setCurField({});
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const pushData = {
      radius: Number(radius) * 1000,
      body,
      title,
      fields:
        !curField.label || !curField.value
          ? []
          : [{ field_id: curField.id, value: curField.value }],
    };

    onPushMessageNearShop(id, pushData);
    onClearData();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <form onSubmit={onSubmit} className={styles.modal}>
        <HeaderForModal
          setOpen={onCloseModal}
          headerLabel={'Отправка уведомлений'}
        />
        <Box>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='column'
          >
            <Box className={styles.inputLabel}>
              <FormLabel component='legend'>Заголовок</FormLabel>
            </Box>
            <Box>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={styles.input}
                variant='outlined'
                required
              />
            </Box>
          </Grid>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='column'
          >
            <Box className={styles.inputLabel}>
              <FormLabel component='legend'>Текст</FormLabel>
            </Box>
            <Box>
              <TextField
                onChange={(e) => setBody(e.target.value)}
                value={body}
                className={styles.input}
                variant='outlined'
                multiline
                rows={3}
                required
              />
            </Box>
          </Grid>

          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Радиус уведомлений, км
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={(e) => setRadius(e.target.value)}
                value={radius}
                className={styles.input}
                label='Радиус'
                variant='outlined'
                required
              />
            </Grid>
          </Grid>
          <Grid container direction='row'>
            <Grid style={{ paddingRight: 20 }} item xs={6}>
              <Box className={styles.inputLabel}>
                <FormLabel component='legend'>Доп. поле</FormLabel>
              </Box>
              <Select
                styles={propStyle.selectStyle}
                placeholder='Выбери доп. поле'
                value={curField}
                isSearchable
                onChange={(selectedOption) =>
                  setCurField({
                    label: selectedOption.label,
                    value: selectedOption.value,
                    id: selectedOption.id,
                  })
                }
                options={fieldsList.map((el, i) => ({
                  label: el.name,
                  value: '',
                  id: el.id,
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.inputLabel}>
                <FormLabel component='legend'>Значение</FormLabel>
              </Box>
              <TextField
                value={curField?.value || ''}
                onChange={(e) =>
                  setCurField({ ...curField, value: e.target.value })
                }
                name='title'
                className={styles.input}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Box>
        <Box width='100%' mt={4}>
          <Grid container direction='row' justify='center'>
            <Box mr={3}>
              <Button
                className={styles.submitButton}
                variant='contained'
                type='submit'
              >
                Отправить
              </Button>
            </Box>
            <Box>
              <Button onClick={onCloseModal} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
      <Notify
        closeFn={onCloseActionShopsMessage}
        status='success'
        text='Уведомления успешно отправлены!'
        isOpen={isShopsActionSuccess}
      />
      <Notify
        closeFn={onCloseActionShopsMessage}
        status='error'
        text='Ошибка при отправке сообщений!'
        isOpen={isShopsActionError}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: 2,
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  shops: { isShopsActionSuccess, isShopsActionError },
  fields: { fieldsList },
}) => {
  return {
    isShopsActionSuccess,
    isShopsActionError,
    fieldsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPushMessageNearShop: (id, radius) =>
      dispatch(onPushMessageNearShop(id, radius)),
    onGetFields: () => dispatch(onGetFields()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNearShopModal);
