import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import {
  onGetShopsListReq,
  onAddShopReq,
  onEditShopReq,
  onDeleteShopReq,
  onPushMessageNearShopReq,
} from '../../api/shops';

export const onGetShopsList = () => {
  return async (dispatch) => {
    const type = constant.IS_LOADING_SHOPS_LIST;

    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetShopsListReq();

      dispatch({
        type: constant.ON_GET_SHOPS_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddShop = (shopData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddShopReq(shopData);

      const shops = [...getState().shops.shopsList, data];

      dispatch({
        type: constant.ON_GET_SHOPS_LIST,
        payload: shops,
      });

      dispatch({
        type: constant.IS_SHOPS_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_SHOPS_ACTION_ERROR,
      });
    }
  };
};

export const onEditShop = (shopData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditShopReq(shopData);

      const shops = getState().shops.shopsList;

      const idx = shops.findIndex((el) => el.id === data.id);

      const list = [...shops.slice(0, idx), data, ...shops.slice(idx + 1)];

      dispatch({
        type: constant.ON_GET_SHOPS_LIST,
        payload: list,
      });

      dispatch({
        type: constant.IS_SHOPS_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_SHOPS_ACTION_ERROR,
      });
    }
  };
};

export const onDeleteShop = (id) => {
  return async (dispatch, getState) => {
    await onDeleteShopReq(id);

    const shops = getState().shops.shopsList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_SHOPS_LIST,
      payload: shops,
    });
  };
};

export const onPushMessageNearShop = (id, data) => {
  return async (dispatch) => {
    try {
      await onPushMessageNearShopReq(id, data);

      dispatch({
        type: constant.IS_SHOPS_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_SHOPS_ACTION_ERROR,
      });
    }
  };
};

export const onCloseActionShopsMessage = () => {
  return {
    type: constant.ON_CLOSE_ACTION_SHOPS_MESSAGE,
  };
};
