import React, { useState, useEffect } from 'react';

import { Box, Dialog } from '@material-ui/core';

import HeaderForModal from '../HeaderForModal';
import { getQuestionsStatsReq } from '../../../api/questions';
import QuestionsHistoryTable from '../../Tables/QuestionsHistoryTable';

const QuestionsHistoryModal = ({ id, open, setOpen }) => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    (async () => {
      if (id) {
        const {
          data: { data },
        } = await getQuestionsStatsReq(id);
        setHistory(data);
      }
    })();
    return () => setHistory(null);
  }, [id]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={closeModal}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box p='20px'>
        <HeaderForModal setOpen={closeModal} headerLabel='История' />
        {history && (
          <>
            <Box mb={2} fontSize={20} fontWeight='600'>
              Опрос пройден: {history.clients_count} раз(-а)
            </Box>
            <Box>
              {history?.table?.length
                ? history.table.map((storyItem, i) => (
                    <QuestionsHistoryTable key={i} storyItem={storyItem} />
                  ))
                : null}
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default QuestionsHistoryModal;
