import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import Spinner from '../../Spinner';
import EditLoyaltyModal from '../../Modals/EditLoyaltyModal';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import { onDeleteLoyalty } from '../../../store/actions/loyalty';

const LoyaltyTable = ({ list, loading, onDeleteLoyalty }) => {
  const styles = useStyles();
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loyaltyId, setLoyaltyId] = useState();
  const [loyalty, setLoyalty] = useState({});

  if (loading) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onOpenDeleteModal = (id) => {
    setLoyaltyId(id);
    setConfirmModal(true);
  };

  const onOpenEditModal = (el) => {
    setLoyalty(el);
    setEditModal(true);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>От</TableCell>
              <TableCell align='center'>До</TableCell>
              <TableCell align='center'>Кэшбек</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length
              ? list.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>{el.from}₽</TableCell>
                    <TableCell align='center'>{el.to}₽</TableCell>
                    <TableCell align='center'>{el.percent}%</TableCell>
                    <TableCell align='center'>
                      <Grid container direction='row' justify='space-around'>
                        <Tooltip title='Редактировать'>
                          <EditIcon
                            onClick={() => onOpenEditModal(el)}
                            className={styles.editIcon}
                          />
                        </Tooltip>
                        <Tooltip title='Удалить'>
                          <CloseIcon
                            onClick={() => onOpenDeleteModal(el.id)}
                            className={styles.closeIcon}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {!list.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список программ лояльности пустой
        </Box>
      )}
      <EditLoyaltyModal
        open={editModal}
        setOpen={setEditModal}
        loyaltyData={loyalty}
      />
      <ConfirmDeleteModal
        id={loyaltyId}
        open={confirmModal}
        setOpen={setConfirmModal}
        text='пользователя'
        actionFn={onDeleteLoyalty}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteLoyalty: (id) => dispatch(onDeleteLoyalty(id)),
  };
};

export default connect(null, mapDispatchToProps)(LoyaltyTable);
