import * as constant from '../constants';

const initialState = {
  commentsList: [],
  loadingCommentsList: true,
  commentsListCount: 0,
};

const commentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_COMMENTS_LIST:
      return {
        ...state,
        commentsList: payload.list,
        commentsListCount: payload.count,
      };
    case constant.IS_LOADING_COMMENTS_LIST:
      return {
        ...state,
        loadingCommentsList: payload,
      };
    default:
      return state;
  }
};

export default commentsReducer;
