import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import Spinner from '../../../../../components/Spinner';

const useStyles = makeStyles(() => ({
  outletCellItem: {
    border: 'none',
  },
}));

const UsersSalesByCategoriesTable = ({ list, setList, loading, selectAll }) => {
  const styles = useStyles();

  if (loading) {
    return (
      <Box mt={3}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  if (!list.length && !loading) return null;

  const handleJoinUser = (userId, checked) => {
    const users = list.map((user) =>
      user.user_id === userId ? { ...user, checked } : user,
    );

    setList(users);
  };

  return (
    <Box mt={3}>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' onClick={selectAll}>
                <Button>Выбрать все</Button>
              </TableCell>
              <TableCell align='center'>ФИО</TableCell>
              <TableCell align='center'>Телефон</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length
              ? list.map((el) => (
                  <TableRow key={el.user_id}>
                    <TableCell align='center'>
                      <Checkbox
                        checked={el.checked}
                        onChange={(e) =>
                          handleJoinUser(el.user_id, e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell align='center'>{el.name}</TableCell>
                    <TableCell align='center'>{el.phone}</TableCell>
                    <TableCell>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell variant='footer' align='center'>
                              Продукт
                            </TableCell>
                            <TableCell variant='footer' align='center'>
                              Код
                            </TableCell>
                            <TableCell variant='footer' align='center'>
                              Кол-во продаж
                            </TableCell>
                            <TableCell variant='footer' align='center'>
                              Сумма
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {el.products.map((product) => (
                            <TableRow key={product.id}>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {product.name}
                              </TableCell>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {product.code}
                              </TableCell>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {product.sale_count}
                              </TableCell>
                              <TableCell
                                className={styles.outletCellItem}
                                variant='body'
                                align='center'
                              >
                                {product.sale_amount.toFixed(2)}₽
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersSalesByCategoriesTable;
