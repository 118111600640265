import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';

import {
  onGetPromosListReq,
  onCreatePromoReq,
  onDeletePromoReq,
  onEditPromoReq,
} from '../../api/promos';

export const onGetPromosList = () => {
  const type = constant.IS_LOADING_PROMOS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetPromosListReq();

      dispatch({
        type: constant.ON_GET_PROMOS_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddPromo = (promosData) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await onCreatePromoReq(promosData);

      dispatch({
        type: constant.ON_ADD_PROMO,
        payload: data,
      });
      dispatch({
        type: constant.IS_PROMO_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_PROMO_ACTION_ERROR,
      });
    }
  };
};

export const onEditPromo = (promoData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditPromoReq(promoData);

      const promos = getState().promos.promosList;

      const idx = promos.findIndex((el) => el.id === promoData.id);

      const list = [...promos.slice(0, idx), data, ...promos.slice(idx + 1)];

      dispatch({
        type: constant.ON_GET_PROMOS_LIST,
        payload: list,
      });
      dispatch({
        type: constant.IS_PROMO_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_PROMO_ACTION_ERROR,
      });
    }
  };
};

export const onDeletePromo = (id) => {
  return async (dispatch, getState) => {
    await onDeletePromoReq(id);

    const list = getState().promos.promosList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_PROMOS_LIST,
      payload: list,
    });
  };
};

export const onClosePromosActionMessages = () => {
  return {
    type: constant.ON_CLOSE_ACTION_PROMOS_MESSAGE,
  };
};
