import axios from './axios';

export const onGetCouponsReq = async () => {
  return await axios.post('/coupons/list');
};

export const onAddCouponReq = async (data) => {
  return await axios.post('/coupons/create', data);
};

export const onDeleteCouponReq = async (id) => {
  return await axios.get(`/coupons/delete/${id}`);
};
