import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Dialog, Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';

import Spinner from '../../Spinner';

import { onGetOrderById } from '../../../store/actions/orders';

const OrderDetailsModal = ({
  orderId,
  setOpen,
  open,
  loadingOrderById,
  curOrderById,
  onGetOrderById,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (orderId) {
      onGetOrderById(orderId);
    }
  }, [orderId, onGetOrderById]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      {loadingOrderById ? (
        <Grid
          className={styles.modal}
          container
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Spinner />
        </Grid>
      ) : (
        <Grid container direction='column' className={styles.modal}>
          <Box pb={1} borderBottom={1} borderColor='grey.500'>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              style={{ marginBottom: 14 }}
            >
              <Box>
                <Typography variant='h6' component='h6'>
                  Заказ №{curOrderById.id}
                </Typography>
              </Box>
              <Box>
                <CloseIcon
                  className={styles.closeButton}
                  onClick={() => setOpen(false)}
                />
              </Box>
            </Grid>
            <Box>
              <Grid
                className={styles.infoContainer}
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Время и дата:</span>{' '}
                  {curOrderById.created_at.slice(11, 16)}{' '}
                  {curOrderById.created_at.slice(0, 10).replaceAll('-', '.')}
                </Box>
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Статус:</span>{' '}
                  <span
                    style={{
                      color:
                        curOrderById.status === 4
                          ? 'rgb(60, 179, 113, 0.7)'
                          : 'rgb(255, 165, 0, 0.7)',
                    }}
                  >
                    {curOrderById.status === 4 ? 'Завершен' : 'В процессе'}
                  </span>
                </Box>
              </Grid>
              <Grid
                className={styles.infoContainer}
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Имя:</span>{' '}
                  {curOrderById.user_first_name}
                </Box>
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Фамилия:</span>{' '}
                  {curOrderById.user_second_name}
                </Box>
              </Grid>
              <Grid
                className={styles.infoContainer}
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Адрес:</span>{' '}
                  {curOrderById.outlets_address}
                </Box>
                <Box className={styles.infoItem}>
                  <span className={styles.infoItemLabel}>Комментарий:</span>{' '}
                  {curOrderById.user_comment}
                </Box>
              </Grid>
            </Box>
            {/*  */}
          </Box>
          <Box mb={5}>
            <Box mb={2} pt={2}>
              <Typography variant='h6' component='h6'>
                Товары ({curOrderById?.basket?.length})
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                {curOrderById?.basket?.length &&
                  curOrderById.basket.map((el) => (
                    <Grid
                      key={el.id}
                      className={styles.basketContainer}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Grid className={styles.basketItem} item xs={3}>
                        <Typography className={styles.basketProductName}>
                          {el.product_name}
                        </Typography>
                      </Grid>
                      <Grid className={styles.basketItem} item xs={3}>
                        {el.count}{' '}
                        {el.product_is_by_weight === 1 ? 'кг.' : 'шт.'}
                      </Grid>
                      <Grid className={styles.basketItem} item xs={3}>
                        <Typography className={styles.basketProductAmount}>
                          {el.amount}₽
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                <Box width='100%' pt={1} borderTop={1} borderColor='grey.500'>
                  <Grid
                    className={styles.basketContainer}
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductName}>
                        Итого
                      </Typography>
                    </Grid>
                    <Grid className={styles.basketItem} item xs={3}></Grid>
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductAmount}>
                        {(+curOrderById.amount_now).toFixed(2)}₽
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    className={styles.basketContainer}
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductName}>
                        Бонус за покупку
                      </Typography>
                    </Grid>
                    <Grid className={styles.basketItem} item xs={3}></Grid>
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductAmount}>
                        {(+curOrderById.bonus.added).toFixed(2)}₽
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    className={styles.basketContainer}
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductName}>
                        Бонусы
                      </Typography>
                    </Grid>
                    <Grid className={styles.basketItem} item xs={3}></Grid>
                    <Grid className={styles.basketItem} item xs={3}>
                      <Typography className={styles.basketProductAmount}>
                        {(+curOrderById.bonus.accumulated).toFixed(2)}₽
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Grid container direction='row' justify='center'>
            <Button
              onClick={() => setOpen(false)}
              style={{ backgroundColor: propStyle.orangeColor }}
              variant='contained'
            >
              Закрыть
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 14,
  },
  closeButton: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  infoContainer: {
    marginBottom: 10,
  },
  infoItem: {
    width: 240,
  },
  infoItemLabel: {
    fontWeight: 'bold',
  },
  basketContainer: {
    marginBottom: 10,
  },
  basketItem: {
    textAlign: 'center',
  },
  basketProductName: {
    // fontWeight: '600',
  },
  basketProductAmount: {
    // color: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({ orders: { curOrderById, loadingOrderById } }) => {
  return {
    loadingOrderById,
    curOrderById,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrderById: (id) => dispatch(onGetOrderById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsModal);
