import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import propStyle from '../../../resourses/propStyles';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';
import AddSubCategoryModal from '../../Modals/AddSubCategoryModal';

import { imgPath, noPhotoImg } from '../../../resourses/variables';

import {
  onDeleteCategory,
  onDeleteSubCategory,
  onEditCategory,
} from '../../../store/actions/categories';
import ActionCategoryModal from '../../Modals/ActionCategoryModal/ActionCategoryModal';
import EditSubCategoryModal from '../../Modals/EditSubCategoryModal';

const CategoriesList = ({
  categories,
  loading,
  onDeleteCategory,
  onDeleteSubCategory,
  onEditCategory,
}) => {
  const styles = useStyles();
  const [openCatId, setOpenCatId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [confirmDeleteSubCatDialog, setConfirmDeleteSubCatDialog] =
    useState(false);
  const [editCategoryDialog, setEditCategoryDialog] = useState(false);
  const [addSubCategoryDialog, setAddSubCategoryDialog] = useState(false);
  const [editSubCategoryDialog, setEditSubCategoryDialog] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [subCategory, setSubCategory] = useState({});
  const [categoryFile, setCategoryFile] = useState();

  if (loading) {
    return (
      <Box my={5}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  const onShowDeleteCategoryModal = (id) => {
    setCategoryId(id);
    setConfirmDeleteDialog(true);
  };

  const onShowDeleteSubCategoryModal = (id) => {
    setSubCategoryId(id);
    setConfirmDeleteSubCatDialog(true);
  };

  const onShowEditCategoryModal = (id, name, file) => {
    setCategoryName(name);
    setCategoryId(id);
    setEditCategoryDialog(true);
    setCategoryFile(file);
  };

  const onShowAddSubCategoryModal = (id) => {
    setCategoryId(id);
    setAddSubCategoryDialog(true);
  };

  const onShowEditSubCategoryModal = (sub) => {
    setSubCategory(sub);
    setEditSubCategoryDialog(true);
  };

  const onShowCategory = (id) => {
    id === openCatId ? setOpenCatId(null) : setOpenCatId(id);
  };

  return (
    <>
      {categories.length ? (
        <Box
          className={styles.itemContainer}
          mt={2}
          p={2}
          border={1}
          borderColor='grey.500'
          borderRadius={8}
        >
          <Box className={styles.labelWrapper} pb={1}>
            <Typography>Категории ({categories.length})</Typography>
          </Box>
          <Box mt={2}>
            {categories.map((cat) => (
              <Box
                onClick={() => onShowCategory(cat.id)}
                className={styles.item}
                key={cat.id}
                mb={2}
              >
                {/* category */}
                <Box>
                  <Grid
                    className={styles.category}
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Box>
                      <Grid container direction='row' alignItems='center'>
                        <Box mr={3}>
                          {cat.file ? (
                            <img
                              src={imgPath + cat.file}
                              className={styles.img}
                              alt='categoryPhoto'
                            />
                          ) : (
                            <img
                              src={noPhotoImg}
                              className={styles.img}
                              alt='categoryPhoto'
                            />
                          )}
                        </Box>
                        <Box>
                          <Typography>{cat.name}</Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Box>
                      {cat.sub_categories?.length && openCatId === cat.id ? (
                        <ExpandMoreIcon />
                      ) : cat.sub_categories?.length ? (
                        <ExpandLessIcon />
                      ) : null}
                    </Box>
                  </Grid>
                </Box>
                {/* subCategory */}
                {cat.sub_categories?.length &&
                openCatId === cat.sub_categories[0].parent_id
                  ? cat.sub_categories.map((sub) => (
                      <Box onClick={(e) => e.stopPropagation()} key={sub.id}>
                        <Grid
                          className={styles.subCategory}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Box>
                            <Box>
                              <Typography>{sub.name}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Grid container direction='row' alignItems='center'>
                              <Tooltip title='Редактировать'>
                                <Box mr={2}>
                                  <EditIcon
                                    onClick={() =>
                                      onShowEditSubCategoryModal(sub)
                                    }
                                    className={styles.editIcon}
                                  />
                                </Box>
                              </Tooltip>
                              <Tooltip title='Удалить'>
                                <Box>
                                  <CloseIcon
                                    onClick={() =>
                                      onShowDeleteSubCategoryModal(sub.id)
                                    }
                                    className={styles.closeIcon}
                                  />
                                </Box>
                              </Tooltip>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    ))
                  : null}

                <Grid
                  onClick={(e) => e.stopPropagation()}
                  className={styles.addSubCategoryWrapper}
                  container
                  direction='row'
                  justify='space-between'
                >
                  <Box>
                    <Button
                      onClick={() => onShowDeleteCategoryModal(cat.id)}
                      className={styles.actionBottomButton}
                    >
                      Удалить категорию
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() =>
                        onShowEditCategoryModal(cat.id, cat.name, cat.file)
                      }
                      className={styles.actionBottomButton}
                    >
                      Редактировать категорию
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => onShowAddSubCategoryModal(cat.id)}
                      className={styles.actionBottomButton}
                    >
                      Добавить подкатегорию
                    </Button>
                  </Box>
                </Grid>
              </Box>
            ))}
          </Box>
          <ConfirmDeleteModal
            id={categoryId}
            open={confirmDeleteDialog}
            setOpen={setConfirmDeleteDialog}
            actionFn={onDeleteCategory}
          />
          <ConfirmDeleteModal
            id={subCategoryId}
            open={confirmDeleteSubCatDialog}
            setOpen={setConfirmDeleteSubCatDialog}
            actionFn={onDeleteSubCategory}
          />
          <ActionCategoryModal
            open={editCategoryDialog}
            setOpen={setEditCategoryDialog}
            onCategoryAction={onEditCategory}
            headerLabel='Редактирование категории'
            id={categoryId}
            categoryName={categoryName}
            file={categoryFile}
            setFile={setCategoryFile}
          />
          <AddSubCategoryModal
            open={addSubCategoryDialog}
            setOpen={setAddSubCategoryDialog}
            categoryId={categoryId}
          />
          <EditSubCategoryModal
            open={editSubCategoryDialog}
            setOpen={setEditSubCategoryDialog}
            subCategory={subCategory}
          />
        </Box>
      ) : (
        <Box>
          <Typography component='h6' variant='h6'>
            Список категорий пуст
          </Typography>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  labelWrapper: {
    borderBottom: `1px solid ${propStyle.borderColor}`,
  },
  itemContainer: {
    backgroundColor: '#fff',
  },
  item: {
    boxShadow: '0 0 8px rgba(0,0,0,0.1)',
    borderRadius: 8,
  },
  img: {
    width: 40,
    height: 40,
  },
  category: {
    userSelect: 'none',
    cursor: 'pointer',
    padding: 8,
    borderRadius: 8,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      backgroundColor: propStyle.shadowedColor,
    },
  },
  subCategory: {
    userSelect: 'none',
    padding: 8,
    borderRadius: 8,
  },
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  addSubCategoryWrapper: {
    marginTop: 8,
    padding: 8,
    paddintTop: 0,
  },
  actionBottomButton: {
    color: propStyle.orangeColor,
    padding: 4,
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteCategory: (id) => dispatch(onDeleteCategory(id)),
    onDeleteSubCategory: (id) => dispatch(onDeleteSubCategory(id)),
    onEditCategory: (data) => dispatch(onEditCategory(data)),
  };
};

export default connect(null, mapDispatchToProps)(CategoriesList);
