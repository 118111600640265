import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import FiltersSearchButton from '../FiltersSearchButton/FiltersSearchButton';
import UsersSalesByPriceModal from '../FiltersModals/UsersSalesByPriceModal';
import UsersSalesByPriceTable from '../FiltersTables/UsersSalesByPriceTable';

const UsersSalesByPriceFilters = ({ users, setUsers, selectAll }) => {
  const [loading, setLoading] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  return (
    <Box mx={2}>
      <Grid container justify='flex-end'>
        <FiltersSearchButton title='Поиск' submit={() => setShowModal(true)} />
      </Grid>
      <UsersSalesByPriceTable
        loading={loading}
        list={users}
        setList={setUsers}
        selectAll={selectAll}
      />
      <UsersSalesByPriceModal
        isShowModal={isShowModal}
        closeModal={() => setShowModal(false)}
        setUsers={setUsers}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default UsersSalesByPriceFilters;
