import * as constant from '../constants';

const initialState = {
  loyaltyList: [],
  loadingLoyaltyList: true,
  loyaltyTypes: [],
  isLoyaltyActionSuccess: false,
  isLoyaltyActionError: false,
};

const loyaltyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_LOYALTY_LIST:
      return {
        ...state,
        loyaltyList: payload,
      };
    case constant.IS_LOADING_LOYALTY_LIST:
      return {
        ...state,
        loadingLoyaltyList: payload,
      };
    case constant.ON_GET_LOYALTY_TYPES:
      return {
        ...state,
        loyaltyTypes: payload,
      };
    case constant.ON_ADD_LOYALTY:
      return {
        ...state,
        loyaltyList: [...state.loyaltyList, payload],
      };
    case constant.IS_LOYALTY_ACTION_SUCCESS:
      return {
        ...state,
        isLoyaltyActionSuccess: true,
      };
    case constant.IS_LOYALTY_ACTION_ERROR:
      return {
        ...state,
        isLoyaltyActionError: true,
      };
    case constant.ON_CLOSE_ACTION_LOYALTY_MESSAGE:
      return {
        ...state,
        isLoyaltyActionSuccess: false,
        isLoyaltyActionError: false,
      };
    default:
      return state;
  }
};

export default loyaltyReducer;
