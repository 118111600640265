import Highcharts from 'highcharts';

export default Highcharts.theme = {
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, '#f1f1f1'],
        [1, '#f1f1f1'],
      ],
    },
  },
  credits: {
    enabled: false,
  },
};
