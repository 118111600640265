import * as constant from '../constants';
import {
  onGetProductsCategoriesListReq,
  onAddNewCategoryReq,
  onAddNewSubCategoryReq,
  onDeleteCategoryReq,
  onEditCategoryReq,
  onEditSubCategoryReq,
} from '../../api/categories';
import { dispatchLoadingStatus } from './helpersFuncions';

export const onGetProductsCategoriesList = () => {
  return async (dispatch) => {
    const type = constant.IS_LOADING_CATEGORIES_LIST;

    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: { data },
      } = await onGetProductsCategoriesListReq();

      dispatch({
        type: constant.ON_GET_CATEGORIES_LIST,
        payload: data,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onGetCategoriesForSelect = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await onGetProductsCategoriesListReq();

    const list = data.map((el) => {
      return {
        parentCategoryId: el.id,
        name: el.name,
      };
    });

    dispatch({
      type: constant.ON_GET_CATEGORIES_FOR_SELECT,
      payload: list,
    });
  };
};

export const onAddNewCategory = ({ name, file_content }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddNewCategoryReq({ name, file_content });

      const categories = getState().categories.categoriesList;

      dispatch({
        type: constant.ON_GET_CATEGORIES_LIST,
        payload: [...categories, data],
      });
      dispatch({
        type: constant.IS_CATEGORY_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_CATEGORY_ACTION_ERROR,
      });
    }
  };
};

export const onEditCategory = ({ name, id, file_content }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditCategoryReq({ name, id, file_content });

      const categories = getState().categories.categoriesList.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            name: data.name,
            file: data.file,
          };
        } else {
          return el;
        }
      });

      dispatch({
        type: constant.ON_GET_CATEGORIES_LIST,
        payload: categories,
      });
      dispatch({
        type: constant.IS_CATEGORY_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_CATEGORY_ACTION_ERROR,
      });
    }
  };
};

export const onDeleteCategory = (id) => {
  return async (dispatch, getState) => {
    await onDeleteCategoryReq(id);

    const categories = getState().categories.categoriesList.filter(
      (el) => el.id !== id
    );

    dispatch({
      type: constant.ON_GET_CATEGORIES_LIST,
      payload: categories,
    });
  };
};

export const onDeleteSubCategory = (id) => {
  return async (dispatch, getState) => {
    onDeleteCategoryReq(id);

    const categories = getState().categories.categoriesList.map((el) => {
      return {
        ...el,
        sub_categories: el.sub_categories?.length
          ? el.sub_categories.filter((sub) => sub.id !== id)
          : null,
      };
    });

    dispatch({
      type: constant.ON_GET_CATEGORIES_LIST,
      payload: categories,
    });
  };
};

export const onCloseCategoryActionMessages = () => {
  return { type: constant.ON_CLOSE_ACTION_CATEGORY_MESSAGE };
};

export const onAddNewSubCategory = ({ name, categoryId }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddNewSubCategoryReq({ name, parent_id: categoryId });

      const categories = getState().categories.categoriesList.map((el) => {
        if (!el.sub_categories?.length && el.id === categoryId) {
          return {
            ...el,
            sub_categories: [data],
          };
        } else if (el.id === categoryId) {
          return {
            ...el,
            sub_categories: [...el.sub_categories, data],
          };
        } else return el;
      });
      dispatch({
        type: constant.ON_GET_CATEGORIES_LIST,
        payload: categories,
      });

      dispatch({
        type: constant.IS_CATEGORY_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_CATEGORY_ACTION_ERROR,
      });
    }
  };
};

export const onEditSubCategory = ({ name, id, parent_id }) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditSubCategoryReq({ name, id, parent_id });

      const categories = getState().categories.categoriesList.map((el) => {
        if (el.id === parent_id && el.sub_categories?.length) {
          return {
            ...el,
            sub_categories: [...el.sub_categories, data],
          };
        } else if (el.id === parent_id) {
          return {
            ...el,
            sub_categories: [data],
          };
        } else {
          const list = el.sub_categories.filter((sub) => sub.id !== id);
          return { ...el, sub_categories: list };
        }
      });

      dispatch({
        type: constant.ON_GET_CATEGORIES_LIST,
        payload: categories,
      });

      dispatch({
        type: constant.IS_CATEGORY_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_CATEGORY_ACTION_ERROR,
      });
    }
  };
};
