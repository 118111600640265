import * as constant from '../constants';

const initialState = {
  bonusesRulesList: [],
  loadingBonusesRulesList: false,
};

const bonusesRulesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.UPDATE_BONUSES_RULES_LIST:
      return {
        ...state,
        bonusesRulesList: payload,
      };
    case constant.IS_LOADING_BONUSES_RULES_LIST:
      return {
        ...state,
        loadingBonusesRulesList: payload,
      };
    default:
      return state;
  }
};

export default bonusesRulesReducer;
