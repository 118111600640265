import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  Paper,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Spinner from '../../Spinner';
import propStyle from '../../../resourses/propStyles';
import OrderDetailsModal from '../../Modals/OrderDetails/OrderDetailsModal';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';
import TablePaginationActions from '../TablePagination';

import { onDeleteOrder } from '../../../store/actions/orders';

const OrdersTable = ({
  list,
  loading,
  onDeleteOrder,
  page,
  setPage,
  ordersListCount,
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [curOrderId, setCurOrderId] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // pagination
  const [rowsPerPage] = useState(20);

  if (loading) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onOpenOrderDetailsModal = (bool, id = null) => {
    if (!openDeleteDialog) {
      setCurOrderId(id);
      setOpen(bool);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Номер покупки</TableCell>
              <TableCell align='center'>Время и дата</TableCell>
              <TableCell align='center'>Сумма</TableCell>
              <TableCell align='center'>Статус</TableCell>
              <TableCell align='center'>Адрес</TableCell>
              <TableCell align='center'>Заказчик</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length && !loading
              ? (rowsPerPage > 0 && list).map((el) => (
                  <TableRow
                    onClick={() => onOpenOrderDetailsModal(true, el.id)}
                    className={styles.tableItem}
                    key={el.id}
                  >
                    <TableCell align='center'>#{el.id}</TableCell>
                    <TableCell align='center'>
                      {el.created_at.slice(11, 16)}{' '}
                      {el.created_at.slice(0, 10).replaceAll('-', '.')}
                    </TableCell>
                    <TableCell align='center'>
                      {(+el.amount).toFixed(2)}₽
                    </TableCell>
                    <TableCell align='center'>
                      <Box
                        style={{
                          paddingTop: 3,
                          paddingBottom: 3,
                          paddingLeft: 5,
                          paddingRight: 5,
                          backgroundColor:
                            el.status === 1
                              ? 'rgb(60, 179, 113, 0.7)'
                              : 'rgb(255, 165, 0, 0.7)',
                          borderRadius: 12,
                        }}
                      >
                        {el.status === 0 ? 'Предзаказ' : 'Завершен'}
                      </Box>
                    </TableCell>
                    <TableCell align='center'>{el.outlets_address}</TableCell>
                    <TableCell align='center'>
                      {el.user_first_name} {el.user_second_name}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={10}
                count={ordersListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={() => (
                  <TablePaginationActions
                    colSpan={4}
                    count={ordersListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={null}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!list.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список покупок пустой
        </Box>
      )}
      <ConfirmDeleteModal
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        id={curOrderId}
        actionFn={onDeleteOrder}
      />
      <OrderDetailsModal orderId={curOrderId} setOpen={setOpen} open={open} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: propStyle.shadowedColor,
    },
  },
  closeIcon: {
    zIndex: 1,
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
}));

const mapStateToProps = ({ orders: { ordersListCount } }) => {
  return {
    ordersListCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteOrder: (id) => dispatch(onDeleteOrder(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
