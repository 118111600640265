import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';

import TablePaginationActions from '../TablePagination';
import SearchInput from '../../SearchInput/SearchInput';

const CardsTable = ({ list, page, setPage, cardsListCount, term, setTerm }) => {
  const styles = useStyles();

  const [rowsPerPage] = useState(20);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Номер</TableCell>
              <TableCell align='center'>Процент</TableCell>
              <TableCell align='center'>Бонусы</TableCell>
              <TableCell align='center'>Предыдущий владелец</TableCell>
              <TableCell align='right' className={styles.searchCell}>
                <SearchInput term={term} setTerm={setTerm} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length
              ? list.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>
                      {el.number ? el.number : ''}
                    </TableCell>
                    <TableCell align='center'>{el.bills[0].percent}%</TableCell>
                    <TableCell align='center'>{el.bills[0].value} p.</TableCell>
                    <TableCell align='center'>{el.old_holder_name}</TableCell>
                    <TableCell align='center' />
                  </TableRow>
                ))
              : null}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={10}
                count={cardsListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={() => (
                  <TablePaginationActions
                    colSpan={4}
                    count={cardsListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={null}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    marginRight: 14,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  searchCell: { padding: '0 32px', width: 140 },
}));

const mapStateToProps = ({ cards: { cardsListCount } }) => ({
  cardsListCount,
});

export default connect(mapStateToProps)(CardsTable);
