import * as constant from '../constants';

const initialState = {
  ordersDaysStatsList: [],
  loadingOrdersDaysStatsList: true,
};

const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_ORDERS_DAYS_STATS:
      return {
        ...state,
        ordersDaysStatsList: payload,
      };
    case constant.IS_LOADING_ORDERS_DAYS_STATS_LIST:
      return {
        ...state,
        loadingOrdersDaysStatsList: payload,
      };
    default:
      return state;
  }
};

export default statsReducer;
