import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HeaderForModal from '../HeaderForModal';
import Select from 'react-select';

import propStyles from '../../../resourses/propStyles';

import {
  createBonusesRulesList,
  editBonusesRules,
} from '../../../store/actions/bonusesRules';
import { monthData } from '../../../resourses/variables';
import { onGetFieldsReq } from '../../../api/fields';

import { triggerTypesData, typesData } from './triggers';

const ActionBonusesRulesModal = ({
  open,
  setOpen,
  data,
  setData,
  modalTitle,
  createBonusesRulesList,
  editBonusesRules,
}) => {
  const styles = useStyles();
  const startData = useMemo(
    () => ({
      name: '',
      start_dt: '',
      day: 1,
      month: 0,
      duration: 0,
      enabled: false,
      field_id: '',
      is_birthday: false,
      sex: null,
      value: 0,
      date_trigger_type: typesData[0],
      trigger_type: triggerTypesData[0],
    }),
    [],
  );
  const [bonusesRules, setBonusesRules] = useState(startData);
  const [fieldsList, setFieldsList] = useState([]);
  const [disabledEdit, setDisabledEdit] = useState(false);

  useEffect(() => {
    (async () => {
      const {
        data: {
          data: { list },
        },
      } = await onGetFieldsReq();

      setFieldsList([
        ...list
          .filter((el) => el.is_user_editable)
          .map((el) => ({ value: el.id, label: el.name })),
      ]);
    })();

    return () => {
      setFieldsList([]);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setBonusesRules(data);
      const dateType = data.date_trigger_type;
      const triggerType = data.trigger_type;

      const newRule = { ...data };

      if (dateType === 1) {
        newRule.date_trigger_type = { value: 1, label: 'Начальная дата' };
      } else if (dateType === 2) {
        newRule.date_trigger_type = { value: 2, label: 'Дата и месяц' };
        newRule.month = {
          value: newRule.month,
          label: monthData[newRule.month - 1].label,
        };
      } else if (dateType === 3) {
        newRule.date_trigger_type = { value: 3, label: 'День рождения' };
      }

      if (triggerType === 1) {
        newRule.sex =
          newRule.sex === 0
            ? { label: 'Мужской', value: 0 }
            : { label: 'Женский', value: 1 };
        newRule.trigger_type = { value: 1, label: 'Пол' };
      } else if (triggerType === 2) {
        newRule.trigger_type = { value: 2, label: 'Доп. поле' };
        newRule.field_id = fieldsList.filter(
          (el) => el.value === newRule.field_id,
        );
      }
      setDisabledEdit(true);
      setBonusesRules(newRule);
    } else {
      setBonusesRules(startData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, startData]);

  const handleChange = (e) => {
    const name = e.target.name,
      value = e.target.value,
      checked = e.target.checked;

    const isCheckbox = ['enabled', 'is_birthday'].includes(name);
    // eslint-disable-next-line
    const isNumberValues =
      ['value', 'day', 'month', 'duration'].includes(name) && value !== 0;

    if (isCheckbox) {
      setBonusesRules({ ...bonusesRules, [name]: checked });
    } else {
      setBonusesRules({
        ...bonusesRules,
        [name]: isNumberValues ? +value : value,
      });
    }
  };

  const handleSubmit = () => {
    const newRule = { ...bonusesRules };

    newRule.date_trigger_type = newRule.date_trigger_type?.value || null;
    newRule.trigger_type = newRule.trigger_type?.value || null;
    newRule.sex = newRule.sex?.value || null;
    newRule.field_id = newRule.field_id?.value || null;
    newRule.month = newRule.month?.value || null;

    const dateType = newRule.date_trigger_type;
    const triggerType = newRule.trigger_type;

    // start_dt
    if (dateType === 1) {
      delete newRule.is_birthday;
      delete newRule.day;
      delete newRule.month;
      // birthday
    } else if (dateType === 3) {
      delete newRule.start_dt;
      delete newRule.day;
      delete newRule.month;
      newRule.is_birthday = newRule.is_birthday === true ? 1 : 0;
      // day month
    } else if (dateType === 2) {
      delete newRule.is_birthday;
      delete newRule.start_dt;
    }

    if (triggerType === 1) {
      newRule.sex = newRule.sex === true ? 1 : 0;
      delete newRule.field_id;
    } else if (triggerType === 2) {
      delete newRule.sex;
    }

    newRule.enabled = newRule.enabled === true ? 1 : 0;

    data ? editBonusesRules(newRule) : createBonusesRulesList(newRule);
  };

  const closeModal = () => {
    setBonusesRules(startData);
    setData && setData(null);
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='md' onClose={closeModal} open={open}>
      <form onSubmit={handleSubmit} className={styles.modal}>
        <HeaderForModal setOpen={closeModal} headerLabel={modalTitle} />
        {/*  */}
        <Box>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.pr20} item xs={4}>
              <TextField
                className={styles.input}
                value={bonusesRules.name}
                onChange={handleChange}
                name='name'
                label='Название'
                variant='outlined'
              />
            </Grid>
            <Grid className={styles.pr20} item xs={4}>
              <TextField
                className={styles.input}
                value={bonusesRules.value}
                onChange={handleChange}
                name='value'
                label='Баланс'
                variant='outlined'
                type='number'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={styles.input}
                value={bonusesRules.duration}
                onChange={handleChange}
                name='duration'
                label='Длительность(дни)'
                variant='outlined'
                type='number'
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.pr20} item xs={4}>
              <Select
                isSearchable={false}
                styles={propStyles.selectStyle}
                isDisabled={disabledEdit}
                placeholder='Модификатор'
                value={bonusesRules.trigger_type}
                onChange={(selectedOption) => {
                  setBonusesRules({
                    ...bonusesRules,
                    trigger_type: selectedOption,
                  });
                }}
                options={triggerTypesData}
              />
            </Grid>

            {/*  */}
            <Grid className={styles.pr20} item xs={4}>
              {bonusesRules.trigger_type?.value === 1 ? (
                <Select
                  isSearchable={false}
                  styles={propStyles.selectStyle}
                  placeholder='Выберите пол'
                  value={bonusesRules.sex}
                  isDisabled={disabledEdit}
                  onChange={(selectedOption) =>
                    setBonusesRules({
                      ...bonusesRules,
                      sex: selectedOption,
                    })
                  }
                  options={[
                    { label: 'Мужской', value: 0 },
                    {
                      label: 'Женский',
                      value: 1,
                    },
                  ]}
                />
              ) : bonusesRules.trigger_type?.value === 2 ? (
                <Select
                  styles={propStyles.selectStyle}
                  placeholder='Выберите доп. поле'
                  value={bonusesRules.field_id}
                  isDisabled={disabledEdit}
                  onChange={(selectedOption) => {
                    setBonusesRules({
                      ...bonusesRules,
                      field_id: selectedOption,
                    });
                  }}
                  options={fieldsList}
                />
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                className={styles.checkbox}
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={!!bonusesRules.enabled}
                    name='enabled'
                    color='primary'
                    style={{
                      color: propStyles.orangeColor,
                    }}
                  />
                }
                label='Активировать'
                labelPlacement='end'
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.pr20} item xs={4}>
              <Select
                isSearchable={false}
                styles={propStyles.selectStyle}
                placeholder='Модификатор'
                value={bonusesRules.date_trigger_type}
                onChange={(selectedOption) => {
                  setBonusesRules({
                    ...bonusesRules,
                    date_trigger_type: selectedOption,
                  });
                }}
                options={typesData}
              />
            </Grid>
            {bonusesRules.date_trigger_type?.value === 1 ? (
              <>
                {/*  Дата начала */}
                <Grid className={styles.pr20} item xs={4}>
                  <TextField
                    className={styles.input}
                    value={bonusesRules.start_dt}
                    onChange={handleChange}
                    name='start_dt'
                    label='Дата начала'
                    type='date'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4} />
              </>
            ) : bonusesRules.date_trigger_type?.value === 3 ? (
              <>
                {/* День рождения */}
                <Grid className={styles.pr20} item xs={4}>
                  <FormControlLabel
                    className={styles.checkbox}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={!!bonusesRules.is_birthday}
                        name='is_birthday'
                        color='primary'
                        style={{ color: propStyles.orangeColor }}
                      />
                    }
                    label='Активировать'
                    labelPlacement='end'
                  />
                </Grid>
                <Grid item xs={4} />
              </>
            ) : bonusesRules.date_trigger_type?.value === 2 ? (
              <>
                <Grid className={styles.pr20} item xs={4}>
                  <TextField
                    className={styles.input}
                    value={bonusesRules.day}
                    onChange={handleChange}
                    name='day'
                    label='Число начала'
                    variant='outlined'
                    type='number'
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    isSearchable={false}
                    styles={propStyles.selectStyle}
                    placeholder='Месяц начала'
                    value={bonusesRules.month}
                    onChange={(selectedOption) => {
                      setBonusesRules({
                        ...bonusesRules,
                        month: selectedOption,
                      });
                    }}
                    options={monthData}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Box mr={3}>
              <Button
                onClick={handleSubmit}
                className={styles.addButton}
                variant='contained'
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={closeModal} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyles.orangeColor,
    },
  },
  addButton: {
    backgroundColor: propStyles.orangeColor,
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  checkbox: {
    justifyContent: 'center',
    width: '100%',
  },
  pr20: {
    paddingRight: 20,
  },
}));

const mapDispatch = (dispatch) => ({
  createBonusesRulesList: (data) => dispatch(createBonusesRulesList(data)),
  editBonusesRules: (data) => dispatch(editBonusesRules(data)),
});

export default connect(null, mapDispatch)(ActionBonusesRulesModal);
