import React from 'react';
import classNames from 'classnames';

import { Box, TextField, makeStyles, Typography } from '@material-ui/core';

import propStyles from '../../resourses/propStyles';

const MigrationsPicker = ({ label, period, setPeriod, withoutBorder }) => {
  const styles = useStyles();

  const hangleChangePeriod = (e, periodType) => {
    const date = e.target.value;

    setPeriod({ ...period, [periodType]: date });
  };

  return (
    <Box
      className={classNames(styles.textFieldsContainer, {
        [styles.textFieldsContainerWithBorder]: !withoutBorder,
      })}
      mb={2}
    >
      {label && (
        <Typography className={styles.textFieldLabel} variant='subtitle1'>
          {label}
        </Typography>
      )}
      <TextField
        value={period.start}
        onChange={(date) => hangleChangePeriod(date, 'start')}
        label='Дата начала'
        type='date'
        className={styles.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        value={period.end}
        onChange={(date) => hangleChangePeriod(date, 'end')}
        label='Дата конца'
        type='date'
        className={styles.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  textFieldsContainer: {
    padding: 14,
    borderRadius: 4,
  },
  textFieldsContainerWithBorder: {
    border: `1px solid ${propStyles.shadowColor}`,
  },
  textFieldLabel: {
    marginBottom: 10,
  },
  textField: {
    width: 190,
    marginRight: 34,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export default MigrationsPicker;
