import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import EditUserModal from '../../Modals/EditUserModal';
import AddUserModal from '../../Modals/AddUserModal';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';
import Notify from '../../Notify';
import SearchInput from '../../SearchInput';
import TablePaginationActions from '../TablePagination';

import {
  onDeleteUser,
  onBlockUser,
  changeUserBlockMessage,
} from '../../../store/actions/users';
import UserTableCellSpinner from '../../UserTableCellSpinner';

const UsersTable = ({
  userBlockMessage,
  changeUserBlockMessage,
  onBlockUser,
  list,
  loading,
  addModal,
  setAddModal,
  onDeleteUser,
  handleChangePage,
  page,
  count,
  term,
  setTerm,
}) => {
  const styles = useStyles();
  const history = useHistory();

  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [curUserId, setCurUserId] = useState();
  const [rowsPerPage] = useState(20);

  const onOpenEditUserModal = (bool, id) => {
    setCurUserId(id);
    setEditModal(bool);
  };

  const onCloseBlockNotify = () => {
    changeUserBlockMessage('');
  };

  const onPushToUserStats = (el) => {
    history.push(`/statistics/${el.id}`);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Имя</TableCell>
              <TableCell align='center'>Фамилия</TableCell>
              <TableCell align='center'>Номер телефона</TableCell>
              <TableCell align='center'>Регистрация</TableCell>
              <TableCell align='center'>Карты лояльности</TableCell>
              <TableCell align='center'>Популярный продукт</TableCell>
              <TableCell align='right'>
                <SearchInput term={term} setTerm={setTerm} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length && !loading ? (
              (rowsPerPage > 0 && list)
                .filter((el) => el.archived !== 1)
                .map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell padding='default' align='center'>
                      {el.first_name}
                    </TableCell>
                    <TableCell padding='default' align='center'>
                      {el.second_name}
                    </TableCell>
                    <TableCell padding='default' align='center'>
                      {el.phone}
                    </TableCell>
                    <TableCell padding='default' align='center'>
                      {el?.created_at
                        ? el.created_at.slice(0, 10).replaceAll('-', '.')
                        : ''}
                    </TableCell>
                    <TableCell align='center'>
                      {el.card_list?.length ? el.card_list.length + ' шт.' : ''}
                    </TableCell>
                    <TableCell align='center'>{el.top_product?.name}</TableCell>
                    <TableCell
                      style={{ paddingLeft: 2 }}
                      padding='default'
                      align='center'
                    >
                      <Grid container direction='row' justify='space-around'>
                        <Tooltip title='Статистика'>
                          <Box>
                            <EqualizerIcon
                              className={styles.closeIcon}
                              onClick={() => onPushToUserStats(el)}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip title='Редактировать'>
                          <Box>
                            <EditIcon
                              onClick={() => onOpenEditUserModal(true, el.id)}
                              className={styles.editIcon}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip title='Удалить'>
                          <Box>
                            <CloseIcon
                              onClick={() => {
                                setCurUserId(el.id);
                                setConfirmModal(true);
                              }}
                              className={styles.closeIcon}
                            />
                          </Box>
                        </Tooltip>
                        <Tooltip title='Заблокировать'>
                          <Box>
                            <BlockIcon
                              onClick={() =>
                                el.active === 1
                                  ? onBlockUser({ active: 0, id: el.id })
                                  : onBlockUser({ active: 1, id: el.id })
                              }
                              className={
                                el.active === 1
                                  ? styles.blackColor
                                  : styles.redColor
                              }
                            />
                          </Box>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
            ) : loading && list?.length ? (
              <UserTableCellSpinner />
            ) : loading && !list?.length ? (
              <UserTableCellSpinner />
            ) : null}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={10}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={() => (
                  <TablePaginationActions
                    colSpan={4}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={null}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!list?.length && !loading && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список пользователей пустой
        </Box>
      )}
      <EditUserModal
        open={editModal}
        setOpen={setEditModal}
        userId={curUserId}
        headerLabel='Редактирование пользователя'
      />
      <AddUserModal
        headerLabel='Создание пользователя'
        open={addModal}
        setOpen={setAddModal}
      />
      <ConfirmDeleteModal
        id={curUserId}
        open={confirmModal}
        setOpen={setConfirmModal}
        actionFn={onDeleteUser}
      />

      <Notify
        isOpen={!!userBlockMessage}
        text={userBlockMessage}
        closeFn={onCloseBlockNotify}
        status={'success'}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
    position: 'relative',
  },
  tableItem: {},
  editIcon: {
    cursor: 'pointer',
    fontSize: 20,
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 20,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  blackColor: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
    color: 'black',
  },
  redColor: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
    color: 'red',
  },
}));

const mapStateToProps = ({ users: { userBlockMessage } }) => {
  return {
    userBlockMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteUser: (id) => dispatch(onDeleteUser(id)),
    onBlockUser: (data) => dispatch(onBlockUser(data)),
    changeUserBlockMessage: (text) => dispatch(changeUserBlockMessage(text)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
