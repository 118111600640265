import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Drawer, Divider, List } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import CategoryIcon from '@material-ui/icons/Category';
import GroupIcon from '@material-ui/icons/Group';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import StoreIcon from '@material-ui/icons/Store';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MoreIcon from '@material-ui/icons/More';
import SettingsIcon from '@material-ui/icons/Settings';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QuestionIcon from '@material-ui/icons/Help';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';

import propStyle from '../../resourses/propStyles';
import LeftSidebarLink from '../LeftSidebarLink';

const drawerWidth = 240;

const LeftSidebar = () => {
  const styles = useStyles();

  return (
    <Drawer
      className={styles.drawer}
      variant='permanent'
      classes={{
        paper: styles.drawerPaper,
      }}
      anchor='left'
    >
      <Grid
        container
        justify='center'
        alignItems='center'
        className={styles.toolbar}
      >
        Чешский Лев
      </Grid>
      <Divider />
      <List>
        <LeftSidebarLink
          link='/'
          desc='Покупки'
          icon={<ReceiptIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/products'
          desc='Товары'
          icon={<ListIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/categories'
          desc='Категории'
          icon={<CategoryIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/users'
          desc='Пользователи'
          icon={<GroupIcon className={styles.menuIcon} />}
        />
      </List>
      <Divider />
      <List>
        <LeftSidebarLink
          link='/loyalties'
          desc='Лояльность'
          icon={<LoyaltyIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/news'
          desc='Новости'
          icon={<AnnouncementIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/promos'
          desc='Акции'
          icon={<LocalActivityIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/coupons'
          desc='Купоны'
          icon={<ConfirmationNumberIcon className={styles.menuIcon} />}
        />
      </List>
      <Divider />
      <List>
        <LeftSidebarLink
          link='/shops'
          desc='Магазины'
          icon={<StoreIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/reviews'
          desc='Отзывы'
          icon={<QuestionAnswerIcon className={styles.menuIcon} />}
        />
        <LeftSidebarLink
          link='/fields'
          desc='Доп. поля'
          icon={<MoreIcon className={styles.menuIcon} />}
        />

        <LeftSidebarLink
          link='/cards'
          desc='Карты'
          icon={<CreditCardIcon className={styles.menuIcon} />}
        />
        <Divider />
        <List>
          {/* <LeftSidebarLink
            link={'/migrations'}
            desc={'Миграции покупок'}
            icon={<DeveloperBoard className={styles.menuIcon} />}
          /> */}
          <LeftSidebarLink
            link='/filters'
            desc='Фильтрация по пользователям'
            icon={<DeveloperBoard className={styles.menuIcon} />}
          />
          <LeftSidebarLink
            link='/bonusesRules'
            desc='Акционные бонусы'
            icon={<CalendarTodayIcon className={styles.menuIcon} />}
          />
          <LeftSidebarLink
            link='/questions'
            desc='Опросы'
            icon={<QuestionIcon className={styles.menuIcon} />}
          />
          <LeftSidebarLink
            link='/settings'
            desc='Настройки'
            icon={<SettingsIcon className={styles.menuIcon} />}
          />
        </List>
      </List>
    </Drawer>
  );
};

const useStyles = makeStyles(() => ({
  drawer: {
    display: 'flex',
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: propStyle.orangeColor,
    color: '#fff',
  },
  toolbar: {
    padding: 20,
    fontSize: 24,
  },
  content: {
    flexGrow: 1,
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  activeListItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  menuIcon: {
    color: '#fff',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
}));

export default LeftSidebar;
