import React from 'react';

import { TableCell, Box, TableRow } from '@material-ui/core';
import Spinner from '../Spinner';

const UserTableCellSpinner = () => {
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell align='center'>
        <Box>
          <Spinner />
        </Box>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default UserTableCellSpinner;
