import { useState, useEffect } from 'react';
import {
  onGetPushPurchaseProductSettingsReq,
  onEditPurchaseProductSettingsReq,
} from '../../../api/settings';

export const usePurchasePushText = () => {
  const [purchasePushEdit, setPurchasePushEdit] = useState(false);
  const [startData, setStartData] = useState({});
  const [purchasePushTexts, setPurchasePushTexts] = useState({});

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await onGetPushPurchaseProductSettingsReq();

      setPurchasePushTexts(data);
      setStartData(data);
    })();
  }, []);

  useEffect(() => {
    if (!purchasePushEdit) {
      setPurchasePushTexts(startData);
    }
  }, [purchasePushEdit, startData]);

  const onEditPurchaseProductSet = async () => {
    const title = purchasePushTexts.title;
    const body = purchasePushTexts.body;

    if (title && body) {
      try {
        await onEditPurchaseProductSettingsReq({
          title,
          body,
        });

        setStartData(purchasePushTexts);
      } catch {
        setPurchasePushTexts(startData);
      } finally {
        setPurchasePushEdit(false);
      }
    }
  };

  return {
    purchasePushTexts,
    setPurchasePushTexts,
    purchasePushEdit,
    setPurchasePushEdit,
    onEditPurchaseProductSet,
  };
};
