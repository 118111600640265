import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import { onGetShopsList, onDeleteShop } from '../../../store/actions/shops';
import EditShopModal from '../../Modals/EditShopModal';
import PushNearShopModal from '../../Modals/PushNearShopModal/PushNearShopModal';

const ShopsTable = ({
  shopsList,
  loadingShopsList,
  onGetShopsList,
  onDeleteShop,
}) => {
  const styles = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPushDialog, setOpenPushDialog] = useState(false);
  const [shopId, setShopId] = useState();
  const [shop, setShop] = useState({});

  useEffect(() => {
    onGetShopsList();
  }, [onGetShopsList]);

  if (loadingShopsList) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onOpenDeleteModal = (id) => {
    setShopId(id);
    setOpenDeleteDialog(true);
  };

  const onOpenEditModal = (el) => {
    setShop(el);
    setOpenEditDialog(true);
  };

  const onOpenPushModal = (id) => {
    setShopId(id);
    setOpenPushDialog(true);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Код магазина</TableCell>
              <TableCell align='center'>Название</TableCell>
              <TableCell align='center'>Город</TableCell>
              <TableCell align='center'>Адрес</TableCell>
              <TableCell align='center'>Телефон</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopsList.length
              ? shopsList.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>{el.id}</TableCell>
                    <TableCell align='center'>{el.name}</TableCell>
                    <TableCell align='center'>{el.city_name}</TableCell>
                    <TableCell align='center'>
                      {`${el.street_name ? el.street_name : ''}${
                        el.street_name && el.house_name
                          ? ', ' + el.house_name
                          : ''
                      }`}
                    </TableCell>
                    <TableCell align='center'>{el.phone}</TableCell>
                    <TableCell align='center'>
                      <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        wrap='nowrap'
                      >
                        <Box mr={3}>
                          <Tooltip title='Редактировать'>
                            <EditIcon
                              onClick={() => onOpenEditModal(el)}
                              className={styles.editIcon}
                            />
                          </Tooltip>
                        </Box>
                        <Box mr={3}>
                          <Tooltip title='Пуш-уведомление вокруг магазина'>
                            <MailOutlineIcon
                              className={styles.closeIcon}
                              onClick={() => onOpenPushModal(el.id)}
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title='Удалить'>
                            <CloseIcon
                              onClick={() => onOpenDeleteModal(el.id)}
                              className={styles.closeIcon}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {!shopsList.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список магазинов пустой
        </Box>
      )}

      <EditShopModal
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        shopData={shop}
      />
      <PushNearShopModal
        open={openPushDialog}
        setOpen={setOpenPushDialog}
        id={shopId}
      />
      <ConfirmDeleteModal
        id={shopId}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        actionFn={onDeleteShop}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapStateToProps = ({ shops: { shopsList, loadingShopsList } }) => {
  return {
    shopsList,
    loadingShopsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetShopsList: () => dispatch(onGetShopsList()),
    onDeleteShop: (id) => dispatch(onDeleteShop(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopsTable);
