import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import { onGetCoupons, onDeleteCoupon } from '../../../store/actions/coupons';

const CouponsTable = ({
  couponsList,
  loadingCouponsList,
  onGetCoupons,
  onDeleteCoupon,
}) => {
  const styles = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [couponId, setCouponId] = useState();

  useEffect(() => {
    onGetCoupons();
  }, [onGetCoupons]);

  if (loadingCouponsList) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const onOpenDeleteModal = (id) => {
    setCouponId(id);
    setOpenDeleteDialog(true);
  };

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Товар</TableCell>
              <TableCell align='center'>Кол-во единиц</TableCell>
              <TableCell align='center'>Номер</TableCell>
              <TableCell align='center'>Имя</TableCell>
              <TableCell align='center'>Фамилия</TableCell>
              <TableCell align='center'>Дата окончания</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {couponsList.length
              ? couponsList.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>{el.product_name}</TableCell>
                    <TableCell align='center'>{el.count} шт.</TableCell>
                    <TableCell align='center'>{el.phone}</TableCell>
                    <TableCell align='center'>{el.first_name}</TableCell>
                    <TableCell align='center'>{el.second_name}</TableCell>
                    <TableCell align='center'>{el.date_end}</TableCell>
                    <TableCell align='center'>
                      <Grid container direction='row' justify='space-around'>
                        <Tooltip title='Редактировать'>
                          <CloseIcon
                            onClick={() => onOpenDeleteModal(el.id)}
                            className={styles.closeIcon}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {!couponsList.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список купонов пустой
        </Box>
      )}
      <ConfirmDeleteModal
        id={couponId}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        actionFn={onDeleteCoupon}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapStateToProps = ({ coupons: { couponsList, loadingCouponsList } }) => {
  return {
    couponsList,
    loadingCouponsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCoupons: () => dispatch(onGetCoupons()),
    onDeleteCoupon: (id) => dispatch(onDeleteCoupon(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponsTable);
