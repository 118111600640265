import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';
import ActionNewsModal from '../ActionNewsModal';

import { onAddNews } from '../../../store/actions/news';

const AddNewsModal = ({ open, setOpen, onAddNews, headerLabel }) => {
  const [isAddModal] = useState(true);

  const onSubmit = (news, img, showQuestionnaire) => {
    const data = { ...news };

    data.file_content = img;
    if (showQuestionnaire) {
      data.question_bonus_duration = +data.question_bonus_duration;
      data.question_bonus_value = +data.question_bonus_value;
    }

    onAddNews(data, showQuestionnaire);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionNewsModal
        headerLabel={headerLabel}
        setOpen={setOpen}
        newsData={null}
        onSubmit={onSubmit}
        isAddModal={isAddModal}
      />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddNews: (data, show) => dispatch(onAddNews(data, show)),
  };
};

export default connect(null, mapDispatchToProps)(AddNewsModal);
