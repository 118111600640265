import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid, Box, Button, Dialog, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Notify from '../../Notify';

import InputMask from 'react-input-mask';

import {
  onCreateUserCard,
  onAttachUserCard,
  onErrorCreateCard,
  onSuccessCreateCard,
} from '../../../store/actions/users';

const UserCardsModal = ({
  open,
  setOpen,
  userId,
  onCreateUserCard,
  onAttachUserCard,
  isCreateCardError,
  isCreateCardSuccess,
  onErrorCreateCard,
  isAttachCard,
  isCreateCardErrorMessage,
}) => {
  const styles = useStyles();
  const [number, setNumber] = useState('');

  const onAddNewCard = (e) => {
    e.preventDefault();
    if (number.length > 1) {
      isAttachCard
        ? onAttachUserCard({ user_id: userId, number })
        : onCreateUserCard({ user_id: userId, number });

      setNumber('');
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box className={styles.modal}>
        <HeaderForModal setOpen={onClose} headerLabel='Карты пользователя' />

        <form onSubmit={onAddNewCard}>
          <Grid container direction='row' justify='space-between'>
            <Grid item xs={8}>
              <InputMask
                className={styles.input}
                mask='*9999999'
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                placeholder='Новая карта'
                required
                maskChar={''}
                formatChars={{
                  9: '[0-9]',
                  '*': '[Zz0-9]',
                }}
              >
                {(inputProps) => (
                  <Input
                    variant='outlined'
                    label='Новая карта'
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='center'
                style={{ height: '100%' }}
              >
                <Button
                  type='submit'
                  className={styles.submitButton}
                  variant='contained'
                >
                  Добавить карту
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box width='100%' mt={4}>
            <Grid container direction='row' justify='center'>
              <Box>
                <Button onClick={onClose} variant='contained'>
                  Отмена
                </Button>
              </Box>
            </Grid>
          </Box>
        </form>
      </Box>
      <Notify
        status={'error'}
        text={isCreateCardErrorMessage}
        isOpen={isCreateCardError}
        closeFn={onErrorCreateCard}
      />
      <Notify
        status={'success'}
        text={'Вы добавили новую карту'}
        isOpen={isCreateCardSuccess}
        closeFn={onSuccessCreateCard}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },

  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    color: 'grey',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  users: { isCreateCardError, isCreateCardSuccess, isCreateCardErrorMessage },
}) => {
  return {
    isCreateCardError,
    isCreateCardSuccess,
    isCreateCardErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateUserCard: (data) => dispatch(onCreateUserCard(data)),
    onAttachUserCard: (data) => dispatch(onAttachUserCard(data)),
    onErrorCreateCard: (bool) => dispatch(onErrorCreateCard(bool)),
    onSuccessCreateCard: (bool) => dispatch(onSuccessCreateCard(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCardsModal);
