import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';

import Header from '../../components/Header';

import { onGetCardsList } from '../../store/actions/cards';
import CardsTable from '../../components/Tables/CardsTable';

const CardsPage = ({ onGetCardsList, cardsList, loadingCardsList }) => {
  const [page, setPage] = useState(0);
  const [term, setTerm] = useState('');

  useEffect(() => {
    if (term) {
      onGetCardsList(1, term);
    } else {
      page === 0 ? onGetCardsList(1) : onGetCardsList(+page * 20);
    }
  }, [onGetCardsList, page, term]);

  return (
    <Box pb={2}>
      <Header label={'Карты'} />

      <CardsTable
        term={term}
        setTerm={setTerm}
        list={cardsList}
        loading={loadingCardsList}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

const mapStateToProps = ({ cards: { cardsList, loadingCardsList } }) => ({
  cardsList,
  loadingCardsList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCardsList: (limit, term) => dispatch(onGetCardsList(limit, term)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage);
