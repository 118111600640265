import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import propStyle from '../../../resourses/propStyles';

import Spinner from '../../Spinner';
import EditProductModal from '../../Modals/EditProductModal';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';
import { onChangeProductsPositionReq } from '../../../api/products';

import { onDeleteProduct } from '../../../store/actions/products';
import SearchInput from '../../SearchInput';
// DND
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ProductsTable = ({ products, loading, onDeleteProduct, setPage }) => {
  const styles = useStyles();

  const [productData, setProductData] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [catId, setCatId] = useState();
  const [term, setTerm] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (products.length) {
      setData(products);
    }
  }, [products]);

  const onSearchProduct = useCallback(() => {
    if (!term) {
      setData(products);
      return;
    }

    const list = products.filter(
      (el) =>
        el.name?.toLowerCase().includes(term.toLowerCase()) ||
        el.code?.includes(term)
    );

    setData(list);
  }, [term, products]);

  useEffect(() => {
    onSearchProduct();
  }, [onSearchProduct]);

  if (loading) {
    return (
      <Box my={5}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  const onOpenEditModal = (el) => {
    setProductData(el);
    setCatId(el.id);
    setOpenEditDialog(true);
  };

  const onOpenDeleteModal = (id) => {
    setCatId(id);
    setOpenDeleteDialog(true);
  };

  // DND
  const reorderDndProducts = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorderDndProducts(
      data,
      result.source.index,
      result.destination.index
    );
    const sortIdxs = items.map((el) => el.id);

    setData(items);
    await onChangeProductsPositionReq(sortIdxs);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? propStyle.shadowedColor : '#fff',
    ...draggableStyle,
  });

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Название</TableCell>
              <TableCell align='center'>Описание</TableCell>
              <TableCell align='center'>Код</TableCell>
              <TableCell align='center'>Цена</TableCell>
              <TableCell align='right'>
                <SearchInput term={term} setTerm={setTerm} />
              </TableCell>
            </TableRow>
          </TableHead>
          {/* // */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={String(item.id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className={styles.tableItem}
                          >
                            <TableCell padding='default' align='center'>
                              {item.name}
                            </TableCell>
                            <TableCell padding='default' align='center'>
                              {item.description}
                            </TableCell>
                            <TableCell padding='default' align='center'>
                              {item.code}
                            </TableCell>
                            <TableCell padding='default' align='center'>
                              {item.price}₽
                            </TableCell>
                            <TableCell
                              style={{ width: 140 }}
                              padding={'default'}
                              align='right'
                            >
                              <Grid
                                container
                                direction='row'
                                justify='space-evenly'
                              >
                                <Tooltip title='Редактировать'>
                                  <Box>
                                    <EditIcon
                                      onClick={() => onOpenEditModal(item)}
                                      className={styles.editIcon}
                                    />
                                  </Box>
                                </Tooltip>
                                <Tooltip title='Удалить'>
                                  <Box>
                                    <CloseIcon
                                      onClick={() => onOpenDeleteModal(item.id)}
                                      className={styles.closeIcon}
                                    />
                                  </Box>
                                </Tooltip>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>

      <EditProductModal
        productData={productData}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        catId={catId}
      />
      <ConfirmDeleteModal
        id={catId}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        actionFn={onDeleteProduct}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    width: '100%',
  },
  tableItem: {},
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteProduct: (id) => dispatch(onDeleteProduct(id)),
  };
};

export default connect(null, mapDispatchToProps)(ProductsTable);
