import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Dialog,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Notify from '../../Notify';

import {
  onCloseCategoryActionMessages,
  onGetCategoriesForSelect,
  onEditSubCategory,
} from '../../../store/actions/categories';

const EditSubCategoryModal = ({
  open,
  setOpen,
  categoriesForSelect,
  onGetCategoriesForSelect,
  onCloseCategoryActionMessages,
  onEditSubCategory,
  isCategoryActionSuccess,
  isCategoryActionError,
  subCategory,
}) => {
  const styles = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    setData(subCategory);
  }, [subCategory]);

  useEffect(() => {
    onGetCategoriesForSelect();
  }, [onGetCategoriesForSelect]);

  const onHandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    onEditSubCategory(data);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box className={styles.modal}>
        <HeaderForModal
          setOpen={setOpen}
          headerLabel='Редактирование подкатегории'
        />
        <Grid container direction='row' alignItems='center'>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Название
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={onHandleChange}
                value={data.name}
                name='name'
                className={styles.input}
                label='Название'
                variant='outlined'
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.inputLabel} item xs={4}>
              Категория
            </Grid>
            <Grid item xs={8}>
              <FormControl className={styles.formControl} variant='outlined'>
                <InputLabel>Категория</InputLabel>
                <Select
                  onChange={onHandleChange}
                  className={styles.input}
                  value={data.parent_id}
                  name='parent_id'
                  label='Категория'
                >
                  {categoriesForSelect.length &&
                    categoriesForSelect.map((el) => (
                      <MenuItem
                        key={el.parentCategoryId}
                        value={el.parentCategoryId}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Box mr={3}>
              <Button
                onClick={onSubmit}
                className={styles.addButton}
                variant='contained'
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={() => setOpen(false)} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='success'
        text='Категория успешно отредактирована!'
        isOpen={isCategoryActionSuccess}
      />
      <Notify
        closeFn={onCloseCategoryActionMessages}
        status='error'
        text='Ошибка при редактировании категории!'
        isOpen={isCategoryActionError}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },

  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  formControl: {
    width: '100%',
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
}));

const mapStateToProps = ({
  categories: {
    categoriesForSelect,
    isCategoryActionSuccess,
    isCategoryActionError,
  },
}) => {
  return {
    categoriesForSelect,
    isCategoryActionSuccess,
    isCategoryActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCategoriesForSelect: () => dispatch(onGetCategoriesForSelect()),
    onCloseCategoryActionMessages: () =>
      dispatch(onCloseCategoryActionMessages()),
    onEditSubCategory: (data) => dispatch(onEditSubCategory(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubCategoryModal);
