import { combineReducers } from 'redux';

import authReducer from './authReducer';
import productsReducer from './productsReducer';
import ordersReducer from './ordersReducer';
import usersReducer from './usersReducer';
import categoriesReducer from './categoriesReducer';
import newsReducer from './newsReducer';
import promosReducer from './promosReducer';
import loyaltyReducer from './loyaltyReducer';
import couponsReducer from './couponsReducer';
import shopsReducer from './shopsReducer';
import commentsReducer from './commentsReducer';
import fieldsReducer from './fieldsReducer';
import statsReducer from './statsReducer';
import settingsReducer from './settingsReducer';
import cardsReducer from './cardsReducer';
import bonusesRulesReducer from './bonusesRulesReducer';
import notifyReducer from './notifyReducer';

export default combineReducers({
  auth: authReducer,
  products: productsReducer,
  orders: ordersReducer,
  users: usersReducer,
  categories: categoriesReducer,
  news: newsReducer,
  promos: promosReducer,
  loyalty: loyaltyReducer,
  coupons: couponsReducer,
  shops: shopsReducer,
  comments: commentsReducer,
  fields: fieldsReducer,
  stats: statsReducer,
  settings: settingsReducer,
  cards: cardsReducer,
  bonusesRules: bonusesRulesReducer,
  notify: notifyReducer,
});
