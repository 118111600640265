import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';

import ActionLoyalyModal from '../ActionLoyaltyModal';

import {
  onCloseActionLoyaltyMessage,
  onAddLoyalty,
} from '../../../store/actions/loyalty';

const AddLoyaltyModal = ({
  open,
  setOpen,
  isLoyaltyActionSuccess,
  isLoyaltyActionError,
  onAddLoyalty,
  onCloseActionLoyaltyMessage,
}) => {
  const onSubmit = (loyalty) => {
    onAddLoyalty(loyalty);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionLoyalyModal
        setOpen={setOpen}
        loyaltyData={null}
        onSubmit={onSubmit}
        headerLabel='Добавление программы лояльности'
      />
      <Notify
        closeFn={onCloseActionLoyaltyMessage}
        status='success'
        text='Программа лояльности успешно создана!'
        isOpen={isLoyaltyActionSuccess}
      />
      <Notify
        closeFn={onCloseActionLoyaltyMessage}
        status='error'
        text='Ошибка при создании программы лояльности!'
        isOpen={isLoyaltyActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  loyalty: { isLoyaltyActionSuccess, isLoyaltyActionError },
}) => {
  return { isLoyaltyActionSuccess, isLoyaltyActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseActionLoyaltyMessage: () => dispatch(onCloseActionLoyaltyMessage()),
    onAddLoyalty: (data) => dispatch(onAddLoyalty(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLoyaltyModal);
