import React, { useState } from 'react';

import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../resourses/propStyles';

import Header from '../../components/Header';
import MoreFieldsTable from '../../components/Tables/MoreFieldsTable';
import AddFieldModal from '../../components/Modals/AddFieldModal';

const MoreFieldsPage = () => {
  const styles = useStyles();
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <Box>
      <Header label={'Дополнительные поля пользователей'} />
      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Button
          onClick={() => setShowAddModal(true)}
          className={styles.addButton}
          variant='contained'
        >
          Добавить
        </Button>
      </Grid>
      <MoreFieldsTable />
      <AddFieldModal open={showAddModal} setOpen={setShowAddModal} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

export default MoreFieldsPage;
