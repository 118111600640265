import * as constant from '../constants';

const initialState = {
  fieldsList: [],
  loadingFieldsList: true,
  isFieldsActionSuccess: false,
  isFieldsActionError: false,
};

const fieldsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_FIELDS_LIST:
      return {
        ...state,
        fieldsList: payload,
      };
    case constant.IS_LOADING_FIELDS_LIST:
      return {
        ...state,
        loadingFieldsList: payload,
      };
    case constant.IS_FIELDS_ACTION_SUCCESS:
      return {
        ...state,
        isFieldsActionSuccess: true,
      };
    case constant.IS_FIELDS_ACTION_ERROR:
      return {
        ...state,
        isFieldsActionError: true,
      };
    case constant.ON_CLOSE_ACTION_FIELDS_MESSAGE:
      return {
        ...state,
        isFieldsActionSuccess: false,
        isFieldsActionError: false,
      };
    default:
      return state;
  }
};

export default fieldsReducer;
