import * as constant from '../constants';

const initialState = {
  pushPurchaseProductSettings: {},
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_PUSH_PURCHASE_PRODUCT_SETTINGS:
      return {
        ...state,
        pushPurchaseProductSettings: payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
