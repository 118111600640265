import React from 'react';
import { connect } from 'react-redux';

import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';

const CategoriesList = ({ categoriesList, category, setCategory }) => {
  const styles = useStyles();

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  const selectRenderValue = (selected) => selected.name;

  return (
    <FormControl className={styles.formControl} variant='outlined'>
      <InputLabel>Выберите категорию</InputLabel>
      <Select
        value={category}
        onChange={handleChange}
        renderValue={selectRenderValue}
      >
        {categoriesList.map((categoryItem) => (
          <MenuItem key={categoryItem.id} value={categoryItem}>
            <ListItemText primary={categoryItem.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
  },
}));

const mapState = ({ categories: { categoriesList } }) => ({
  categoriesList,
});

export default connect(mapState)(CategoriesList);
