import React from 'react';

import { Box } from '@material-ui/core';

import SettingsItem from './SettingsItem';
import SettingsItemInput from './SettingsItemInput';
import { usePurchasePushText } from './usePurchasePush';
import { useAuthTexts } from './useAuthTexts';

const SettingsTable = () => {
  const {
    purchasePushTexts,
    setPurchasePushTexts,
    purchasePushEdit,
    setPurchasePushEdit,
    onEditPurchaseProductSet,
  } = usePurchasePushText();

  const {
    authTexts,
    setAuthTexts,
    authTextsEdit,
    setAuthTextsEdit,
    editAuthTexts,
  } = useAuthTexts();

  return (
    <Box mt={3} mx={2}>
      <SettingsItem
        settingsName='Уведомление при покупке'
        firstTitle='Заголовок'
        firstTitleField={
          <SettingsItemInput
            value={purchasePushTexts?.title}
            setValue={(e) =>
              setPurchasePushTexts({
                ...purchasePushTexts,
                title: e.target.value,
              })
            }
            disabled={!purchasePushEdit}
          />
        }
        secondTitle='Текст'
        secondTitleField={
          <SettingsItemInput
            value={purchasePushTexts?.body}
            setValue={(e) =>
              setPurchasePushTexts({
                ...purchasePushTexts,
                body: e.target.value,
              })
            }
            disabled={!purchasePushEdit}
          />
        }
        setFieldsEdit={setPurchasePushEdit}
        submitEdit={onEditPurchaseProductSet}
        isFieldsEdit={purchasePushEdit}
      />
      {/*  */}
      <SettingsItem
        settingsName='Текста авторизации'
        firstTitle='Текст при отправке номера'
        firstTitleField={
          <SettingsItemInput
            value={authTexts?.popup_text}
            setValue={(e) =>
              setAuthTexts({
                ...authTexts,
                popup_text: e.target.value,
              })
            }
            disabled={!authTextsEdit}
          />
        }
        secondTitle='Текст поля ввода кода(placeholder)'
        secondTitleField={
          <SettingsItemInput
            value={authTexts?.placeholder_text}
            setValue={(e) =>
              setAuthTexts({
                ...authTexts,
                placeholder_text: e.target.value,
              })
            }
            disabled={!authTextsEdit}
          />
        }
        setFieldsEdit={setAuthTextsEdit}
        isFieldsEdit={authTextsEdit}
        submitEdit={editAuthTexts}
      />
    </Box>
  );
};

export default SettingsTable;
