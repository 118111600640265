import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Popover, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import propStyle from '../../resourses/propStyles';

import { onLogoutUser } from '../../store/actions/auth';

const Header = ({ label, onLogoutUser }) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction='row'
      justify='space-between'
      alignItems='center'
      className={styles.header}
    >
      <Box className={styles.text}>{label}</Box>
      <Box>
        <AccountCircleIcon
          onClick={handleClick}
          fontSize='large'
          className={styles.icon}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box px={3} py={1} borderRadius={8}>
            <Button onClick={onLogoutUser} color='secondary'>
              Выйти
            </Button>
          </Box>
        </Popover>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    padding: 20,
  },
  icon: {
    color: 'grey',
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  text: {
    fontSize: 24,
    fontWeight: '500',
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutUser: () => dispatch(onLogoutUser()),
  };
};

export default connect(null, mapDispatchToProps)(Header);
