import React from 'react';

import { Box, Tooltip, Grid } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

const SettingsItem = ({
  settingsName,
  firstTitle,
  firstTitleField,
  secondTitle,
  secondTitleField,
  isFieldsEdit,
  setFieldsEdit,
  submitEdit,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.container} mb={2} borderRadius={8}>
      <Box>
        <Box fontSize={24} fontWeight={500} mb={2}>
          {settingsName}
        </Box>
        <Grid container>
          <Grid item xs={5}>
            <Box fontSize={20} fontWeight={500} mb={1}>
              {firstTitle}
            </Box>
            {firstTitleField}
          </Grid>
          <Grid item xs={5}>
            <Box fontSize={20} fontWeight={500} mb={1}>
              {secondTitle}
            </Box>
            {secondTitleField}
          </Grid>
          <Grid item xs={2} className={styles.editIconsContainer}>
            {isFieldsEdit ? (
              <>
                <Tooltip style={{ marginRight: 30 }} title='Сохранить'>
                  <DoneIcon onClick={submitEdit} className={styles.doneIcon} />
                </Tooltip>
                <Tooltip title='Отмена'>
                  <CloseIcon
                    onClick={() => setFieldsEdit(false)}
                    className={styles.closeIcon}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title='Редактировать'>
                <EditIcon
                  onClick={() => setFieldsEdit(true)}
                  className={styles.editIcon}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    background: propStyle.whiteColor,
    padding: 14,
  },
  editIconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  editIcon: {
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  doneIcon: {
    cursor: 'pointer',
    color: propStyle.successColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    color: propStyle.shadowColor,
  },
}));

export default SettingsItem;
