import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';
import ActionShopsModal from '../ActionShopsModal/ActionShopsModal';

import {
  onAddShop,
  onCloseActionShopsMessage,
} from '../../../store/actions/shops';

const AddShopsModal = ({
  open,
  setOpen,
  isShopsActionSuccess,
  isShopsActionError,
  onAddShop,
  onCloseActionShopsMessage,
}) => {
  const onSubmit = (shop) => {
    onAddShop(shop);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionShopsModal
        setOpen={setOpen}
        loyaltyData={null}
        onSubmit={onSubmit}
        headerLabel='Добавление магазина'
        shopData={null}
      />
      <Notify
        closeFn={onCloseActionShopsMessage}
        status='success'
        text='Магазин успешно создан!'
        isOpen={isShopsActionSuccess}
      />
      <Notify
        closeFn={onCloseActionShopsMessage}
        status='error'
        text='Ошибка при создании магазина!'
        isOpen={isShopsActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  shops: { isShopsActionSuccess, isShopsActionError },
}) => {
  return { isShopsActionSuccess, isShopsActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseActionShopsMessage: () => dispatch(onCloseActionShopsMessage()),
    onAddShop: (data) => dispatch(onAddShop(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShopsModal);
