import React, { useState } from 'react';

import {
  Dialog,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import HeaderForModal from '../../../../../components/Modals/HeaderForModal';

import { useCreateNotify } from '../../../../../hooks/useCreateNotify';
import { getUsersSalesByCategoriesReq } from '../../../../../api/migrations';
import FiltersSearchButton from '../../FiltersSearchButton';
import MigrationsPicker from '../../../../../components/MigrationsPicker';
import CategoriesList from '../../CategoriesList';

const UsersSalesByCategoriesModal = ({
  isShowModal,
  setUsers,
  loading,
  setLoading,
  closeModal,
}) => {
  const { createNotify } = useCreateNotify();

  const [period, setPeriod] = useState({ start: '', end: '' });
  const [isNovelty, setNovelty] = useState(false);
  const [isStock, setStock] = useState(false);
  const [category, setCategory] = useState({});

  const isPeriodValid = (period) => Object.values(period).every((el) => !!el);

  const isValidForm = isPeriodValid(period);

  const paramsData = {
    date_begin: period.start,
    date_end: period.end,
    is_novelty: +isNovelty,
    is_stock: +isStock,
    category_id: category?.id,
  };

  const getUsersByFilters = async () => {
    if (!isValidForm) return createErrorNotify();

    setLoading(true);

    try {
      const {
        data: { data },
      } = await getUsersSalesByCategoriesReq(paramsData);
      setUsers(data.map((user) => ({ ...user, checked: false })));
    } finally {
      setLoading(false);
    }
  };

  const createErrorNotify = () => {
    createNotify({
      notifyStatus: 'error',
      notifyText: 'Заполните корректно все поля, чтобы продолжить!',
      isShowNotify: true,
    });
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box p={2}>
        <HeaderForModal setOpen={closeModal} headerLabel='Заполните все поля' />
        <Box>
          <MigrationsPicker
            label='Первый период'
            period={period}
            setPeriod={setPeriod}
          />
        </Box>
        <Grid container>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNovelty}
                  onChange={(e) => setNovelty(e.target.checked)}
                />
              }
              label='Новинка'
              labelPlacement='start'
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isStock}
                  onChange={(e) => setStock(e.target.checked)}
                />
              }
              label='Акция'
              labelPlacement='start'
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <CategoriesList category={category} setCategory={setCategory} />
        </Box>

        <Grid container justify='flex-end'>
          <FiltersSearchButton
            title='Поиск'
            disabled={loading}
            submit={getUsersByFilters}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default UsersSalesByCategoriesModal;
