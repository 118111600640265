import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../resourses/propStyles';

import Header from '../../components/Header';
import AddProductModal from '../../components/Modals/AddProductModal';
import ProductsTable from '../../components/Tables/ProductsTable';

import Select from 'react-select';

import {
  onGetCategoriesForProductsPage,
  onGetProductsList,
} from '../../store/actions/products';

const ProductsPage = ({
  onGetCategoriesForProductsPage,
  categoriesForProductsPage,
  onGetProductsList,
  productsList,
  loadingProductsList,
}) => {
  const styles = useStyles();
  const [addModal, setAddModal] = useState(false);
  const [tab, setTab] = useState(0);
  const [id, setId] = useState();
  const [page, setPage] = useState(0);

  useEffect(() => {
    onGetCategoriesForProductsPage();
  }, [onGetCategoriesForProductsPage]);

  useEffect(() => {
    if (categoriesForProductsPage.length) {
      setId(categoriesForProductsPage[0].id);
      setTab({
        label: categoriesForProductsPage[0].name,
        value: categoriesForProductsPage[0].id,
      });
    }
  }, [categoriesForProductsPage]);

  useEffect(() => {
    if (id) {
      onGetProductsList(id);
    }
  }, [id, onGetProductsList]);

  return (
    <Box className={styles.container}>
      <Header label={'Товары'} />
      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Button
          onClick={() => setAddModal(true)}
          className={styles.addButton}
          variant='contained'
        >
          Добавить
        </Button>
      </Grid>
      <Box px={2}>
        <Box mt={3}>
          <Select
            styles={propStyle.selectStyle}
            placeholder='Категория'
            value={tab}
            isSearchable
            onChange={(selectedOption) => {
              setPage(0);
              setTab(selectedOption);
              setId(selectedOption.value);
            }}
            options={[
              ...categoriesForProductsPage.map((el) => ({
                value: el.id,
                label: el.name,
              })),
              { value: -1, label: 'Товары без категории' },
              { value: 1, label: 'Не распределенные' },
            ]}
          />
        </Box>
        <ProductsTable
          page={page}
          setPage={setPage}
          products={productsList.filter((el) => +el.archived !== 1)}
          loading={loadingProductsList}
        />
      </Box>
      <AddProductModal open={addModal} setOpen={setAddModal} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({
  products: { categoriesForProductsPage, productsList, loadingProductsList },
}) => {
  return {
    categoriesForProductsPage,
    productsList,
    loadingProductsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCategoriesForProductsPage: () =>
      dispatch(onGetCategoriesForProductsPage()),
    onGetProductsList: (id) => dispatch(onGetProductsList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
