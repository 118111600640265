import axios from './axios';

export const onGetShopsListReq = async () => {
  return await axios.post('/outlets/list');
};

export const onAddShopReq = async (data) => {
  return await axios.post('/outlets/create', data);
};

export const onEditShopReq = async (data) => {
  return await axios.post(`/outlets/edit/${data.id}`, data);
};

export const onDeleteShopReq = async (id) => {
  return await axios.get(`/outlets/delete/${id}`);
};

export const onPushMessageNearShopReq = async (id, data) => {
  return await axios.post(`/outlets/send_to_nearest/${id}`, data);
};
