import React, { useState } from 'react';

import { Dialog, Box, TextField, Grid } from '@material-ui/core';
import HeaderForModal from '../../../../../components/Modals/HeaderForModal';

import { useCreateNotify } from '../../../../../hooks/useCreateNotify';
import { getUsersSalesLastDateReq } from '../../../../../api/migrations';
import MigrationsOutlets from '../../../../../components/MigrationsOutlets';
import FiltersSearchButton from '../../FiltersSearchButton';

const UsersSalesByLastDateModal = ({
  isShowModal,
  setUsers,
  loading,
  setLoading,
  closeModal,
}) => {
  const [outlets, setOutlets] = useState([]);
  const [dateFrom, setDateFrom] = useState('');

  const isValidForm = !!dateFrom && !!outlets.length;

  const { createNotify } = useCreateNotify();

  const paramsData = {
    outlet_ids: outlets.map((el) => el.id),
    date_from: dateFrom,
  };

  const getUsersByFilters = async () => {
    if (!isValidForm) return createErrorNotify();

    setLoading(true);

    try {
      const {
        data: { data },
      } = await getUsersSalesLastDateReq(paramsData);

      setUsers(data.map((user) => ({ ...user, checked: false })));
    } finally {
      setLoading(false);
    }
  };

  const setDate = (e) => {
    const date = e.target.value;

    setDateFrom(date);
  };

  const createErrorNotify = () => {
    createNotify({
      notifyStatus: 'error',
      notifyText: 'Заполните корректно все поля, чтобы продолжить!',
      isShowNotify: true,
    });
  };

  return (
    <Dialog open={isShowModal} fullWidth onClose={closeModal}>
      <Box p={2}>
        <HeaderForModal setOpen={closeModal} headerLabel='Заполните все поля' />
        <Grid container>
          <Grid item xs={7}>
            <MigrationsOutlets outlets={outlets} setOutlets={setOutlets} />
          </Grid>
          <Grid item xs={5}>
            <TextField
              value={dateFrom}
              onChange={setDate}
              label='Последняя покупка'
              type='date'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container justify='flex-end'>
          <FiltersSearchButton
            title='Поиск'
            disabled={loading}
            submit={getUsersByFilters}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default UsersSalesByLastDateModal;
