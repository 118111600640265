import * as constant from '../constants';

const initialState = {
  loadingCategoriesList: true,
  categoriesList: [],
  isCategoryActionSuccess: false,
  isCategoryActionError: false,
  categoriesForSelect: [],
};

export const categoriesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_CATEGORIES_LIST:
      return {
        ...state,
        categoriesList: payload,
      };
    case constant.ON_GET_CATEGORIES_FOR_SELECT:
      return {
        ...state,
        categoriesForSelect: payload,
      };
    case constant.IS_LOADING_CATEGORIES_LIST:
      return {
        ...state,
        loadingCategoriesList: payload,
      };
    case constant.IS_CATEGORY_ACTION_SUCCESS:
      return {
        ...state,
        isCategoryActionSuccess: true,
      };
    case constant.IS_CATEGORY_ACTION_ERROR:
      return {
        ...state,
        isCategoryActionError: true,
      };
    case constant.ON_CLOSE_ACTION_CATEGORY_MESSAGE: {
      return {
        ...state,
        isCategoryActionSuccess: false,
        isCategoryActionError: false,
      };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
