import React from 'react';
import { Grid, TableCell, makeStyles } from '@material-ui/core';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles(() => ({
  tableCell: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  icon: {
    fontSize: 16,
    marginLeft: 6,
  },
}));

const TableSortCeil = ({ children, align, dir, setDir }) => {
  const styles = useStyles();

  const isDescDir = dir === 'desc';

  const handleChangeDir = () => setDir(isDescDir ? 'asc' : 'desc');

  return (
    <TableCell
      className={styles.tableCell}
      align={align}
      onClick={handleChangeDir}
    >
      <Grid container justify='center' alignItems='center'>
        {children}
        {isDescDir ? (
          <ArrowDownwardIcon className={styles.icon} />
        ) : (
          <ArrowUpwardIcon className={styles.icon} />
        )}
      </Grid>
    </TableCell>
  );
};

export default TableSortCeil;
