import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExportIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import Spinner from '../../Spinner';
import ActionBonusesRulesModal from '../../Modals/ActionBonusesRulesModal';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import {
  deleteBonusesRules,
  getBonusesRulesList,
} from '../../../store/actions/bonusesRules';
import { uploadBonusesRules } from '../../../api/bonusesRules';
import BonusesHistoryModal from '../../Modals/BonusesHistoryModal/BonusesHistoryModal';
import { API_PATH } from '../../../resourses/variables';

const BonusesRulesTable = ({
  bonusesRulesList,
  loadingBonusesRulesList,
  getBonusesRulesList,
  deleteBonusesRules,
}) => {
  const styles = useStyles();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [bonusesRulesId, setBonusesRulesId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [bonusesRulesData, setBonusesRulesData] = useState(null);

  useEffect(() => {
    getBonusesRulesList();
  }, [getBonusesRulesList]);

  if (loadingBonusesRulesList) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const uploadRules = async (id) => {
    const {
      data: { data: file },
    } = await uploadBonusesRules(id);

    window.location.href = API_PATH + file;
  };

  const dayMonthFormatter = (dayOrMonth) =>
    String(dayOrMonth).length > 1 ? dayOrMonth : '0' + dayOrMonth;

  const openEditModal = (data) => {
    setBonusesRulesData(data);
    setShowEditModal(true);
  };

  const openConfirmDeleteModal = (id) => {
    setShowConfirmModal(true);
    setBonusesRulesId(id);
  };

  const removeBonusesRules = () => {
    deleteBonusesRules(bonusesRulesId);
  };

  const showBonusesRulesHistoryModal = (el) => {
    setBonusesRulesData(el);
    setShowHistoryModal(true);
  };

  return (
    <>
      <ActionBonusesRulesModal
        open={showEditModal}
        setOpen={setShowEditModal}
        modalTitle='Редактировать акционные бонусы'
        data={bonusesRulesData}
        setData={setBonusesRulesData}
      />
      <ConfirmDeleteModal
        id={bonusesRulesId}
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        text='акционные бонусы'
        actionFn={removeBonusesRules}
      />
      <BonusesHistoryModal
        open={showHistoryModal}
        setOpen={setShowHistoryModal}
        id={bonusesRulesData?.id}
        name={bonusesRulesData?.name}
      />
      <Box mt={3} mx={2}>
        <TableContainer component={Paper}>
          <Table className={styles.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Название</TableCell>
                <TableCell width={80} align='center'>Начало</TableCell>
                <TableCell align='center'>Длительность(дни)</TableCell>
                <TableCell align='center'>День/Месяц</TableCell>
                <TableCell align='center'>День рождения</TableCell>
                <TableCell align='center'>Пол</TableCell>
                <TableCell align='center'>Активна</TableCell>
                <TableCell align='center'>Сумма</TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bonusesRulesList.length
                ? bonusesRulesList.map((el) => (
                    <TableRow key={el.id} className={styles.tableItem}>
                      <TableCell align='center'>{el.name}</TableCell>
                      <TableCell align='center'>{el.start_dt}</TableCell>
                      <TableCell align='center'>{el.duration}</TableCell>
                      <TableCell align='center'>
                        {el.day &&
                          el.month &&
                          `${dayMonthFormatter(el.day)}/${dayMonthFormatter(
                            el.month,
                          )}`}
                      </TableCell>
                      <TableCell align='center'>{el.is_birthday}</TableCell>
                      <TableCell align='center'>{el.sex}</TableCell>
                      <TableCell align='center'>
                        {el.enabled ? 'Активна' : ''}
                      </TableCell>
                      <TableCell align='center'>{el.value} р.</TableCell>
                      <TableCell width={120} align='center'>
                        <Grid container direction='row' justify='space-between'>
                          <Tooltip title='Показать историю'>
                            <HistoryIcon
                              onClick={() => showBonusesRulesHistoryModal(el)}
                              className={styles.Icon}
                            />
                          </Tooltip>
                          <Tooltip title='Скачать отчет(xls)'>
                            <ExportIcon
                              onClick={() => uploadRules(el.id)}
                              color='action'
                              className={styles.Icon}
                            />
                          </Tooltip>
                          <Tooltip title='Редактировать'>
                            <EditIcon
                              onClick={() => openEditModal(el)}
                              className={`${styles.Icon} ${styles.editIcon}`}
                            />
                          </Tooltip>
                          <Tooltip title='Удалить'>
                            <CloseIcon
                              onClick={() => openConfirmDeleteModal(el.id)}
                              color='error'
                              className={styles.Icon}
                            />
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    color: propStyle.orangeColor,
  },
  Icon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

const mapState = ({
  bonusesRules: { bonusesRulesList, loadingBonusesRulesList },
}) => ({
  bonusesRulesList,
  loadingBonusesRulesList,
});

const mapDispatch = (dispatch) => ({
  getBonusesRulesList: () => dispatch(getBonusesRulesList()),
  deleteBonusesRules: (id) => dispatch(deleteBonusesRules(id)),
});

export default connect(mapState, mapDispatch)(BonusesRulesTable);
