import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Box,
  Button,
  TextField,
  Dialog,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Notify from '../../Notify';
import Select from 'react-select';

import { onPushNotification } from '../../../api/pushNotifications';
import { onGetFields } from '../../../store/actions/fields';

const NotifyModal = ({ open, setOpen, onGetFields, fieldsList }) => {
  const styles = useStyles();
  const [data, setData] = useState({ title: '', body: '', scope: 'all' });
  const [notifyText, setNotifyText] = useState();
  const [notify, setNotify] = useState(false);
  const [notifyStatus, setNotifyStatus] = useState('');
  const [curField, setCurField] = useState({});

  useEffect(() => {
    onGetFields();
  }, [onGetFields]);

  const onHandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const pushData = {
      ...data,
      fields:
        !curField.label || !curField.value
          ? []
          : [{ field_id: curField.id, value: curField.value }],
    };

    const response = await onPushNotification(pushData);

    if (response.status === 200) {
      setNotifyText('Вы успешно отправили push-уведомления пользователям!');
      setNotifyStatus('success');
      setNotify(true);
      setData({ title: '', body: '', scope: 'all' });
      setCurField({});
    } else {
      setNotifyText('Ошибка при отправке push-уведомлений пользователям!');
      setNotifyStatus('error');
      setNotify(true);
    }
  };

  const onCloseNotifyDialog = () => {
    setNotifyText();
    setNotifyStatus();
    setNotify(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Notify
        status={notifyStatus}
        text={notifyText}
        isOpen={notify}
        closeFn={onCloseNotifyDialog}
      />
      <form onSubmit={onSubmit} className={styles.modal}>
        <HeaderForModal setOpen={setOpen} headerLabel='Отправка уведомления' />
        <Box>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='column'
          >
            <Box className={styles.inputLabel}>
              <FormLabel component='legend'>Заголовок</FormLabel>
            </Box>
            <Box>
              <TextField
                value={data.title}
                onChange={onHandleChange}
                name='title'
                className={styles.input}
                variant='outlined'
                required
              />
            </Box>
          </Grid>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='column'
          >
            <Box className={styles.inputLabel}>
              <FormLabel component='legend'>Текст</FormLabel>
            </Box>
            <Box>
              <TextField
                value={data.body}
                onChange={onHandleChange}
                name='body'
                className={styles.input}
                variant='outlined'
                multiline
                rows={3}
                required
              />
            </Box>
          </Grid>
          <Box>
            <FormLabel component='legend'>Получатели</FormLabel>
            <RadioGroup
              onChange={onHandleChange}
              row
              defaultValue='all'
              aria-label='push'
              name='scope'
            >
              <FormControlLabel
                style={{ marginLeft: 0 }}
                value='all'
                control={<Radio style={{ color: propStyle.orangeColor }} />}
                label='Все'
                labelPlacement='start'
              />
              <FormControlLabel
                value='birthdays'
                control={<Radio style={{ color: propStyle.orangeColor }} />}
                label='Именинники'
                labelPlacement='start'
              />
            </RadioGroup>
          </Box>
          <Grid container direction='row'>
            <Grid style={{ paddingRight: 20 }} item xs={6}>
              <Box className={styles.inputLabel}>
                <FormLabel component='legend'>Доп. поле</FormLabel>
              </Box>
              <Select
                styles={propStyle.selectStyle}
                placeholder='Выбери доп. поле'
                value={curField}
                isSearchable
                onChange={(selectedOption) =>
                  setCurField({
                    label: selectedOption.label,
                    value: selectedOption.value,
                    id: selectedOption.id,
                  })
                }
                options={fieldsList.map((el) => ({
                  label: el.name,
                  value: '',
                  id: el.id,
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.inputLabel}>
                <FormLabel component='legend'>Значение</FormLabel>
              </Box>
              <TextField
                value={curField?.value || ''}
                onChange={(e) =>
                  setCurField({ ...curField, value: e.target.value })
                }
                name='title'
                className={styles.input}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Box>
        <Box width='100%' mt={4}>
          <Grid container direction='row' justify='center'>
            <Box mr={3}>
              <Button
                type='submit'
                className={styles.submitButton}
                variant='contained'
              >
                Отправить
              </Button>
            </Box>
            <Box>
              <Button onClick={() => setOpen(false)} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    color: 'grey',
    marginBottom: 2,
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({ fields: { fieldsList } }) => {
  return {
    fieldsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFields: () => dispatch(onGetFields()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifyModal);
