import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import { onGetNewsList, onDeleteNews } from '../../../store/actions/news';

import { imgPath } from '../../../resourses/variables';
import EditNewsModal from '../../Modals/EditNewsModal';

const NewsList = ({
  newsList,
  loadingNewsList,
  onGetNewsList,
  onDeleteNews,
}) => {
  const styles = useStyles();
  const [newsId, setNewsId] = useState();
  const [newsData, setNewsData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    onGetNewsList();
  }, [onGetNewsList]);

  if (loadingNewsList) {
    return (
      <Box my={5}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  const onOpenEditModal = (el) => {
    setNewsData(el);
    setNewsId(el.id);
    setOpenEditDialog(true);
  };

  const onOpenDeleteModal = (id) => {
    setNewsId(id);
    setOpenDeleteDialog(true);
  };

  return (
    <Box mx={2} mt={2}>
      {newsList.length ? (
        <Grid container direction='row' justify='space-between'>
          {newsList.map((el) => (
            <Box key={el.id} className={styles.item}>
              <Box>
                <img
                  className={styles.img}
                  src={imgPath + el.file}
                  alt='news'
                />
              </Box>
              <Box p={1}>
                <Typography style={{ textAlign: 'center' }}>
                  {el.name}
                </Typography>
              </Box>
              <Grid
                className={styles.buttonContainer}
                container
                direction='row'
                justify='space-around'
              >
                <Box>
                  <Button
                    onClick={() => onOpenDeleteModal(el.id)}
                    size='small'
                    color='secondary'
                  >
                    Удалить
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => onOpenEditModal(el)}
                    size='small'
                    className={styles.editButton}
                  >
                    Редактировать
                  </Button>
                </Box>
              </Grid>
            </Box>
          ))}
        </Grid>
      ) : (
        <Box my={5}>
          <Grid container direction='row' justify='center'>
            <Typography>Список новостей пустой</Typography>
          </Grid>
        </Box>
      )}
      <ConfirmDeleteModal
        id={newsId}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        actionFn={onDeleteNews}
      />
      <EditNewsModal
        headerLabel='Редактирование новости'
        newsData={newsData}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        newsId={newsId}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 280,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  item: {
    width: '30%',
    margin: 10,
    borderRadius: 8,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
      transition: 'transorm .1s linear',
    },
  },
  buttonContainer: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  editButton: {
    color: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({ news: { newsList, loadingNewsList } }) => {
  return {
    newsList,
    loadingNewsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetNewsList: () => dispatch(onGetNewsList()),
    onDeleteNews: (id) => dispatch(onDeleteNews(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);
