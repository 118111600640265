import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Dialog, Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import NewCropImageModal from '../NewCropImageModal';

import { noPhotoImg, imgPath } from '../../../resourses/variables';

import { onCloseCategoryActionMessages } from '../../../store/actions/categories';

const ActionCategoryModal = ({
  open,
  setOpen,
  onCategoryAction,
  headerLabel,
  categoryName,
  id,
  file,
  setFile,
}) => {
  const styles = useStyles();
  const [name, setName] = useState(categoryName);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [img, setImg] = useState();
  const [categoryFile, setCategoryFile] = useState();

  useEffect(() => {
    setName(categoryName);
  }, [categoryName]);

  useEffect(() => {
    setCategoryFile(file);
  }, [file]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (name.length) {
      onCategoryAction({ name, id, file_content: img });
    }
    setName('');
    setImg();
    setFile();
  };

  const onCloseDialog = () => {
    setImg();
    setOpen(false);
    setFile();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <form onSubmit={onSubmit} className={styles.modal}>
        <HeaderForModal setOpen={onCloseDialog} headerLabel={headerLabel} />
        <Box>
          <Grid
            className={styles.editFieldContainer}
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid className={styles.addPhotoContainer} item xs={4}>
              <Box mr={2}>
                {img ? (
                  <img className={styles.img} src={img} alt='categoryPhoto' />
                ) : categoryFile && !img ? (
                  <img
                    className={styles.img}
                    src={imgPath + categoryFile}
                    alt='categoryPhoto'
                  />
                ) : (
                  <img
                    className={styles.img}
                    src={noPhotoImg}
                    alt='categoryPhoto'
                  />
                )}
              </Box>
              <Box>
                <PhotoCamera
                  onClick={() => setOpenFileDialog(true)}
                  className={styles.addPhotoButton}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
                className={styles.input}
                label='Название'
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Box mr={3}>
              <Button
                type='submit'
                className={styles.addButton}
                variant='contained'
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={onCloseDialog} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
        </Box>
        <NewCropImageModal
          img={img}
          setImg={setImg}
          open={openFileDialog}
          setOpen={setOpenFileDialog}
          // w={30}
          // h={30}
          fw={16}
          fh={16}
        />
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  img: {
    width: 40,
    height: 40,
    borderRadius: 2,
  },
  addPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addPhotoButton: {
    cursor: 'pointer',
    color: propStyle.shadowColor,
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseCategoryActionMessages: () =>
      dispatch(onCloseCategoryActionMessages()),
  };
};

export default connect(null, mapDispatchToProps)(ActionCategoryModal);
