import React, { useState, useEffect } from 'react';

import { Grid, Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import Map from '../../Map';

const ActionShopsModal = ({
  headerLabel,
  setOpen,
  shopData,
  onSubmit,
  curAddress,
}) => {
  const styles = useStyles();
  const [marker, setMarker] = useState({});
  const [shop, setShop] = useState({
    name: '',
    phone: '',
    from: '',
    to: '',
  });

  useEffect(() => {
    if (shopData) {
      setShop(shopData);
    }
  }, [shopData]);

  const clearData = () => {
    setShop({
      name: '',
      phone: '',
      from: '',
      to: '',
    });
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const onHandleChange = (e) => {
    setShop({ ...shop, [e.target.name]: e.target.value });
  };

  return (
    <Grid container direction='column' className={styles.modal}>
      <HeaderForModal setOpen={onCloseModal} headerLabel={headerLabel} />
      <Box>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid className={styles.pr20} item xs={6}>
            <TextField
              onChange={onHandleChange}
              value={shop.name}
              name='name'
              className={styles.input}
              label='Название'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              onChange={onHandleChange}
              value={shop.phone}
              name='phone'
              className={styles.input}
              label='Телефон'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid className={styles.pr20} item xs={6}>
            <TextField
              onChange={onHandleChange}
              value={shop.from}
              name='from'
              label='Время открытия'
              type='time'
              className={styles.input}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              onChange={onHandleChange}
              value={shop.to}
              name='to'
              label='Время закрытия'
              type='time'
              className={styles.input}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='column'
        >
          <Map marker={marker} setMarker={setMarker} curAddress={curAddress} />
        </Grid>
      </Box>
      <Box width='100%' mt={5}>
        <Grid container direction='row' justify='center'>
          <Box mr={3}>
            <Button
              className={styles.submitButton}
              variant='contained'
              onClick={() => {
                onSubmit({ ...shop, lat: marker.lat, lon: marker.lng });
                clearData();
              }}
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={onCloseModal} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.orangeColor,
    },
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: propStyle.orangeColor,
  },
  pr20: {
    paddingRight: 20,
  },
}));

export default ActionShopsModal;
