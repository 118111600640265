import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../../resourses/propStyles';

import Spinner from '../../Spinner';
import ConfirmDeleteModal from '../../Modals/ConfirmDeleteModal';

import { onGetPromosList, onDeletePromo } from '../../../store/actions/promos';

import { imgPath } from '../../../resourses/variables';
import EditPromoModal from '../../Modals/EditPromoModal';

const PromosList = ({
  promosList,
  loadingPromosList,
  onGetPromosList,
  onDeletePromo,
}) => {
  const styles = useStyles();

  const [promoId, setPromoId] = useState();
  const [promoData, setPromoData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    onGetPromosList();
  }, [onGetPromosList]);

  if (loadingPromosList) {
    return (
      <Box my={5}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  const onOpenEditModal = (el) => {
    setPromoData(el);
    setPromoId(el.id);
    setOpenEditDialog(true);
  };

  const onOpenDeleteModal = (id) => {
    setPromoId(id);
    setOpenDeleteDialog(true);
  };

  return (
    <Box mx={2} mt={2}>
      {promosList.length ? (
        <Grid container direction='row'>
          {promosList.map((el) => (
            <Box key={el.id} className={styles.item}>
              <Box>
                <img
                  className={styles.img}
                  src={imgPath + el.file}
                  alt='promo'
                />
              </Box>
              <Box p={1}>
                <Typography style={{ textAlign: 'center' }}>
                  {el.name}
                </Typography>
              </Box>
              <Grid
                className={styles.buttonContainer}
                container
                direction='row'
                justify='space-around'
              >
                <Box>
                  <Button
                    onClick={() => onOpenDeleteModal(el.id)}
                    size='small'
                    color='secondary'
                  >
                    Удалить
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => onOpenEditModal(el)}
                    size='small'
                    className={styles.editButton}
                  >
                    Редактировать
                  </Button>
                </Box>
              </Grid>
            </Box>
          ))}
        </Grid>
      ) : (
        <Box my={5}>
          <Grid container direction='row' justify='center'>
            <Typography>Список акций пустой</Typography>
          </Grid>
        </Box>
      )}
      <ConfirmDeleteModal
        id={promoId}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        actionFn={onDeletePromo}
      />
      <EditPromoModal
        headerLabel='Редактирование акции'
        promoData={promoData}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        promoId={promoId}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 140,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  item: {
    width: '31%',
    margin: 10,
    borderRadius: 8,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
      transition: 'transorm .1s linear',
    },
  },
  buttonContainer: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  editButton: {
    color: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({ promos: { promosList, loadingPromosList } }) => {
  return {
    promosList,
    loadingPromosList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPromosList: () => dispatch(onGetPromosList()),
    onDeletePromo: (id) => dispatch(onDeletePromo(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromosList);
