import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import propStyle from '../../../resourses/propStyles';

import HeaderForModal from '../HeaderForModal';
import NewCropImageModal from '../NewCropImageModal';

import { imgPath } from '../../../resourses/variables';

const ActionPromoModal = ({
  promoData,
  setOpen,
  onSubmit,
  headerLabel,
  isAddModal,
}) => {
  const styles = useStyles();

  const [promos, setPromos] = useState({
    name: '',
    description: '',
    push: false,
    sms: false,
  });
  const [img, setImg] = useState();
  const [openFileDialog, setOpenFileDialog] = useState(false);

  const onShowAddFileModal = () => {
    setOpenFileDialog(true);
  };

  useEffect(() => {
    if (promoData) {
      setPromos(promoData);
    }
  }, [promoData]);

  const onHandleChange = (e) => {
    setPromos({
      ...promos,
      [e.target.name]:
        e.target.name === 'sms' || e.target.name === 'push'
          ? e.target.checked
          : e.target.value,
    });
  };

  const clearData = () => {
    setPromos({
      ...promos,
      name: '',
      description: '',
      file: '',
      push: false,
      sms: false,
    });
    setImg();
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  return (
    <Box className={styles.modal}>
      <HeaderForModal setOpen={onCloseModal} headerLabel={headerLabel} />
      <Box>
        <Grid
          className={styles.editFieldContainer}
          container
          direction='row'
          alignItems='center'
        >
          <Grid item xs={5}>
            <TextField
              onChange={onHandleChange}
              value={promos.name}
              name='name'
              className={styles.input}
              label='Название'
              variant='outlined'
            />
          </Grid>
          {isAddModal && (
            <>
              <Grid className={styles.formCheckbox} item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onHandleChange}
                      value={promos.sms}
                      name='sms'
                      style={{ color: propStyle.orangeColor }}
                      color='primary'
                    />
                  }
                  label='Смс'
                  labelPlacement='top'
                />
              </Grid>
              <Grid className={styles.formCheckbox} item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onHandleChange}
                      value={promos.push}
                      name='push'
                      color='primary'
                      style={{ color: propStyle.orangeColor }}
                    />
                  }
                  label='Пуш-уведомление'
                  labelPlacement='top'
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid className={styles.editFieldContainer} container direction='row'>
          <Grid item xs={8}>
            <TextField
              onChange={onHandleChange}
              value={promos.description}
              name='description'
              className={styles.input}
              label='Описание новости'
              variant='outlined'
              multiline
              rows={11}
            />
          </Grid>
          <Grid style={{ paddingLeft: 20 }} item xs={4}>
            <Tooltip title='Добавить картинку'>
              <Box
                position='relative'
                className={styles.imgWrapper}
                onClick={onShowAddFileModal}
              >
                <Box
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                  position='absolute'
                >
                  <PhotoCamera
                    style={{ color: propStyle.orangeColor }}
                    fontSize='large'
                  />
                </Box>
                {img ? (
                  <img src={img} className={styles.img} alt='product' />
                ) : promos.file ? (
                  <img
                    src={imgPath + promos.file}
                    className={styles.img}
                    alt='promos'
                  />
                ) : (
                  <Box className={styles.img} />
                )}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Box mr={3}>
            <Button
              onClick={() => {
                onSubmit(promos, img);
                clearData();
              }}
              className={styles.addButton}
              variant='contained'
            >
              Сохранить
            </Button>
          </Box>
          <Box>
            <Button onClick={onCloseModal} variant='contained'>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
      <NewCropImageModal
        setImg={setImg}
        open={openFileDialog}
        setOpen={setOpenFileDialog}
        w={300}
        h={140}
        fw={16}
        fh={9}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
  rubleIconWrap: {},
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
  addFileButton: {
    color: propStyle.orangeColor,
  },
  editFieldContainer: {
    marginBottom: 20,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  imgWrapper: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  img: {
    width: '100%',
    height: 246,
    borderRadius: 8,
  },
  formControl: {
    width: '100%',
  },
}));

export default ActionPromoModal;
