import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Box,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Tooltip,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../../resourses/propStyles';
import CloseIcon from '@material-ui/icons/Close';

import Spinner from '../../Spinner';
import TablePaginationActions from '../TablePagination';
import TableSortCeil from '../TableSortCeil';

import { onDeleteComment } from '../../../store/actions/comments';

const CommentsTable = ({
  list,
  count,
  loading,
  onDeleteComment,
  isShopList,
  page,
  handleChangePage,
  rowsPerPage,
  dir,
  setDir,
}) => {
  const styles = useStyles();

  if (loading)
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );

  return (
    <Box mt={3} mx={2}>
      <TableContainer component={Paper}>
        <Table className={styles.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
                {isShopList ? 'Магазин' : 'Товар'}
              </TableCell>
              <TableCell align='center'>Отзыв</TableCell>
              <TableCell align='center'>Пользователь</TableCell>
              <TableCell align='center'>Статус</TableCell>
              <TableSortCeil dir={dir} setDir={setDir} align='center'>
                Дата
              </TableSortCeil>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length
              ? list.map((el) => (
                  <TableRow key={el.id} className={styles.tableItem}>
                    <TableCell align='center'>
                      {isShopList ? el.outlet_name : el.product_name}
                    </TableCell>
                    <TableCell align='center'>{el.message}</TableCell>
                    <TableCell align='center'>
                      {el.user_first_name + ' ' + el.user_second_name}
                    </TableCell>
                    <TableCell align='center'>
                      {el.is_hidden ? 'Скрыт' : ''}
                    </TableCell>
                    <TableCell align='center'>
                      {moment(el.created_at).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title='Скрыть отзыв'>
                        <Grid container direction='row' justify='space-around'>
                          <CloseIcon
                            onClick={() => onDeleteComment(el)}
                            className={styles.closeIcon}
                          />
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={10}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={() => (
                  <TablePaginationActions
                    colSpan={4}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={null}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!list.length && (
        <Box fontSize={24} p={4} style={{ textAlign: 'center' }}>
          Список отзывов пустой
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
  tableItem: {},
  editIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: propStyle.orangeColor,
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: propStyle.shadowColor,
    },
  },
}));

const mapState = ({ comments: { commentsListCount } }) => ({
  count: commentsListCount,
});

const mapDispatchTo = (dispatch) => ({
  onDeleteComment: (data) => dispatch(onDeleteComment(data)),
});

export default connect(mapState, mapDispatchTo)(CommentsTable);
