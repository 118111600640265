import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import AuthPage from '../pages/AuthPage';
import OrdersPage from '../pages/OrdersPage';
import UsersPage from '../pages/UsersPage';
import CategoriesPage from '../pages/CategoriesPage';
import ProductsPage from '../pages/ProductsPage';
import LoyaltiesPage from '../pages/LoyaltiesPage';
import NewsPage from '../pages/NewsPage';
import PromosPage from '../pages/PromosPage';
import ShopsPage from '../pages/ShopsPage';
import CouponsPage from '../pages/CouponsPage';
import CommentsPage from '../pages/CommentsPage';
import MoreFieldsPage from '../pages/MoreFieldsPage';
import UserStatsPage from '../pages/UserStatsPage';
import SettingsPage from '../pages/SettingsPage';
import CardsPage from '../pages/CardsPage';
import BonusesRulesPage from '../pages/BonusesRulesPage';
import QuestionsHistoryPage from '../pages/QuestionsHistoryPage';
import MigrationsPage from '../pages/FiltersByUsersPage/components/MigrationsFilters';
// import YandexMap from '../pages/YandexMap';

import LeftSidebar from '../components/LeftSidebar';

import { onCheckAuthUser } from '../store/actions/auth';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import FiltersByUsersPage from '../pages/FiltersByUsersPage/FiltersByUsersPage';

const Routes = ({ isAuth, onCheckAuthUser }) => {
  const styles = useStyles();

  const routes = [
    {
      path: '/',
      component: OrdersPage,
      exact: true,
    },
    {
      path: '/users',
      component: UsersPage,
    },
    {
      path: '/products',
      component: ProductsPage,
    },
    {
      path: '/categories',
      component: CategoriesPage,
    },
    {
      path: '/loyalties',
      component: LoyaltiesPage,
    },
    {
      path: '/news',
      component: NewsPage,
    },
    {
      path: '/promos',
      component: PromosPage,
    },
    {
      path: '/shops',
      component: ShopsPage,
    },
    {
      path: '/coupons',
      component: CouponsPage,
    },
    {
      path: '/reviews',
      component: CommentsPage,
    },
    {
      path: '/fields',
      component: MoreFieldsPage,
    },
    {
      path: '/statistics/:id',
      component: UserStatsPage,
    },
    {
      path: '/settings',
      component: SettingsPage,
    },
    {
      path: '/cards',
      component: CardsPage,
    },
    {
      path: '/bonusesRules',
      component: BonusesRulesPage,
    },
    {
      path: '/questions',
      component: QuestionsHistoryPage,
    },
    {
      path: '/migrations',
      component: MigrationsPage,
    },
    {
      path: '/filters',
      component: FiltersByUsersPage,
    },
    // {
    //   path: '/map',
    //   component: YandexMap,
    // },
  ];

  useEffect(() => {
    onCheckAuthUser();
  }, [isAuth, onCheckAuthUser]);

  return (
    <>
      {isAuth ? (
        <Grid container direction='row'>
          <LeftSidebar />
          <Box className={styles.routes}>
            {routes.map((route, i) => {
              return (
                <Route
                  key={i}
                  exact={route?.exact}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Box>
        </Grid>
      ) : (
        <AuthPage />
      )}
    </>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  routes: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: '100%',
    marginLeft: drawerWidth,
  },
}));

const mapStateToProps = ({ auth: { isAuth } }) => {
  return {
    isAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckAuthUser: () => dispatch(onCheckAuthUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
