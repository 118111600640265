import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Box, Grid } from '@material-ui/core';

import { onGetOrdersDaysStats } from '../../../store/actions/stats';

import hgStyles from '../../../resourses/graphicsStyles';
import Spinner from '../../Spinner';

const DaysStats = ({
  onGetOrdersDaysStats,
  ordersDaysStatsList,
  loadingOrdersDaysStatsList,
}) => {
  const [series, setSeries] = useState([]);
  const [dateSeries, setDateSeries] = useState([]);

  useEffect(() => {
    onGetOrdersDaysStats();
  }, [onGetOrdersDaysStats]);

  useEffect(() => {
    if (ordersDaysStatsList.length) {
      setSeries(onGetSeries(ordersDaysStatsList));
    }
  }, [ordersDaysStatsList]);

  const onGetSeries = (arr) => {
    let data = [
      {
        name: 'Кол-во покупок',
        field: 'count',
        data: [],
      },
      {
        name: 'Средняя сумма',
        field: 'average_amount',
        data: [],
      },
      {
        name: 'Дата',
        field: 'date',
        data: [],
      },
      {
        name: 'Добавлено бонусов',
        field: 'total_added',
        data: [],
      },
      {
        name: 'Всего потрачено',
        field: 'total_amount',
        data: [],
      },
      {
        name: 'Списано бонусов',
        field: 'total_debited',
        data: [],
      },
    ];

    arr.forEach((el) => {
      for (let key in el) {
        if (key === data[0].field) {
          data[0].data.push(el[key]);
        } else if (key === data[1].field) {
          data[1].data.push(+el[key].toFixed(2));
        } else if (key === data[2].field) {
          data[2].data.push(el[key]);
        } else if (key === data[3].field) {
          data[3].data.push(+el[key].toFixed(2));
        } else if (key === data[4].field) {
          data[4].data.push(+el[key].toFixed(2));
        } else if (key === data[5].field) {
          data[5].data.push(+el[key].toFixed(2));
        }
      }
    });
    setDateSeries(data[2].data);

    data = data.filter((el) => el.field !== 'date');

    data.forEach((el) => {
      delete el.field;
    });

    return data;
  };

  if (loadingOrdersDaysStatsList) {
    return (
      <Box mb={4}>
        <Grid container direction='row' justify='center'>
          <Spinner />
        </Grid>
      </Box>
    );
  }

  const options = {
    chart: {
      type: 'spline',
    },
    title: {
      text: `Покупки`,
      style: {
        color: 'black',
      },
    },
    xAxis: {
      accessibility: '',
      type: 'date',
      categories: dateSeries,
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: series,
  };

  Highcharts.setOptions(hgStyles);

  return (
    <Box width='100%'>
      <HighchartsReact
        immutable={true}
        highcharts={Highcharts}
        options={options}
      />
    </Box>
  );
};

const mapStateToProps = ({
  stats: { ordersDaysStatsList, loadingOrdersDaysStatsList },
}) => {
  return {
    ordersDaysStatsList,
    loadingOrdersDaysStatsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrdersDaysStats: () => dispatch(onGetOrdersDaysStats()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DaysStats);
