import axios from './axios';

export const onGetLoyaltyListReq = async () => {
  return await axios.post('/bill_programs/list');
};

export const onAddLoyaltyReq = async (data) => {
  return await axios.post('/bill_programs/create', {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onEditLoyaltyReq = async (data) => {
  return await axios.post(`/bill_programs/edit/${data.id}`, {
    ...data,
    file_content: data.file_content ? data.file_content.split(',')[1] : '',
  });
};

export const onDeleteLoyaltyReq = async (id) => {
  return await axios.get(`/bill_programs/delete/${id}`);
};

export const onGeyLoyaltyTypesReq = async () => {
  return await axios.post('/bill_types/list');
};
