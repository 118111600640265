import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../resourses/propStyles';

import LoyaltyTable from '../../components/Tables/LoyaltyTable';
import Header from '../../components/Header';
import AddLoyaltyModal from '../../components/Modals/AddLoyaltyModal';

import { onGetLoyaltyList } from '../../store/actions/loyalty';

const LoyaltiesPage = ({
  loyaltyList,
  loadingLoyaltyList,
  onGetLoyaltyList,
}) => {
  const styles = useStyles();
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    onGetLoyaltyList();
  }, [onGetLoyaltyList]);

  return (
    <Box>
      <Header label={'Лояльность'} />
      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Button
          onClick={() => setAddModal(true)}
          className={styles.addButton}
          variant='contained'
        >
          Добавить
        </Button>
      </Grid>
      <LoyaltyTable list={loyaltyList} loading={loadingLoyaltyList} />
      <AddLoyaltyModal open={addModal} setOpen={setAddModal} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

const mapStateToProps = ({ loyalty: { loyaltyList, loadingLoyaltyList } }) => {
  return {
    loyaltyList,
    loadingLoyaltyList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLoyaltyList: () => dispatch(onGetLoyaltyList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltiesPage);
