import * as constant from '../constants';
import { onGetCardsListReq } from '../../api/cards';
import { dispatchLoadingStatus } from './helpersFuncions';

export const onGetCardsList = (limit, term) => {
  const loading = constant.IS_LOADING_CARDS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, loading));
    try {
      const {
        data: {
          data: { list, count },
        },
      } = await onGetCardsListReq(limit, term);

      dispatch({ type: constant.ON_GET_CARDS_LIST, payload: { list, count } });
    } finally {
      dispatch(dispatchLoadingStatus(false, loading));
    }
  };
};
