import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '@material-ui/core';

import Notify from '../../Notify';

import ActionPromoModal from '../ActionPromoModal';

import {
  onClosePromosActionMessages,
  onAddPromo,
} from '../../../store/actions/promos';

const AddPromoModal = ({
  open,
  setOpen,
  onClosePromosActionMessages,
  isPromosActionSuccess,
  isPromosActionError,
  onAddPromo,
  headerLabel,
}) => {
  const [isAddModal] = useState(true);

  const onSubmit = (promos, img) => {
    const data = { ...promos };

    data.file_content = img;

    onAddPromo(data);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <ActionPromoModal
        setOpen={setOpen}
        promosData={null}
        onSubmit={onSubmit}
        headerLabel={headerLabel}
        isAddModal={isAddModal}
      />
      <Notify
        closeFn={onClosePromosActionMessages}
        status='success'
        text='Новость успешно создана!'
        isOpen={isPromosActionSuccess}
      />
      <Notify
        closeFn={onClosePromosActionMessages}
        status='error'
        text='Ошибка при создании новости!'
        isOpen={isPromosActionError}
      />
    </Dialog>
  );
};

const mapStateToProps = ({
  promos: { isPromosActionSuccess, isPromosActionError },
}) => {
  return { isPromosActionSuccess, isPromosActionError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClosePromosActionMessages: () => dispatch(onClosePromosActionMessages()),
    onAddPromo: (data) => dispatch(onAddPromo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromoModal);
