import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import {
  onGetLoyaltyListReq,
  onGeyLoyaltyTypesReq,
  onAddLoyaltyReq,
  onEditLoyaltyReq,
  onDeleteLoyaltyReq,
} from '../../api/loyalty';

export const onGetLoyaltyList = () => {
  const type = constant.IS_LOADING_LOYALTY_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { list },
        },
      } = await onGetLoyaltyListReq();

      dispatch({
        type: constant.ON_GET_LOYALTY_LIST,
        payload: list,
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onGeyLoyaltyTypes = () => {
  return async (dispatch) => {
    const {
      data: {
        data: { list },
      },
    } = await onGeyLoyaltyTypesReq();

    dispatch({
      type: constant.ON_GET_LOYALTY_TYPES,
      payload: list,
    });
  };
};

export const onAddLoyalty = (loyaltyData) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await onAddLoyaltyReq(loyaltyData);

      dispatch({
        type: constant.ON_ADD_LOYALTY,
        payload: data,
      });

      dispatch({ type: constant.IS_LOYALTY_ACTION_SUCCESS });
    } catch (e) {
      dispatch({ type: constant.IS_LOYALTY_ACTION_ERROR });
    }
  };
};

export const onEditLoyalty = (loyaltyData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditLoyaltyReq(loyaltyData);

      const loyaltyList = getState().loyalty.loyaltyList;

      const idx = loyaltyList.findIndex((el) => el.id === loyaltyData.id);

      const list = [
        ...loyaltyList.slice(0, idx),
        data,
        ...loyaltyList.slice(idx + 1),
      ];

      dispatch({
        type: constant.ON_GET_LOYALTY_LIST,
        payload: list,
      });

      dispatch({ type: constant.IS_LOYALTY_ACTION_SUCCESS });
    } catch (e) {
      dispatch({ type: constant.IS_LOYALTY_ACTION_ERROR });
    }
  };
};

export const onDeleteLoyalty = (id) => {
  return async (dispatch, getState) => {
    await onDeleteLoyaltyReq(id);

    const list = getState().loyalty.loyaltyList.filter((el) => el.id !== id);

    dispatch({
      type: constant.ON_GET_LOYALTY_LIST,
      payload: list,
    });
  };
};

export const onCloseActionLoyaltyMessage = () => {
  return { type: constant.ON_CLOSE_ACTION_LOYALTY_MESSAGE };
};
