import axios from './axios';

export const getBonusesRulesListReq = async () =>
  await axios.post('/bonus_rules/list');

export const createBonusesRulesReq = async (data) =>
  await axios.post('/bonus_rules/create', data);

export const editBonusesRulesReq = async (data) =>
  await axios.post(`/bonus_rules/edit/${data.id}`, data);

export const deleteBonusesRulesReq = async (id) =>
  await axios.get(`/bonus_rules/delete/${id}`);

export const uploadBonusesRules = async (id) =>
  await axios.get(`/generate_bonus_rules_report/${id}`);

export const getBonusesRulesHistoryReq = async (id) =>
  await axios.get(`/bonus_rules/history/${id}`);

export const addBonusesByUsersReq = async ({
  users,
  duration,
  value,
}) =>
  await axios.post('/bulk/bonus_add', {
    client_ids: users,
    duration,
    value,
  });
