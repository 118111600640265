import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ActionUserModal from '../ActionUserModal';
import Spinner from '../../Spinner';
import Notify from '../../Notify';

import {
  onGetUserById,
  onEditUser,
  onCloseUserActionMessages,
} from '../../../store/actions/users';

const EditUserModal = ({
  open,
  setOpen,
  userId,
  onGetUserById,
  loadingUserById,
  curUserById,
  onEditUser,
  headerLabel,
  onCloseUserActionMessages,
  isUserActionSuccess,
  isUserActionError,
}) => {
  const styles = useStyles();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (userId) {
      onGetUserById(userId);
    }
  }, [onGetUserById, userId]);

  useEffect(() => {
    if (curUserById) {
      setUser(curUserById);
    }
  }, [curUserById]);

  const onHandleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    onEditUser(user);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      {loadingUserById ? (
        <Grid
          className={styles.modal}
          container
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Spinner />
        </Grid>
      ) : (
        <ActionUserModal
          setOpen={setOpen}
          user={user}
          setUser={setUser}
          onHandleChange={onHandleChange}
          onSubmit={onSubmit}
          headerLabel={headerLabel}
        />
      )}
      <Notify
        closeFn={onCloseUserActionMessages}
        status='success'
        text='Пользователь успешно редактирован!'
        isOpen={isUserActionSuccess}
      />
      <Notify
        closeFn={onCloseUserActionMessages}
        status='error'
        text='Ошибка при редактировании пользователя!'
        isOpen={isUserActionError}
      />
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    padding: 20,
  },
}));

const mapStateToProps = ({
  users: {
    curUserById,
    loadingUserById,
    isUserActionSuccess,
    isUserActionError,
  },
}) => {
  return {
    curUserById,
    loadingUserById,
    isUserActionSuccess,
    isUserActionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserById: (id) => dispatch(onGetUserById(id)),
    onEditUser: (data) => dispatch(onEditUser(data)),
    onCloseUserActionMessages: () => dispatch(onCloseUserActionMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
