import * as constant from '../constants';

const initialState = {
  couponsList: [],
  loadingCouponsList: true,
  productsForCoupSelect: [],
  userForCoupSelect: [],
  isCouponsActionSucces: false,
  isCouponsActionError: false,
};

const couponsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_COUPONS_LIST:
      return {
        ...state,
        couponsList: payload,
      };
    case constant.IS_LOADING_COUPONS_LIST:
      return {
        ...state,
        loadingCouponsList: payload,
      };
    case constant.ON_GET_DATA_FOR_COUP_SELECT:
      const { users, products } = payload;
      return {
        ...state,
        userForCoupSelect: users,
        productsForCoupSelect: products,
      };
    case constant.IS_COUPONS_ACTION_SUCCESS:
      return {
        ...state,
        isCouponsActionSucces: true,
      };
    case constant.IS_COUPONS_ACTION_ERROR:
      return {
        ...state,
        isCouponsActionError: true,
      };
    case constant.ON_CLOSE_ACTION_COUPONS_MESSAGE:
      return {
        ...state,
        isCouponsActionSucces: false,
        isCouponsActionError: false,
      };
    default:
      return state;
  }
};

export default couponsReducer;
