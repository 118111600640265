import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Box, Button, Grid, Typography, Dialog } from '@material-ui/core';

const NewCropImageModal = ({ open, setOpen, setImg, w, h, fw, fh }) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: fw / fh });
  const [completedCrop, setCompletedCrop] = useState(null);

  function generateDownload(canvas) {
    const dataURL = canvas.toDataURL();
    setImg(dataURL);
    setOpen(false);
  }

  const onCancelImgAdd = () => {
    setImg();
    setOpen(false);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <Dialog
      style={{}}
      fullWidth={true}
      maxWidth='md'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Grid
        style={{ padding: 20 }}
        container
        direction='column'
        alignItems='center'
      >
        <Box mt={3}>
          {/*  */}
          {upImg && (
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='flex-start'
              style={{ marginBottom: 20 }}
            >
              <Box mr={5}>
                <Box mb={2}>
                  <Typography>Обрезка изображения</Typography>
                </Box>
                <Box>
                  <ReactCrop
                    style={{ border: '1px solid black' }}
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography>Новое изображение</Typography>
                </Box>
                <Box>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          {/*  */}
          <Box mb={2}>
            <Grid container direction='column' alignItems='center'>
              <input
                onChange={onSelectFile}
                accept='image/*'
                style={{ display: 'none' }}
                id='contained-button-file'
                multiple
                type='file'
              />
              <label htmlFor='contained-button-file'>
                <Button variant='contained' color='primary' component='span'>
                  Загрузить изображение
                </Button>
              </label>
            </Grid>
          </Box>
          <Grid container direction='row' justify='center'>
            <Box mr={3}>
              <Button
                color='primary'
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() =>
                  generateDownload(previewCanvasRef.current, completedCrop)
                }
                variant='contained'
              >
                Сохранить
              </Button>
            </Box>
            <Box>
              <Button onClick={onCancelImgAdd} variant='contained'>
                Отмена
              </Button>
            </Box>
          </Grid>
          {/*  */}
        </Box>
      </Grid>
    </Dialog>
  );
};

export default NewCropImageModal;
