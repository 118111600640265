import React from 'react';

import { TextField } from '@material-ui/core';

const SettingsItemInput = ({ value, setValue, disabled }) => {
  return (
    <TextField
      value={value}
      onChange={setValue}
      multiline
      rows={3}
      disabled={disabled}
      fullWidth
    />
  );
};

export default SettingsItemInput;
