import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header';
import CommentsTable from '../../components/Tables/CommentsTable/CommentsTable';

import { onGetCommentsList } from '../../store/actions/comments';
import propStyle from '../../resourses/propStyles';

const ReviewTypes = {
  PRODUCT: 'product',
  OUTLET: 'outlet',
};

const rowsPerPage = 20;

const CommentsPage = ({
  commentsList,
  onGetCommentsList,
  loadingCommentsList,
}) => {
  const styles = useStyles();

  const [tab, setTab] = useState(ReviewTypes.PRODUCT);
  const [page, setPage] = useState(0);
  const [type, setType] = useState(ReviewTypes.PRODUCT);
  const [sortDir, setSortDir] = useState('desc');

  useEffect(() => {
    onGetCommentsList({ limit: +page * rowsPerPage, type, dir: sortDir });
  }, [onGetCommentsList, page, type, sortDir]);

  const handleChange = (_, tabValue) => {
    setPage(0);
    setType(tabValue);
    setTab(tabValue);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Header label='Отзывы' />
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{
          style: {
            backgroundColor: propStyle.orangeColor,
          },
        }}
        value={tab}
        onChange={handleChange}
      >
        <Tab label='Товары' value={ReviewTypes.PRODUCT} />
        <Tab label='Магазины' value={ReviewTypes.OUTLET} />
      </Tabs>
      <CommentsTable
        list={commentsList}
        loading={loadingCommentsList}
        isShopList={tab === ReviewTypes.OUTLET}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        dir={sortDir}
        setDir={setSortDir}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    fontSize: 18,
  },
}));

const mapStateToProps = ({
  comments: { commentsList, loadingCommentsList },
}) => {
  return {
    commentsList,
    loadingCommentsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetCommentsList: ({ limit, type, dir }) =>
    dispatch(onGetCommentsList({ limit, type, dir })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPage);
