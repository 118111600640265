import * as constant from '../constants';

const initialState = {
  newsList: [],
  loadingNewsList: true,
};

const newsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constant.ON_GET_NEWS_LIST:
      return {
        ...state,
        newsList: payload,
      };
    case constant.IS_LOADING_NEWS_LIST:
      return {
        ...state,
        loadingNewsList: payload,
      };
    case constant.ON_ADD_NEWS:
      return {
        ...state,
        newsList: [...state.newsList, payload],
      };
    default:
      return state;
  }
};

export default newsReducer;
