import React from 'react';

import { useDispatch } from 'react-redux';
import { setNotify } from '../store/actions/notify';

export const useCreateNotify = () => {
  const dispatch = useDispatch();

  return {
    createNotify: (params) => dispatch(setNotify(params)),
  };
};
