import * as constant from '../constants';

const initialState = {
  isShowNotify: false,
  notifyText: '',
  notifyStatus: 'success',
};

const notifyReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.SET_NOTIFY:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default notifyReducer;
