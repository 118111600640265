import * as constant from '../constants';
import { dispatchLoadingStatus } from './helpersFuncions';
import { onGetCommentsListReq, onDeleteCommentReq } from '../../api/comments';

export const onGetCommentsList = ({ limit, type, dir }) => {
  const loadingType = constant.IS_LOADING_COMMENTS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, loadingType));
    try {
      const {
        data: {
          data: { list, count },
        },
      } = await onGetCommentsListReq({ type, limit, dir });

      dispatch({
        type: constant.ON_GET_COMMENTS_LIST,
        payload: {
          list,
          count,
        },
      });
    } catch (e) {
    } finally {
      dispatch(dispatchLoadingStatus(false, loadingType));
    }
  };
};

export const onDeleteComment = (data) => {
  return async (dispatch, getState) => {
    await onDeleteCommentReq(data.id);

    const comments = getState().comments.commentsList;
    const idx = comments.findIndex((el) => el.id === data.id);

    const list = [
      ...comments.slice(0, idx),
      { ...data, is_hidden: !data.is_hidden },
      ...comments.slice(idx + 1),
    ];

    dispatch({
      type: constant.ON_GET_COMMENTS_LIST,
      payload: list,
    });
  };
};
