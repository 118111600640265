import React from 'react';

import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import propStyles from '../../../resourses/propStyles';
import { makeStyles } from '@material-ui/styles';

const QuestionsHistoryTable = ({ storyItem }) => {
  const styles = useStyles();
  if (!storyItem) return null;

  return (
    <Box mb={'24px'}>
      <Divider style={{ marginBottom: 20 }} />
      <Box mb={1} fontSize={20} fontWeight='600' color={propStyles.orangeColor}>
        - {storyItem.question_name}
      </Box>
      {storyItem?.summary && (
        <Box mb={1}>
          <Box fontSize={18} fontWeight='600' color={propStyles.orangeColor}>
            Статистика:
          </Box>
          <Grid container alignItems='center'>
            {Object.keys(storyItem.summary).map((key, i) => (
              <Grid className={styles.storyItem} item fontSize={14} key={key}>
                {i + 1}) {key}: {storyItem.summary[key]}%
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Accordion style={{ borderTop: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box fontSize={18} fontWeight='600'>
            Все ответы
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell width='30%' align='center'>
                    Клиент
                  </TableCell>
                  <TableCell width='20%' align='center'>
                    Номер карты
                  </TableCell>
                  <TableCell width='50%' align='center'>
                    Ответ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storyItem.answers?.map((el) => (
                  <>
                    <TableRow key={el.client_card_number}>
                      <TableCell width='30%' align='center'>
                        {el.client_name}
                      </TableCell>
                      <TableCell width='20%' align='center'>
                        {el.client_card_number}
                      </TableCell>
                      <TableCell width='50%' align='center'>
                        {el.value}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  storyItem: {
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    marginRight: 15,
  },
}));

export default QuestionsHistoryTable;
