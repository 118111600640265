import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Grid,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExportIcon from '@material-ui/icons/ExitToApp';
import Spinner from '../../Spinner';

import { onGetNewsListReq } from '../../../api/news';
import propStyles from '../../../resourses/propStyles';
import QuestionsHistoryModal from '../../Modals/QuestionsHistoryModal';
import { downloadQuestionsReportReq } from '../../../api/questions';
import { API_PATH } from '../../../resourses/variables';

const QuestionsTable = () => {
  const styles = useStyles();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [questId, setQuestId] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await onGetNewsListReq();
        setQuestions(data.list.filter((el) => el.questions?.length));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Grid container direction='column' alignItems='center' justify='center'>
        <Spinner />
      </Grid>
    );
  }

  const showStats = (id) => {
    setQuestId(id);
    setShowStatsModal(true);
  };

  const downloadReport = async (id) => {
    const {
      data: { data },
    } = await downloadQuestionsReportReq(id);

    window.location.href = API_PATH + data;
  };

  return (
    <>
      <QuestionsHistoryModal
        id={questId}
        open={showStatsModal}
        setOpen={setShowStatsModal}
      />
      <Box px={2} mt={2}>
        <TableContainer component={Paper}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Название</TableCell>
                <TableCell align='center'>Дата обновления</TableCell>
                <TableCell align='center'>
                  Длительность начисления бонусов(дни)
                </TableCell>
                <TableCell align='center'>Бонус</TableCell>
                <TableCell align='center'>Кол-во вопросов</TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((el) => (
                <TableRow key={el.id}>
                  <TableCell align='center'>{el.name}</TableCell>
                  <TableCell align='center'>
                    {moment(el.updated_at).format('l')}
                  </TableCell>
                  <TableCell align='center'>
                    {el.question_bonus_duration}
                  </TableCell>
                  <TableCell align='center'>
                    {el.question_bonus_value} p.
                  </TableCell>
                  <TableCell align='center'>{el.questions?.length}</TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Скачать отче(xls)'>
                      <ExportIcon
                        onClick={() => downloadReport(el.id)}
                        className={styles.downloadIcon}
                        color='primary'
                      />
                    </Tooltip>
                    <Tooltip title='Показать историю'>
                      <EqualizerIcon
                        onClick={() => showStats(el.id)}
                        className={styles.historyIcon}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    width: '100%',
  },
  historyIcon: {
    color: propStyles.orangeColor,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  downloadIcon: {
    marginRight: 20,
    color: propStyles.successColor,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default QuestionsTable;
