import * as constant from '../constants';

const initialState = {
  promosList: [],
  loadingPromosList: true,
  isPromosActionSuccess: false,
  isPromosActionError: false,
};

const promosReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constant.ON_GET_PROMOS_LIST:
      return {
        ...state,
        promosList: payload,
      };
    case constant.IS_LOADING_PROMOS_LIST:
      return {
        ...state,
        loadingPromosList: payload,
      };

    case constant.ON_ADD_PROMO:
      return {
        ...state,
        promosList: [...state.promosList, payload],
      };
    case constant.IS_PROMO_ACTION_SUCCESS:
      return {
        ...state,
        isPromosActionSuccess: true,
      };
    case constant.IS_PROMO_ACTION_ERROR:
      return {
        ...state,
        isPromosActionError: true,
      };
    case constant.ON_CLOSE_ACTION_PROMOS_MESSAGE:
      return {
        ...state,
        isPromosActionSuccess: false,
        isPromosActionError: false,
      };
    default:
      return state;
  }
};

export default promosReducer;
