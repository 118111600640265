import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { Box, Grid, TextField, Button, Typography } from '@material-ui/core';
import propStyle from '../../resourses/propStyles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const apiKey = 'AIzaSyAeTE_kYwFmy1MeEwQDfup0kWVwUUv2gyE';
Geocode.setApiKey(apiKey);
Geocode.setLanguage('ru');
Geocode.setRegion('ru');

const CustomMarker = ({ text }) => (
  <Box
    style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
    width='110px'
    borderRadius={8}
  >
    <Grid container direction='column' jusify='center' alignItems='center'>
      <Box style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: 12 }}>{text}</Typography>
      </Box>
      <Box>
        <LocationOnIcon style={{ color: 'red', fontSize: 40 }} />
      </Box>
    </Grid>
  </Box>
);

const Map = ({ marker, setMarker, curAddress }) => {
  const [address, setAddress] = useState('');
  const [showMarker, setShowMarker] = useState(false);
  const [markerText, setMarkerText] = useState('');
  const [center, setCenter] = useState({
    lat: 59.95,
    lng: 30.33,
  });

  const data = {
    zoom: 11,
  };

  useState(() => {
    if (curAddress) {
      setAddress(curAddress);
    }
  }, [curAddress]);

  const handleClick = (e) => {
    const { lat, lng } = e;

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const curAddress = response.results[0].formatted_address;
        setMarkerText(curAddress);
        setMarker({ lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
    setShowMarker(true);
  };

  const onCheckAddress = () => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
        setMarker({ lat, lng });
        setMarkerText(address);
      },
      (error) => {
        console.error(error);
      }
    );
    setShowMarker(true);
  };

  return (
    <Box mb={4} width='100%' height='500px'>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={10}>
          <TextField
            style={{ width: '100%' }}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            variant='outlined'
            label='Введите адрес'
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size='large'
            onClick={onCheckAddress}
            variant='contained'
            style={{ backgroundColor: propStyle.orangeColor }}
          >
            Поиск
          </Button>
        </Grid>
      </Grid>

      <GoogleMapReact
        onClick={handleClick}
        bootstrapURLKeys={{ key: apiKey }}
        center={center}
        yesIWantToUseGoogleMapApiInternals={true}
        defaultZoom={data.zoom}
      >
        {showMarker && (
          <CustomMarker
            lat={marker.lat}
            lng={marker.lng}
            text={markerText}
          />
        )}
      </GoogleMapReact>
    </Box>
  );
};

export default Map;
