import React from 'react';

import { Dialog, Box, Grid, Button, Typography } from '@material-ui/core';

const ConfirmDeleteModal = ({ id, open, setOpen, actionFn }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <Box p={2}>
        <Box mb={4}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Typography>
              Вы действительно хотите подтвердить удаление?
            </Typography>
          </Grid>
        </Box>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Box mr={3}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                actionFn(id);
                setOpen(false);
              }}
            >
              Удалить
            </Button>
          </Box>
          <Box>
            <Button variant='contained' onClick={() => setOpen(false)}>
              Отмена
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
