import React, { useState } from 'react';

import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import propStyle from '../../resourses/propStyles';

import Header from '../../components/Header';
import CouponsTable from '../../components/Tables/CouponsTable';
import AddCouponModal from '../../components/Modals/AddCouponModal';

const CouponsPage = () => {
  const styles = useStyles();
  const [addModal, setAddModal] = useState(false);

  return (
    <Box>
      <Header label={'Купоны'} />
      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Button
          onClick={() => setAddModal(true)}
          className={styles.addButton}
          variant='contained'
        >
          Добавить
        </Button>
      </Grid>

      <Box>
        <CouponsTable />
      </Box>
      <AddCouponModal open={addModal} setOpen={setAddModal} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyle.orangeColor,
  },
}));

export default CouponsPage;
