import React, { useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../../components/Header';
import propStyles from '../../resourses/propStyles';
import BonusesRulesTable from '../../components/Tables/BonusesRulesTable';
import ActionBonusesRulesModal from '../../components/Modals/ActionBonusesRulesModal';

const BonusesRulesPage = () => {
  const styles = useStyles();
  const [addModal, setAddModal] = useState(false);

  return (
    <div>
      <Header label={'Акционные бонусы'} />
      <Grid
        className={styles.addButtonContainer}
        container
        direction='row'
        justify='flex-end'
      >
        <Button
          onClick={() => setAddModal(true)}
          className={styles.addButton}
          variant='contained'
        >
          Добавить
        </Button>
      </Grid>
      <BonusesRulesTable />
      <ActionBonusesRulesModal
        open={addModal}
        setOpen={setAddModal}
        modalTitle='Добавить акционные бонусы'
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  addButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  addButton: {
    backgroundColor: propStyles.orangeColor,
  },
}));

export default BonusesRulesPage;
