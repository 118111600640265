import * as constant from '../constants';
import {
  onGetProductsListReq,
  onEditProductReq,
  onAddProductReq,
  onDeleteProductReq,
} from '../../api/products';
import { onGetProductsCategoriesListReq } from '../../api/categories';
import { dispatchLoadingStatus } from './helpersFuncions';

export const onGetCategoriesForProductsPage = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await onGetProductsCategoriesListReq();

    const list = data.map((el) => {
      return {
        id: el.id,
        name: el.name,
      };
    });

    dispatch({
      type: constant.ON_GET_CATEGORIES_FOR_PRODUCTS_PAGE,
      payload: list,
    });
  };
};

export const onGetCatAndSubCatForSelect = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await onGetProductsCategoriesListReq();

    dispatch({
      type: constant.ON_GET_CAT_AND_SUBCAT_FOR_SELECT,
      payload: data,
    });
  };
};

export const onGetProductsList = (id) => {
  const type = constant.IS_LOADING_PRODUCTS_LIST;

  return async (dispatch) => {
    dispatch(dispatchLoadingStatus(true, type));
    try {
      const {
        data: {
          data: { data },
        },
      } = await onGetProductsListReq(id);

      dispatch({
        type: constant.ON_GET_PRODUCTS_LIST,
        payload: data,
      });
    } finally {
      dispatch(dispatchLoadingStatus(false, type));
    }
  };
};

export const onAddProduct = (product) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onAddProductReq(product);

      const products = getState().products.productsList;

      const curCatId = products[0].parent_category_id;

      const list = [...products, data].filter(
        (el) => el.parent_category_id === curCatId,
      );

      dispatch({
        type: constant.ON_ADD_PRODUCT,
        payload: list,
      });
      dispatch({
        type: constant.IS_PRODUCT_ACTION_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: constant.IS_PRODUCT_ACTION_ERROR,
      });
    }
  };
};

export const onEditProduct = (productData) => {
  return async (dispatch, getState) => {
    try {
      const {
        data: { data },
      } = await onEditProductReq(productData);

      const products = getState().products.productsList;

      const idx = products.findIndex((el) => el.id === productData.id),
        curCatId = products[0].parent_category_id;

      const list = [
        ...products.slice(0, idx),
        data,
        ...products.slice(idx + 1),
      ];

      dispatch({
        type: constant.ON_EDIT_PRODUCT,
        payload: list.filter((el) => el.parent_category_id === curCatId),
      });
      dispatch({
        type: constant.IS_PRODUCT_ACTION_SUCCESS,
      });
    } catch (e) {
      console.log('e', e.message);
      dispatch({
        type: constant.IS_PRODUCT_ACTION_ERROR,
      });
    }
  };
};

export const onDeleteProduct = (id) => {
  return async (dispatch, getState) => {
    await onDeleteProductReq(id);

    const products = getState().products.productsList.filter(
      (el) => el.id !== id,
    );

    dispatch({
      type: constant.ON_GET_PRODUCTS_LIST,
      payload: products,
    });
  };
};

export const onCloseProductActionMessages = () => {
  return {
    type: constant.ON_CLOSE_ACTION_PRODUCT_MESSAGE,
  };
};
