import React from 'react';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';

import Routes from '../Routes/Routes';
import Notify from '../components/Notify';
import './App.css';

import { closeNotify } from '../store/actions/notify';

const App = ({ isShowNotify, notifyText, notifyStatus, closeNotify }) => {
  return (
    <Box className='app'>
      <Routes />
      <Notify
        status={notifyStatus}
        isOpen={isShowNotify}
        text={notifyText}
        closeFn={closeNotify}
      />
    </Box>
  );
};

const mapState = ({ notify: { isShowNotify, notifyText, notifyStatus } }) => ({
  isShowNotify,
  notifyText,
  notifyStatus,
});

const mapDispatch = (dispatch) => ({
  closeNotify: () => dispatch(closeNotify()),
});

export default connect(mapState, mapDispatch)(App);
