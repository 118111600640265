import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import propStyle from '../../resourses/propStyles';
import Header from '../../components/Header';

import { onGetOrdersList } from '../../store/actions/orders';
import OrdersTable from '../../components/Tables/OrdersTable';
import DaysStats from '../../components/Graphics/DaysStats';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OrdersPage = ({ onGetOrdersList, ordersList, loadingOrdersList }) => {
  const styles = useStyles();
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    page === 0 ? onGetOrdersList(1) : onGetOrdersList(+page * 20);
  }, [onGetOrdersList, page]);

  const handleChange = (e, tabValue) => {
    setTab(tabValue);
  };

  return (
    <Box className={styles.container}>
      <Header label={'Покупки'} />
      <Box px={2}>
        <DaysStats />
        <Tabs
          className={styles.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: propStyle.orangeColor,
            },
          }}
          value={tab}
          onChange={handleChange}
        >
          <Tab label='Все' {...a11yProps(0)} />
          {/* <Tab label='Завершенные' {...a11yProps(1)} />
          <Tab label='Предзаказ' {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <Box p={2}>
        {/* Все заказы */}
        {tab === 0 && (
          <OrdersTable
            page={page}
            setPage={setPage}
            loading={loadingOrdersList}
            list={ordersList}
          />
        )}
        {/* Завершенные */}
        {/* {tab === 1 && (
          <OrdersTable
            loading={loadingOrdersList}
            list={ordersList.filter((el) => el.status === 4)}
          />
        )} */}
        {/* Предзаказ */}
        {/* {tab === 2 && (
          <OrdersTable
            loading={loadingOrdersList}
            list={ordersList.filter((el) => el.status === 0)}
          />
        )} */}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {},
  tabs: {
    fontSize: 18,
  },
  table: {
    minWidth: 650,
  },
}));

const mapStateToProps = ({ orders: { ordersList, loadingOrdersList } }) => {
  return { ordersList, loadingOrdersList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrdersList: (limit) => dispatch(onGetOrdersList(limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
